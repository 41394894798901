import { useState } from 'react';
import { useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { IFormValues } from '../../interfaces/types';
import errorService from '../../services/error.service';
import TextInput from '../inputs/TextInput';
import { IUser } from '../../interfaces';
import { Select } from 'flowbite-react';
import UserService from '../../services/httpServices/user.service';
import ToastService from '../../services/toastService';
import MainButton from '../buttons/MainButton';
import PasswordInput from '../inputs/PasswordInput';
import { SECONDARY_BUTTON } from '../../config/constants';


export interface IEditUserComponent {
  user: IUser,
  onEditUser: any
}

export default function EditUserForm(props: IEditUserComponent): JSX.Element {

  const { user } = props;
  const { updateUserForAdmin } = UserService();
  const { showSuccessToast, showErrorToast } = ToastService();

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('El nombre del usuario es requerido'),
    last_name: Yup.string()
      .optional(),
    role: Yup.string()
      .required('El rol del usuario es requerido'),
    stripe_subscription_status: Yup.string()
      .required('El estado de subscripción de Stripe es requerido'),
  });

  const { register, handleSubmit, formState, watch, control, getValues, setValue  } = useForm<IFormValues>({ 
    resolver: yupResolver(validationSchema), 
    defaultValues:
      {
        email: user.email,
        name: user.name,
        last_name: user.last_name,
        password: user.password,
        role: user.role,
        stripe_subscription_status: user.stripe_subscription_status
      } 
  });

  const { errors } = formState;
  const role = getValues().role;
  const stripe_subscription_status = getValues().stripe_subscription_status;

  const [editUserError, setEditUserError] = useState<string[]>([]);
  const [showErrors, setShowErrors] = useState(false);


  const onSubmit = async (data: any) => {
    try {
      await updateUserForAdmin({ 
        email: user.email,
        password: data.password,
        checkEmail: user.checkEmail,
        name: data.name,
        last_name: data.last_name,
        role: data.role,
        stripe_subscription_status: data.stripe_subscription_status
      }, data.password !== user.password);
      showSuccessToast(`El usuario "${user.email}" se ha modificado correctamente`);
      props.onEditUser();
    } catch (error) {
      showErrorToast('Se ha producido un error al actualizar el usuario');
      const castedError = errorService.castError(error);
      setEditUserError([JSON.stringify(castedError.message)]);
      setShowErrors(true);
    }
  };

  const generateRandomPwd = () => {
    const randomPassword = Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2);
    setValue('password', randomPassword);
  }

  useWatch({ control, name: 'role', defaultValue: '' });
  useWatch({ control, name: 'stripe_subscription_status', defaultValue: '' });

  return (
    <div className='w-full sm:min-w-[500px] sm:max-w-[800px]'>
      <div className='font-medium text-text-200 text-2xl'>Editar usuario</div>
      <div className='flex flex-col gap-5 p-10 border border-gray-300 rounded-lg mt-5'>

        <div>
          <label>
            Email
          </label>
          <TextInput
            placeholder="Email del usuario"
            label="email"
            type='email'
            register={register}
            disabled={true}
          />
        </div>

        <div>
          <label>
            Contraseña
          </label>
          <div className='w-full flex flex-col sm:flex-row items-center gap-3 sm:gap-5 mt-2'>
            <div className='w-full flex-1'>
              <PasswordInput
                placeholder="Contraseña"
                label={'password'}
                register={register}
                required={true}
                minLength={{ value: 1, message: 'La contraseña no puede estar vacía' }}
                error={errors.password}
              />
            </div>
            <MainButton 
            type={SECONDARY_BUTTON} 
            className="w-full sm:w-auto" 
            onClick={generateRandomPwd}>
              Generar contraseña
            </MainButton>
          </div>
          { errors.password?.message && (
            <span className="text-red-600">{errors.password.message}</span>
          )}
        </div>

        <div>
          <label>
            Nombre
          </label>
          <TextInput
            placeholder="Nombre del usuario"
            label="name"
            type='text'
            register={register}
            error={errors.name}
            disabled={false}
          />
          { errors.name?.message && (
            <span className="text-red-600">{errors.name.message}</span>
          )}
        </div>

        <div>
          <label>
            Apellidos
          </label>
          <TextInput
            placeholder="Apellidos del usuario"
            label="last_name"
            type='text'
            register={register}
            error={errors.last_name}
          />
          { errors.last_name?.message && (
            <span className="text-red-600">{errors.last_name.message}</span>
          )}
        </div>

        <div>
          <label>
            Rol del usuario
          </label>
          <Controller
            name={`role`}
            control={control}
            render= {({field}) => (
              <Select
              id="role"
              className='mt-2'
              required={true}
              value={role}
              onChange={(e:any) => {
                field.onChange(e.target.value);
              }}>
                <option key='admin' value='admin'> Administrador </option>;
                <option key='user' value='user'>  Usuario </option>;
                <option key='unpaid' value='unpaid' > Usuario no pagado </option>;
              </Select>
            )}
          />

          { errors.role?.message && (
            <span className="text-red-600">{errors.role.message}</span>
          )}
        </div>

        <div>
          <label>
            Estado de la suscripción
          </label>
          <Controller
            name={`stripe_subscription_status`}
            control={control}
            render= {({field}) => (
              <Select
                id="stripe_subscription_status"
                required={true}
                className='mt-2'
                value={stripe_subscription_status}
                onChange={(val:any) => {
                  field.onChange(val.target.value);
                }}
              >
                <option key='active' value='active' > Activa </option>;
                <option key='incomplete' value='incomplete'>  Incompleta </option>;
                <option key='incomplete_expired' value='incomplete_expired' > Expirada </option>;
                <option key='trialing' value='trialing' > De prueba </option>;
                <option key='past_due' value='past_due' > Vencida </option>;
                <option key='canceled' value='canceled' > Cancelada </option>;
                <option key='unpaid' value='unpaid' > No pagado </option>;
              </Select>
            )}
          />
          {errors.stripe_subscription_status?.message && (
            <span className="text-red-600">{errors.stripe_subscription_status.message}</span>
          )}
        </div>

        { editUserError && showErrors && (
          <span className="text-red-600">{editUserError}</span>
        )}
            
        <MainButton onClick={handleSubmit(onSubmit)}>
          Actualizar
        </MainButton>
      </div>
    </div>
  );

};