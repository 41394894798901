import qve from '../../../../../assets/images/qve.png';
import MainButton from '../../../buttons/MainButton';
import { ROUND_BUTTON, ROUND_WHITE_BUTTON } from '../../../../config/constants';


export default function QVEBanner() {
  return(
    <div className="bg-background-200 py-20 text-justify sm:text-left flex flex-col sm:flex-row items-center justify-evenly px-12 xl:px-60 gap-10">
      <img src={qve} alt={'App Auténtica Test'}/>
      <div className='max-w-[500px]'>
        <h1 className="text-4xl text-center sm:text-left text-text-100 font-semibold" style={{fontFamily: 'serif'}}>
          ¿Qué vas a encontrar?
        </h1>
        <h1 className="mt-3 text-text-300">
          Auténtica Test pone a tu disposición 
          <b> tests de todas las normas aplicables en la Comunidad Valenciana, </b> 
          tanto en Generalitat como en Ayuntamientos y Diputaciones.
        </h1>
        <h1 className="mt-3 text-text-300">
          Lo encontrarás todo perfectamente 
          <b> clasificado en distintos bloques, </b> 
          para que puedas localizar sin ningún problema el test de la norma que buscas.
        </h1>
        <h1 className="mt-3 text-text-300">
          Y además, podrás hacer 
          <b> simulacros de las distintas convocatorias de la Generalitat Valenciana </b> 
          {'(adaptados a la última convocatoria oficial)'} y de Entidades Locales {'(convocatoria tipo)'}.
        </h1>
        <MainButton 
          className="mt-10 mx-auto sm:mx-0"
          type={ROUND_WHITE_BUTTON} 
          onClick={() => {
            window.location.href = 'https://www.autenticatest.com/que-vas-a-encontrar/'
          }}
        >
          Saber más
        </MainButton>
      </div>
    </div>
  )
}