import { IQuestion } from "../../interfaces";
import { useState } from "react";
import ModalQuestionReport from "./reportModal";
import { useAuth } from "../../contexts/authContext";
import { ROUND_BUTTON, ROUND_SECONDARY_BUTTON, TRANSPARENT_BUTTON } from "../../config/constants";
import MainButton from "../buttons/MainButton";
import { BiLinkExternal } from "react-icons/bi";
import { Button } from "flowbite-react";
import { BsQuestionCircleFill } from "react-icons/bs";
import DoubtfulButtonComponent from "./doubtfulButton";


export interface QuestionAnswerProps {
    question: IQuestion,
    isCorrected: boolean,
    index: number,
    onAnswerQuestion: any
}

export default function QuestionAnswer(props: QuestionAnswerProps): JSX.Element {
    const {
        question,
        index,
        isCorrected,
        onAnswerQuestion,
    } = props;


    const getOptionLetter = (index: number) => {
        const lastCharIndexBeforeMayusLetters = 64
        return String.fromCharCode(lastCharIndexBeforeMayusLetters + index)
    }

    const getQuestionLabel = (i: number) => {
        switch (i) {
            case 1: return question.option_1;
            case 2: return question.option_2;
            case 3: return question.option_3;
            case 4: return question.option_4;
        }
    }

    const renderCorrectedAnswer = () => {
        return (
            <>
                <input
                    type='radio'
                    className='peer hidden'
                    id={`${question._id}-answer-${index}`}
                    name={`${question._id}-answer-${index}`}
                    disabled={true}
                />
                <label htmlFor={`${question._id}-answer-${index}`}
                    className={`flex items-center justify-center min-w-[40px] min-h-[40px] border border-gray-300 text-text-200 rounded ${!isCorrected && 'cursor-pointer'} ${question.answer === index ? 'bg-green-300' : question.user_answer === index ? 'bg-red-300' : 'bg-white'}`}>
                    {getOptionLetter(index)}
                </label>
            </>
        )
    }

    const renderNotCorrectedAnswer = () => {
        //aqui hay que activar luego el check cuando recuperemos los datos de la bd de test no acabados 
        return (
            <>
                <input
                    type='radio'
                    onClick={() => {
                        if (question.user_answer === index) onAnswerQuestion(null);
                        else onAnswerQuestion(index);
                    }}
                    className='peer hidden'
                    id={`${question._id}-answer-${index}`}
                    name={`${question._id}-answer-${index}`}
                    checked={question.user_answer === index}
                />
                <label htmlFor={`${question._id}-answer-${index}`}
                    className={'transition select-none flex items-center justify-center min-w-[40px] min-h-[40px] bg-white ring-[1px] ring-gray-300 text-text-200 font-medium rounded cursor-pointer hover:bg-gray-200 peer-checked:bg-primary-200  peer-checked:text-white'}>
                    {getOptionLetter(index)}
                </label>
            </>
        )
    }

    return (
        <div className='flex items-center gap-4'>
            {isCorrected ? renderCorrectedAnswer() : renderNotCorrectedAnswer()}
            <label htmlFor={`${question._id}-answer-${index}`}
                className={`${!isCorrected && 'cursor-pointer'} select-none text-text-200`}>
                {getQuestionLabel(index)}
            </label>
        </div>
    )
}
