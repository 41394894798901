import { FieldError, Path, UseFormRegister, ValidationRule } from 'react-hook-form';

import { useState } from 'react';
import { IFormValues } from '../../interfaces/types';
import {AiFillEye, AiFillEyeInvisible} from 'react-icons/ai';

interface InputProps {
    label: Path<IFormValues>;
    disabled?: boolean;
    register: UseFormRegister<IFormValues>;
    minLength?: ValidationRule<number> | undefined;
    maxLength?: ValidationRule<number> | undefined;
    placeholder: string;
    pattern?: ValidationRule<RegExp> | undefined;
    error: FieldError | undefined;
    min?: ValidationRule<number | string> | undefined;
    required?: boolean;
    onFocus?: () => void;
    onBlur?: () => void;
    className?: string;
    autoFocus?: boolean;
    value?: string;
    name?: string,
}

const PasswordInput = ({
  label,
  disabled,
  register,
  minLength,
  maxLength,
  placeholder,
  pattern,
  error,
  min,
  required,
  onFocus,
  onBlur,
  className,
  autoFocus,
  name,
}: InputProps): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className={`relative flex-1 ${className}`}>
      <input
        type={showPassword ? 'text' : 'password'}
        disabled={disabled}
        placeholder={placeholder}
        min={0}
        required={true}
        autoFocus={autoFocus}
        {...register(label, {
          required,
          minLength,
          min,
          maxLength,
          pattern,
        })}
        onFocus={() => {
          if (onFocus) onFocus();
        }}
        onBlur={() => {
          if (onBlur) onBlur();
        }}
        className={`block rounded-md border-0 w-full pl-4 pr-11 py-2 text-black-100 ring-1 ring-gray-300 focus:ring-black
        ${error && 'ring-error-100 bg-red-50'} `}></input>
      <div
        onClick={() => setShowPassword(!showPassword)}
        onKeyDown={(e) => (e['code'] === 'Space' || e['code'] === 'Enter') && setShowPassword(!showPassword)}
        tabIndex={-1}
        className="absolute right-3 top-2 cursor-pointer text-text-200"
      >
        {showPassword ? <AiFillEye size={22}/> : <AiFillEyeInvisible size={22}/>}
      </div>
    </div>
  );
};
  
export default PasswordInput;