import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EditProfileComponent from '../components/profile/EditTab';
import SubscriptionComponent from '../components/profile/SubscriptionTab';
import UpdatePasswordComponent from '../components/profile/UpdatePwdTab';
import TabSelector from '../components/tabSelector/TabSelector';


export default function ProfileView(): JSX.Element {
  
  const params = new URLSearchParams(window.location.search);
  const history = useNavigate();
  const firstRun = useRef(true);
  const TABS = [
    { id: 'account', label: 'Editar Perfil', component: <EditProfileComponent /> },
    { id: 'subscription', label: 'Suscripción', component: <SubscriptionComponent /> },
    { id: 'changePassword', label: 'Cambiar contraseña', component: <UpdatePasswordComponent /> }
  ];

  const [activeTab, setActiveTab] = useState('account');

  function updateUrlWithState() {
    history({ search: new URLSearchParams({ tab: activeTab }).toString() });
  }
  
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    updateUrlWithState();
  }, [activeTab]);

  useEffect(() => {
    const tab = params.get('tab');
    if(tab && TABS.find(t => t.id === tab)) setActiveTab(tab);
    else if (!tab) updateUrlWithState();
  }, []);

  return (
    <div>
      <TabSelector tabs={TABS} activeTab={activeTab} onChange={setActiveTab} />
      { TABS.map(tab => (
        <div className={`pt-7 ${activeTab === tab.id ? 'block' : 'hidden'}`} key={tab.id}>
          {tab.component}
        </div>
      ))}
    </div>
  );
}