export default function TabSelector({
  activeTab,
  onChange,
  tabs,
}: {
    tabs: { id: string; label: string }[];
    activeTab: string;
    onChange: (newActiveTab: string) => void;
}): JSX.Element {
  return (
    <div className="w-full h-[50px] border-b-[1px] border-gray-200 flex items-center">
      { tabs.map((tab) => (
        <button
          key={tab.id}
          className={`transition flex flex-1 text-sm md:text-base h-full items-center justify-center border-b-2 hover:bg-primary-400 ${tab.id === activeTab ? 'border-primary-100 text-primary-100 font-bold' : 'border-transparent text-text-300 font-medium'}`}
          onClick={() => onChange(tab.id)}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
}