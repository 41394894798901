export default function CTABanner() {
  return(
    <div className="text-center bg-primary-200 px-8 py-16">
      <div className="text-3xl font-semibold text-center">
        <div className='text-center'>
          <span className='text-white'>Líderes en</span>
          <span className='text-black'> Tests y Simulacros de Oposiciones </span>
          <span className='text-white'>Comunidad Valenciana</span>
        </div>
      </div>
    </div>
  )
};