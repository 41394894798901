import { useEffect, useState } from "react";
import { useCallback } from 'react';
import { FilterValue, IdType } from 'react-table';
import { ISimulation } from "../../../../interfaces";
import { DefaultTable } from "../../../tables/table/table";
import SimulationService from "../../../../services/httpServices/simulation.service";
import UtilsService from "../../../../services/utils.service";
import ConfirmationModal from "../../../modals/ConfirmationModal";
import CreateTest from "../createTest/CreateTest";
import Skeleton from "react-loading-skeleton";
import MainButton from "../../../buttons/MainButton";
import { FLOATING_BUTTON } from "../../../../config/constants";
import { GoChevronLeft } from "react-icons/go";


function filterGreaterThan(rows: any, id: Array<IdType<any>>, filterValue: FilterValue) {
  return rows.filter((row: any) => {
    const rowValue = row.values[id[0]];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val: any) => typeof val !== 'number';


const columns = [
  {
    Header: 'Simulacros',
    columns: [
      {
        Header: 'Nombre de simulacro',
        accessor: 'name',
        aggregate: 'count',
        filter: 'fuzzyText',
        Aggregated: ({ cell: { value } }: any) => `${value} Names`,
        disableGroupBy: true
      },
      {
        Header: 'Fecha de creación',
        accessor: 'created_at',
        aggregate: 'uniqueCount',
        filter: 'fuzzyText',
        Aggregated: ({ cell: { value } }: any) => `${value} Data Created`,
        disableGroupBy: true
      },
    ],
  },

]; 

export default function ListTestComponent(): JSX.Element {

  const { getAllSimulations, downloadSimulation, deleteSimulation } = SimulationService();
  const { downloadPDFblob } = UtilsService();

  const [data, setData] = useState<any[]>([]);
  const [simulationToEdit, setSimulationToEdit] = useState<ISimulation>();
  const [isViewEditTest, setIsViewEditTest] = useState<boolean>(false);
  const [simulationDelete, setSimulationDelete] = useState<ISimulation>();
  const [viewModal, setViewModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function onGetAllSimulations() {
      const simulations: any[] = await getAllSimulations();
      setData(simulations);
      setIsLoading(false);
    }
    onGetAllSimulations();
  }, []);

  const downloadSimulationHandler = async (row: ISimulation) => {
    try {
      if (row._id) {
        const blobPDF = await downloadSimulation(row._id);
        downloadPDFblob(blobPDF, `${row.name}.pdf`);
      }
    } catch (error) {
      // TODO: handle error
    }
  };

  const onDownloadSimulation = useCallback(
    (instance: any) => () => {
      instance.selectedFlatRows.map( async (v: any) => {
        await downloadSimulationHandler(v.original);
      });       
    },
    []
  );

  const onDelete = useCallback(
    (instance: any) => async () => {
      if (instance.selectedFlatRows.length > 1) {
        await deleteSimulation(instance.selectedFlatRows.map(((res: any) => res.original._id)));
        window.location.reload(); 
      }
      if (instance.selectedFlatRows.length === 1) {
        instance.selectedFlatRows.map( async (v: any) => {
          setViewModal(true);
          setSimulationDelete(v.original);
        });  
      }
    },
    []
  );

  const deleteSimulationHandler = async () => {
    if (simulationDelete && simulationDelete._id) {
      await deleteSimulation([simulationDelete._id]);
      window.location.reload();
    }
  };

  return (
    <>
      <ConfirmationModal 
        text={`¿Quieres borrar el simulacro ${simulationDelete?.name}?`} 
        onAccept={() => deleteSimulationHandler()} 
        onClose={() => setViewModal(false)} 
        visible={viewModal}/>
      { isLoading ? 
        <Skeleton className="min-w-[70vw]" height={'80vh'}/> :
        <>
          {
            isViewEditTest && simulationToEdit ? 
              <div className="w-full flex-1 flex flex-col items-center relative pt-12">
                <MainButton 
                className="absolute top-0 left-0"
                type={FLOATING_BUTTON} 
                onClick={() => {
                  setSimulationToEdit(undefined);
                  setIsViewEditTest(false);
                }}>
                  <GoChevronLeft size={16}/>
                  Atrás
                </MainButton>
                <CreateTest simulation={simulationToEdit}/>
              </div>
              :
              <div className="w-[70vw]">
                <DefaultTable<any>
                  name={'testTable'}
                  columns={columns}
                  data={data}
                  onClick={(e: any) => {
                    setSimulationToEdit(e.original);
                    setIsViewEditTest(true);
                  }}  
                  onDelete={onDelete}
                  onDownload={onDownloadSimulation}
                />
              </div>
          }
        </>
      }
    </>
  );
};
