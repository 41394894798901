import VerticalTabSelector from "../tabSelector/VerticalTabSelector";
import { useState } from "react";
import ListQuestionComponent from "./questions/listQuestions";
import UploadQuestionComponent from "./questions/uploadQuestions";
import ListReportQuestions from "./reports/listReportOption";
import CreateTest from "./tests/createTest/CreateTest";
import ListTestComponent from "./tests/listTest/ListTest";
import ListUsersComponent from '../../components/admin-panel/users/ListUsers';
import AddUserComponent from '../../components/forms/AddUserForm';




export default function TabsDisplay(props: any): JSX.Element {
  const [activeTab, setActiveTab] = useState('list');

  const TABS = [
    {
      id: 'test',
      label: 'Tests',
      tabs: [
        { id: 'list', label: 'Listar tests', component: <ListTestComponent/> },
        { id: 'create', label: 'Crear tests', component: <CreateTest active={activeTab == 'create'}/> },
      ]
    },
    {
      id: 'questions',
      label: 'Preguntas',
      tabs: [
        { id: 'list', label: 'Listar preguntas', component: <ListQuestionComponent/> },
        { id: 'upload', label: 'Subir CSV', component: <UploadQuestionComponent/> },
      ]
    },
    {
      id: 'reports',
      label: 'Reportes',
      tabs: [
        { id: 'list', label: 'Listar reportes', component: <ListReportQuestions/> },
      ]
    },
    {
      id: 'users',
      label: 'Usuarios',
      tabs: [
        { id: 'list', label: 'Lista de usuarios', component: <ListUsersComponent/> },
        { id: 'create', label: 'Crear usuario', component: <AddUserComponent/> },
      ]
    }
  ];

  return (
    <div className="flex flex-1">
      <VerticalTabSelector
      tabs={TABS.find(item => item.id == props.activeTab)!.tabs}
      activeTab={activeTab}
      onChange={setActiveTab}/>
      { TABS.find(item => item.id == props.activeTab)!.tabs.map((tab: any) => (
        <div
        className={`flex-1 flex flex-col items-center ${activeTab === tab.id ? 'block' : 'hidden'} overflow-x-auto p-8`}
        key={tab.id}>
          {tab.component}
        </div>
      ))}
    </div>
  );
};
