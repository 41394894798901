import { HandPointing } from "phosphor-react";
import { Dispatch, SetStateAction } from "react";

export default function DefaultAvatar({
  diameter,
  className = '',
  setOpenSelectAvatarModal,
  setSrc
}: {
    diameter: number;
    className?: string;
    setOpenSelectAvatarModal: Dispatch<SetStateAction<boolean>>
    setSrc: Dispatch<SetStateAction<string>>; 
  }): JSX.Element {

  const selectAvatar = (e: any) => {
    e.preventDefault();
    setOpenSelectAvatarModal(true);
  };

  
  return (
    <div
      style={{ width: diameter, height: diameter, fontSize: diameter / 2.1 }}
      className={`${className} flex items-center justify-center rounded-full bg-cyan-100 bg-opacity-15 font-medium text-black`}
    >
      <p className="text-sm text-center" onClick={selectAvatar}>Seleccionar avatar</p>
      <div className="absolute md:mt-16 flex h-7 w-7 items-center justify-center rounded-full border-3 border-white bg-gray-5 text-gray-60">
        <HandPointing size={16} />
      </div>
    </div>
  );
}