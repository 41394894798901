import jwt_decode from "jwt-decode";
import { useAxios } from "../contexts/axiosContext";
import { useAuth } from "../contexts/authContext";
import { useLocation, useNavigate } from "react-router-dom";


export default function AuthService() {

  const { authAxios } = useAxios();
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const decodeToken = (token) => {
    return jwt_decode(token);
  };

  function login(email, password) {
    return new Promise((resolve, reject) => {
      authAxios.post(`/api/v1/auth`, {email, password})
        .then(async (res) => {
          const token = res.data;
          const user = decodeToken(token);
          auth.login({token, user});
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function logout() {
    auth.logout();
  }

  return {
    login,
    logout
  };
}