import { GrInstagram } from 'react-icons/gr';
import footerIcon from '../../../assets/images/footerIcon.png';
import { useAuth } from '../../contexts/authContext';
import { BsFacebook } from 'react-icons/bs';


export default function HomeFooter() {

  const auth = useAuth();

  return (
    <div className="flex flex-col sm:flex-row p-7 sm:px-5 sm:py-10 gap-x-[6%] gap-y-8 bg-primary-100 text-white">

      <div className='max-w-[150px]'>
        <img src={footerIcon} alt="Auténtica Test Logo" />
      </div>

      <div className="flex flex-1 justify-between sm:justify-evenly sm:gap-5">
        <div>
          <h2 className="text-xl font-medium uppercase mb-3">AUTÉNTICOS</h2>
          <ul>
            <li>
              <a href="https://www.autenticaoposiciones.com/"
                target="_blank"

                className="text-sm mb-1 hover:underline" rel="noreferrer">Auténtica Oposiciones</a>
            </li>
            <li>
              <a 
                href="https://www.autenticacursos.com/" 
                target="_blank"
                className="text-sm mb-1 hover:underline" rel="noreferrer">Auténtica Cursos</a>
            </li>
          </ul>
        </div>
        <div>
          <h2 className="text-xl font-medium uppercase mb-3">SOPORTE</h2>
          <ul>
            <li>
              <a 
                href="https://www.autenticatest.com/tutorial"
                target="_blank" 
                className="text-sm mb-1 hover:underline " rel="noreferrer"
              >Tutorial</a>
            </li>
            <li>
              <a 
                href='/profile?tab=account'
                className="text-sm mb-1 hover:underline" rel="noreferrer"
              >Mi cuenta</a>
            </li>
            <li>
              <a href="https://www.autenticatest.com/contacta/" 
                target="_blank" 
                className="text-sm mb-1 hover:underline" rel="noreferrer">Contacta</a>
            </li>
            <li>
              <a 
                href="/FAQS"
                className="text-sm mb-1 hover:underline" rel="noreferrer"
              >Preguntas Frecuentes</a>
            </li>
          </ul>
        </div>
        <div>
          <h2 className="text-xl font-medium uppercase mb-3">POLÍTICA</h2>
          <ul>
            <li>
              <a 
                href="https://www.autenticatest.com/terminos-y-condiciones/" 
                target="_blank" 
                className="text-sm mb-1 hover:underline" rel="noreferrer">Términos y condiciones</a>
            </li>
            <li>
              <a 
                href="https://www.autenticatest.com/politica-de-privacidad/" 
                target="_blank" 
                className="text-sm mb-1 hover:underline" rel="noreferrer">Política y privacidad</a>
            </li>
            <li>
              <a 
                href="https://www.autenticatest.com/cookies/"
                target="_blank"  
                className="text-sm mb-1 hover:underline" rel="noreferrer">Cookies</a>
            </li>
          </ul>
        </div>
      </div>

      <div className="flex flex-row-reverse justify-between sm:justify-start pr-12 sm:pr-0 sm:flex-col items-center sm:items-end text-sm sm:text-right">
        <div className="flex items-center gap-4 sm:mb-5 ml-3 sm:ml-0">
          <a href="https://www.facebook.com/Aut%C3%A9ntica-Oposiciones-102323031593418" 
            target="_blank" 
            className="hover:text-gray-900 transition" rel="noreferrer">
              <BsFacebook size={24} />
          </a>
          <a href="https://www.instagram.com/autentica.oposiciones/" 
            target="_blank" 
            className="hover:text-gray-900 transition" rel="noreferrer">
              <GrInstagram size={24} />
          </a>
        </div>
        <div className='flex sm:flex-col'>
          <span>©2023 Auténtica Test™</span>
          <span>All Rights Reserved</span>
        </div>
      </div>
    </div>
  );
}