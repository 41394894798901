import { useEffect, useState } from "react";
import { IQuestion } from "../../../../interfaces";
import { Button, Modal, TextField, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { CellProps, IdType, Row, FilterValue } from 'react-table';
import EditQuestionComponent from "../editQuestion";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ModalInfoQuestion from "./ModalQuestionInfo";
import { TableQuestions } from "../../../tables/table-questions/table";
import QuestionService from "../../../../services/httpServices/question.service";
import ToastService from "../../../../services/toastService";
import UtilsService from "../../../../services/utils.service";
import Skeleton from "react-loading-skeleton";
import ModalReportQuestions from "../../../ModalReportQuestion/index"


function filterGreaterThan(rows: Array<Row<any>>, id: Array<IdType<any>>, filterValue: FilterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id[0]];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val: any) => typeof val !== 'number';

const getMinMax = (rows: Row<any>[], id: IdType<any>) => {
  let min = rows.length ? rows[0].values[id] : 0;
  let max = rows.length ? rows[0].values[id] : 0;
  rows.forEach((row) => {
    min = Math.min(row.values[id], min);
    max = Math.max(row.values[id], max);
  });
  return [min, max];
};

function SliderColumnFilter({ column: { render, filterValue, setFilter, preFilteredRows, id } }: any) {
  const [min, max] = React.useMemo(() => getMinMax(preFilteredRows, id), [id, preFilteredRows]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <TextField
        name={id}
        label={render('Header')}
        type='range'
        inputProps={{
          min,
          max,
        }}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
        variant='standard'
      />
      <Button variant='outlined' style={{ width: 60, height: 36 }} onClick={() => setFilter(undefined)}>
        Off
      </Button>
    </div>
  );
}

const columns = [
  {
    Header: 'Preguntas',
    columns: [
      {
        Header: 'Enunciado',
        accessor: 'statement',
        aggregate: 'count',
        Aggregated: ({ cell: { value } }: CellProps<any>) => `${value} Statement`,
        disableGroupBy: true,
      },
      {
        Header: 'Preparador',
        accessor: 'preparer',
        aggregate: 'count',
        filter: 'fuzzyText',
        Aggregated: ({ cell: { value } }: CellProps<any>) => `${value} Unique Preparer`,
        disableGroupBy: true
      },
      {
        Header: 'Bloque de materia',
        accessor: 'subject_block',
        aggregate: 'count',
        Aggregated: ({ cell: { value } }: CellProps<any>) => `${value} Subject_block`,
        disableGroupBy: true
      },
      {
        Header: 'Nivel',
        accessor: 'level',
        width: 50,
        minWidth: 50,
        align: 'right',
        Filter: SliderColumnFilter,
        filter: 'equals',
        aggregate: 'level',
        disableGroupBy: true,
        defaultCanSort: false,
        disableSortBy: false,
        Aggregated: ({ cell: { value } }: CellProps<any>) => `${value} (level)`,
      },
      {
        Header: 'Normativa',
        accessor: 'standard',
        aggregate: 'count',
        Aggregated: ({ cell: { value } }: CellProps<any>) => `${value} Standard`,
        disableGroupBy: true
      },
      {
        Header: 'Option_1',
        accessor: 'option_1',
        aggregate: 'count',
        Aggregated: ({ cell: { value } }: CellProps<any>) => `${value} Option_1`,
        disableGroupBy: true
      },
      {
        Header: 'Option_2',
        accessor: 'option_2',
        aggregate: 'count',
        Aggregated: ({ cell: { value } }: CellProps<any>) => `${value} Option_2`,
        disableGroupBy: true
      },
      {
        Header: 'Option_3',
        accessor: 'option_3',
        aggregate: 'count',
        Aggregated: ({ cell: { value } }: CellProps<any>) => `${value} Option_3`,
        disableGroupBy: true
      }, {
        Header: 'Option_4',
        accessor: 'option_4',
        aggregate: 'count',
        Aggregated: ({ cell: { value } }: CellProps<any>) => `${value} Option_4`,
        disableGroupBy: true
      },
    ],
  }
];

export default function ListQuestionComponent(): JSX.Element {

  const { showErrorToast } = ToastService();

  const [data, setData] = useState<any[]>([]);
  const [isViewEditQuestion, setIsViewEditQuestion] = useState(false);
  const [questionToEdit, setQuestionToEdit] = useState<IQuestion>();
  const [totalQuestions, setTotalQuestions] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [viewStatementModal, setStatementModal] = useState<boolean>(false);
  const [statement, setStatement] = useState<IQuestion>();
  const [watchInfoQuestion, setWatchInfoQuestion] = useState<boolean>(false);
  const [watchQuestion, setWatchQuestion] = useState<IQuestion>();
  const [search, setSearch] = useState<string>('');
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const { getAllQuestions, deleteQuestion, downloadQuestionsExcel, downloadQuestionsPDF } = QuestionService();
  const { downloadExcelblob, downloadPDFblob, downloadZip } = UtilsService();

  useEffect(() => {
    async function getQuestions() {
      const fetchQuestions: any[] = await getAllQuestions();
      setData(fetchQuestions);
      setTotalQuestions(totalQuestions);
      setIsLoading(false);
    }
    getQuestions();
  }, []);

  const onDeleteQuestion = useCallback(
    (instance: any) => async () => {
      await deleteQuestion(instance.selectedFlatRows.map(((res: any) => res.original._id)));
      window.location.reload();
    },
    []
  );

  const handleClose = () => {
    setStatementModal(false);
  };

  const onDownloadExcel = useCallback(
    (instance: any) => async () => {
      const ids = instance.flatRows.map((v: any) => v.original._id);
      await downloadExcel(ids);
    },
    []
  );

  const onDownloadPDF = useCallback(
    (instance: any) => async () => {
      const ids = instance.flatRows.map((v: any) => v.original._id);
      await downloadPDF(ids);
    },
    []
  );

  const downloadExcel = async (ids: string[]) => {
    setIsDownloading(true);
    try {
      const query = { "$text": { "$search": `"${search}"` } };
      const excel = await downloadQuestionsExcel(ids);
      setIsDownloading(false);
      downloadZip(excel, `Backup-preguntas-${Date.now()}`);
    } catch (error) {
      console.error('Error durante la descarga del Excel:', error);
      setIsDownloading(false);
      // showErrorToast('El excel no se ha descargado con éxito');
    }
  };

  const downloadPDF = async (ids: string[]) => {
    setIsDownloading(true);
    try {
      const query = { "$text": { "$search": `"${search}"` } };
      const pdf = await downloadQuestionsPDF(ids);
      setIsDownloading(false);
      downloadPDFblob(pdf, `Backup-preguntas-${Date.now()}`);
    } catch (error) {
      setIsDownloading(false);
      showErrorToast('El pdf no se ha descargado con éxito');
    }
  };


  return (
    <>
      {isLoading ?
        <Skeleton className="min-w-[70vw]" height={'80vh'} /> :
        <>
          <Modal
            open={viewStatementModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="bg-white">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Enunciado de la pregunta
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {statement?.statement}
              </Typography>
            </Box>
          </Modal>
          {isViewEditQuestion && questionToEdit ?
            <EditQuestionComponent question={questionToEdit} setIsViewEditQuestion={setIsViewEditQuestion} />
            :
            <>
              {isDownloading ?
                <Skeleton className="min-w-[70vw]" height={'80vh'} /> :
                <div className="w-[70vw]">
                  <TableQuestions<any>
                    name={'testTable'}
                    columns={columns}
                    data={data}
                    onDelete={onDeleteQuestion}
                    onClick={(e: any) => {
                      setWatchQuestion(e.original);
                      setWatchInfoQuestion(true);
                    }}
                    onDownloadExcel={onDownloadExcel}
                    onDownloadPDF={onDownloadPDF}
                  />
                  <div>
                    {watchInfoQuestion && watchQuestion &&
                      <ModalReportQuestions
                        type="question"
                        setOpenModalReportInfo={setWatchInfoQuestion}
                        openModalReportInfo={watchInfoQuestion}
                        diameter={100}
                        info={{ question: watchQuestion }}
                        setIsViewEditQuestion={setIsViewEditQuestion}
                        setQuestionToEdit={setQuestionToEdit}
                      />
                    }
                  </div>
                </div>
              }
            </>
          }
        </>
      }
    </>
  );
};
