/* eslint-disable react/prop-types */

import React from 'react';
import axios from 'axios';
import { useAuth } from './authContext';
import ToastService from '../services/toastService';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { storageService } from '../services/storageService/storageService';
import { USER_DATA } from '../services/storageService/storageKeys';
import { HTTP_CODES } from "../config/constants";
import { useNavigate } from 'react-router-dom';


const AxiosContext = React.createContext(null);

export const AxiosProvider = ({ children }) => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const auth = useAuth();
  const { showWarningToast } = ToastService();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const authAxios = axios.create({
    baseURL: API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": i18next.language 
    }
  });

  const publicAxios = axios.create({
    baseURL: API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": i18next.language
    }
  });

  publicAxios.interceptors.request.use(
    (config) => {
      let userData = storageService.getData(USER_DATA);
      if (!config.headers.Authorization && userData?.token) config.headers.Authorization = `Bearer ${userData.token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  )

  publicAxios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response.status === HTTP_CODES.UNAUTHORIZED) {
        setTimeout(() => {
          showWarningToast(t('ERROR.SESSION_EXPIRED'));
        }, 1000);
        auth.logout();
        navigate('/login');
      } else if(error.response.status === HTTP_CODES.FORBIDDEN && auth.user && auth.token) {
        navigate('/unauthorized');
      } else {
        return Promise.reject(error);
      }
  });

  return (
    <AxiosContext.Provider value={{ publicAxios, authAxios }}>
      { children }
    </AxiosContext.Provider>
  );
}

export const useAxios = () => {
  return React.useContext(AxiosContext);
}