import { Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { BsQuestionCircle } from "react-icons/bs";


export default function FloatingButton() {  

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  return (
    <div className="absolute z-20 bottom-3 right-3">
      <button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className="transition p-1 bg-white rounded-full text-primary-100 hover:text-white hover:bg-primary-100 ring-white hover:ring-2"
      >
        <BsQuestionCircle size={44}/>
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <a href="https://www.autenticatest.com/contacta/" target="_blank" rel="noreferrer">
          <MenuItem onClick={handleClose}>Contacta</MenuItem>
        </a>
        <a href="https://www.autenticatest.com/tutorial" target="_blank" rel="noreferrer">
          <MenuItem onClick={handleClose}>Tutorial</MenuItem>
        </a>
        <a href="https://www.autenticatest.com/que-vas-a-encontrar/" target="_blank" rel="noreferrer">
          <MenuItem onClick={handleClose}>Contenido</MenuItem>
        </a>
      </Menu>
    </div>
  );
};
      