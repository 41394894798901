import { Fragment } from "react";
import { Button, Modal } from 'flowbite-react';
import { HiOutlineExclamationCircle } from "react-icons/hi";


export default function ConfirmationModal({
  text,
  visible,
  cancelText,
  acceptText,
  onClose,
  onAccept,
  body,
  finishLater,
  onFinishLater,
  disabeldBottons


}: {
  text: string,
  visible: boolean,
  cancelText?: string,
  acceptText?: string,
  onClose: any,
  onAccept: any,
  body?: string,
  finishLater?: string
  onFinishLater?: any
  disabeldBottons?: boolean
}): JSX.Element {

  return (
    <Fragment>
      <Modal
        show={visible}
        popup={true}
        onClose={() => onClose()}
      >
        <Modal.Body>
          <div className="text-center mt-8">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400" />
            <h3 className="mb-5 text-lg font-normal text-gray-700">
              {text}
            </h3>
            {body && (
              <h5 className="text-l mb-5 text-gray-500 text-left">
                {body.split('\n').map((text, index) => (
                  <><div key={index}>{text}</div></>
                ))}
              </h5>
            )}
            <div className="flex justify-center gap-4">
              <Button
                color="gray"
                onClick={() => onClose()}
              >
                {cancelText ?? 'Cancelar'}
              </Button>
              {finishLater && <Button
                disabled={disabeldBottons}
                className="md:mr-4 bg-primary-100 font-medium text-white hover:border-[#696969] hover:bg-white hover:text-black disabled:opacity-50 hover:disabled:bg-[#FCE96A]"
                color="primary-100"
                type="submit"
                onClick={() => onFinishLater()}
              >
                {finishLater ?? 'Aceptar'}
              </Button>}
              <Button
                disabled={disabeldBottons}
                className="md:mr-4 bg-primary-100 font-medium text-white hover:border-[#696969] hover:bg-white hover:text-black disabled:opacity-50 hover:disabled:bg-[#FCE96A]"
                color="primary-100"
                type="submit"
                onClick={() => onAccept()}
              >
                {acceptText ?? 'Aceptar'}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}
