import { IQuestion, ISection } from "../../interfaces";
import QuestionCard from "./QuestionCard";
import { useEffect, useState } from "react";
import { useAuth } from "../../contexts/authContext";
import MainButton from "../buttons/MainButton";
import { SECONDARY_BUTTON, SECONDARY_GRAY_BUTTON } from "../../config/constants";


interface SectionsQuestionsCard {
  section: ISection,
  onAnswerQuestion: any,
  onCorrectQuestion: any,
  onMarkedAsDoubtful: any,
  totalQuestions: number,
  correctMode: boolean,
  indexStartAt: number
  type?: string
}

function TestSection(props: SectionsQuestionsCard) {

  const {
    section,
    onAnswerQuestion,
    onCorrectQuestion,
    onMarkedAsDoubtful,
    totalQuestions,
    correctMode,
    indexStartAt,
    type
  } = props;
  const auth = useAuth();
  const [activeQuestion, setActiveQuestion] = useState<number>(0);

  return (
    <div>
      {section.questions.length > 0 &&
        <div className="mb-7">
          <div className="flex items-center">
            <div className="w-[8px] h-[8px] ml-2 mr-3 bg-primary-100 rounded-full" />
            <span className="font-semibold text-xl text-text-100">{section.name}</span>
          </div>
          <div className="w-full h-[1px] bg-gray-300 mt-2"></div>
        </div>
      }
      {auth?.user?.studyMode && !correctMode &&
        <div className="flex p-1 overflow-x-auto gap-3 mb-7">
          {section.questions.map((question: IQuestion, i: number) => (
            <MainButton
              key={`study-mode-index-button-${i}`}
              type={SECONDARY_GRAY_BUTTON}
              className={`
              ${activeQuestion === i && 'ring-2 ring-gray-500 bg-gray-200'} 
              ${question.isCorrected ? (question.user_answer === question.answer ? 'bg-green-200' : 'bg-red-200') : ''}
            `}
              onClick={() => setActiveQuestion(i)}>
              {i + 1}
            </MainButton>
          ))}
        </div>
      }
      <div className="flex flex-col gap-7">
        {section.questions.map((question: IQuestion, i: number) => {
          if (!auth?.user?.studyMode || correctMode || i === activeQuestion) {
            return (
              <QuestionCard
                type={type}
                key={question._id}
                isCorrected={question.isCorrected ?? false}
                question={question}
                index={indexStartAt + i}
                onAnswerQuestion={(ans: any) => onAnswerQuestion(i, ans)}
                onMarkedAsDoubtful={(ans: boolean) => onMarkedAsDoubtful(i, ans)}
                onCorrectQuestion={() => onCorrectQuestion(i)}
              />
            )
          }
          return;
        })
        }
      </div>
      {auth?.user?.studyMode && !correctMode &&
        <div className="mt-5 flex justify-between items-center gap-5">
          <MainButton
            type={SECONDARY_GRAY_BUTTON}
            className={activeQuestion === 0 && 'invisible'}
            onClick={() => setActiveQuestion(activeQuestion - 1)}
          >Anterior</MainButton>
          {activeQuestion < totalQuestions - 1 &&
            <MainButton
              type={SECONDARY_BUTTON}
              onClick={() => setActiveQuestion(activeQuestion + 1)}
            >Siguiente</MainButton>
          }
        </div>
      }
    </div>
  );
}

export default TestSection;
