import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import QuestionService from "../../../services/httpServices/question.service";
import MainButton from "../../../components/buttons/MainButton";
import { FLOATING_BUTTON } from "../../../config/constants";
import { GoChevronLeft } from "react-icons/go";
import { useAuth } from "../../../contexts/authContext";
import Skeleton from "react-loading-skeleton";
import { useLocation } from 'react-router-dom';
import { useSubscription } from "../../../contexts/subscriptionContext";



export default function SelectSubject(): JSX.Element {

  const auth = useAuth();
  const { getSubjectBlocks, getBlocksOfSimmulation } = QuestionService();
  const navigate = useNavigate();
  const { state } = useLocation()
  const subscription = useSubscription();
  const title = state ? ('NORMATIVA POR NIVELES' + ' ▶ ' + state?.simulation.split(' ').slice(1).join(' ▶ ')) : 'NORMATIVA'


  const [subjectBlocks, setSubjectBlocks] = useState<string[]>();
  const [isLoading, setIsloading] = useState<boolean>(true);

  useEffect(() => {
    async function onGetSubjectBlocks() {
      const fetchSubjectBlocks: string[] = await getSubjectBlocks();
      setSubjectBlocks(fetchSubjectBlocks);
      setIsloading(false);
    }
    async function onGetSubjectBlocksSimulacrum() {
      const fetchSubjectBlocksSimulacrum = await getBlocksOfSimmulation(state.simulation);
      setSubjectBlocks(fetchSubjectBlocksSimulacrum.groupedBlocks);
      setIsloading(false);
    }
    if (!state) {
      onGetSubjectBlocks();
    } else {
      onGetSubjectBlocksSimulacrum()
    }

  }, []);

  return (
    <div className="relative sm:pt-20 sm:pb-10 flex-1 flex flex-col items-center justify-center bg-background-200">
      <MainButton
        className="absolute top-5 left-5"
        type={FLOATING_BUTTON}
        onClick={() => navigate(-1)}>
        <GoChevronLeft size={16} />
        Atrás
      </MainButton>
      <div className="absolute top-6 right-6">
        <label htmlFor="study-mode" className="flex items-center cursor-pointer relative">
          <input
            type="checkbox"
            id="study-mode"
            className="sr-only peer"
            checked={auth.user?.studyMode}
            onChange={(e) => {
              auth.updateUser({ studyMode: e.target.checked });
            }}
          />
          <div className="toggle-bg bg-gray-200 border-2 border-gray-200 h-6 w-11 rounded-full  peer-checked:bg-primary-200 peer-checked:border-0"></div>
          <span className="text-text-300 text-sm font-medium text-center ml-2">
            Modo estudio
          </span>
        </label>
      </div>

      <h1 className="mt-28 mb-16 sm:mt-0  text-[25px] max-sm:text-[30px] text-center tracking-[10px]  text-text-300 font-light max-sm:px-4">
        {title}
      </h1>
      <div className="w-full flex items-center justify-center px-8">
        {isLoading ?
          <div className="flex w-full flex-wrap flex-col justify-center sm:flex-row gap-7">
            <Skeleton className="w-full sm:min-w-[260px]" height={260} borderRadius={12} />
            <Skeleton className="w-full sm:min-w-[260px]" height={260} borderRadius={12} />
            <Skeleton className="w-full sm:min-w-[260px]" height={260} borderRadius={12} />
            <Skeleton className="w-full sm:min-w-[260px]" height={260} borderRadius={12} />
            <Skeleton className="w-full sm:min-w-[260px]" height={260} borderRadius={12} />
            <Skeleton className="w-full sm:min-w-[260px]" height={260} borderRadius={12} />
            <Skeleton className="w-full sm:min-w-[260px]" height={260} borderRadius={12} />
            <Skeleton className="w-full sm:min-w-[260px]" height={260} borderRadius={12} />
          </div> :
          <div className="flex w-full flex-wrap flex-col justify-center sm:flex-row gap-7">
            {subjectBlocks?.map((subjectBlock: any, index: number) => {
              return (
                <div
                  className='cursor-pointer flex-1 w-full sm:flex-initial sm:w-[260px] sm:min-h-[180px] flex flex-col items-center justify-center transition bg-primary-200 text-center text-white p-10 rounded-3xl shadow-xl hover:scale-110'
                  key={index}
                  onClick={() => navigate(`/tests/regulations/${state ? subjectBlock.subject_block : subjectBlock}`, {
                    state: {
                      title: state?.simulation,
                      laws: subjectBlock.standards
                    }
                  })}>
                  <span className="select-none font-bold text-xl">{state ? subjectBlock.subject_block : subjectBlock}</span>
                  <div className="bg-white rounded-full w-[20px] h-[20px] mt-4" />
                </div>
              );
            })
            }
          </div>
        }
      </div>
    </div>
  );
};
