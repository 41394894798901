import moment from 'moment';

export enum TypesDateFormat {
  MonthDayYearHours = 'MMMM Do YYYY, h:mm:ss a'
}

export function format(date: Date | string | number, format: string): string {
  return moment(date).format(format);
}

export function sortDate(arrayToSort: any) {
  const sortedDates = arrayToSort?.map((obj: { date: string | number | Date; }) => { return { ...obj, date: new Date(obj.date) }; })
    .sort((a: { date: any; }, b: { date: any; }) => Number (b.date) - Number (a.date));

  return sortedDates;
}

export function toHHMMSS (secs: any) {
  const dayformat= moment.utc(secs*1000).format('HH:mm:ss');

  return dayformat;
}
export function toHHMMSSSSS (secs: any) {
  const dayformat= moment.utc(secs*1000).format('HH:mm:ss.SSS');

  return dayformat;
}

export function getDifference (now: any, then: any) {
  return moment.utc(moment(now,"HH:mm:ss").diff(moment(then,"HH:mm:ss"))).format("HH:mm:ss");
}

const dateService = {
  format,
  sortDate,
  toHHMMSS,
  getDifference,
  toHHMMSSSSS
};

export default dateService;