import womancomputer from '../../../../../assets/images/womancomputer.jpg';
import pruebaIcon from '../../../../../assets/icons/iconFreeTest.png';
import { FREE_TEST_TRIAL_URL, TRANSPARENT_BUTTON } from '../../../../config/constants';
import MainButton from '../../../buttons/MainButton';
import { useNavigate } from 'react-router-dom';


export default function FifthSection() {

  const navigate = useNavigate();

  return (
    <div className="flex flex-col sm:flex-row justify-evenly items-center gap-5 px-8 xl:px-60">
      <div className='flex flex-col sm:gap-8'>
        <div>
          <h1 className="mb-4 text-2xl font-semibold text-primary-200">Simulacros de Oposiciones</h1>
          <h1 className="text-sm text-text-100 font-semibold">
            <p>Adaptado a cada convocatoria</p>
            <p className='mt-2'>Generación Aleatoria</p>
            <p className='mt-2'>Define tu nivel: A1,A2,C1,C2</p>
            <p className='mt-2'>Entidades Locales de la C.V.</p>
            <p className='mt-2'>Generalitat Valenciana</p>
          </h1>
        </div>
        <div className='mt-8 sm:mt-0'>
          <h1 className="mb-4 text-2xl font-semibold text-primary-200">Test de Normas Concretas de Oposiciones</h1>
          <h1 className="text-sm text-text-100 font-semibold md:text-justify">
            <p>Adaptado a cada convocatoria</p>
            <p className='mt-2'>Elige el número de Preguntas</p>
            <p className='mt-2'>Se adapta al tiempo que tienes</p>
            <p className='mt-2'>Más de 50 Leyes, Reales Decretos y Decretos</p>
          </h1>

          <MainButton
          type={TRANSPARENT_BUTTON}
          className='px-0 mt-8'
          onClick={() => { window.open(FREE_TEST_TRIAL_URL, '_blank', 'noreferrer')  }}>
            <h1 className="text-lg text-primary-100 font-semibold">Prueba gratis</h1>
            <img
              className="w-6 h-6"
              src={pruebaIcon}
              alt="image of de Auténtica test app"
            />
          </MainButton>
        </div>
      </div>
      <img src={womancomputer} alt={'App Auténtica Test'} className='object-contain min-w-[100px] m-auto sm:m-0'/>
    </div>
  );
};
