import { useEffect, useState } from 'react';
import StatisticsService from '../services/httpServices/statistics.service';
import { useAuth } from '../contexts/authContext';
import { Chart as ChartJS, Tooltip, Legend, CategoryScale, BarElement, LinearScale, Title, Filler } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useNavigate } from 'react-router-dom';
import { ROUND_BLACK_BUTTON, ROUND_BUTTON, ROUND_RED_BUTTON } from '../config/constants';
import MainButton from '../components/buttons/MainButton';
import ToastService from '../services/toastService';
import ConfirmationModal from '../components/modals/ConfirmationModal';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  Filler
);

export default function Statistics(): JSX.Element {

  const auth = useAuth();
  const { resetStatistics, getStatisticUser } = StatisticsService();
  const { showErrorToast } = ToastService();
  const navigate = useNavigate();

  const [modalReset, setModalReset] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>(null);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'ESTADÍSTICAS POR BLOQUE DE MATERIA',
      },
      datalabels:{
        font:{
          size: 15
        }
      },
    }
  };

  const resetAnswers = async () => {
    try {
      await resetStatistics();
      setModalReset(false);
      window.location.reload();
    } catch (error) {
      showErrorToast('No se ha podido resetear las estadísticas, porfavor inténtelo de nuevo');
    } 
  };

  const acceptReset = async () => {
    await resetAnswers();
  };

  async function getStatistics() {
    getStatisticUser().then(response => {
      setData({
        labels: response.map((statistic: any) => statistic._id),
        datasets: [
          {
            label: 'Preguntas correctas',
            data: response.map((statistic: any) => statistic.corrects),
            borderColor: '#50C85D',
            backgroundColor: '#AEFFB7',
            borderWidth: 2,
          },
          {
            label: 'Preguntas falladas',
            data: response.map((statistic: any) => statistic.failures),
            borderColor: '#F85548',
            backgroundColor: '#F5A7A0',
            borderWidth: 2,
          },
          {
            label: 'Preguntas no contestadas',
            data: response.map((statistic: any) => statistic.not_answered),
            borderColor: '#74C7E6',
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderWidth: 2,
          }
        ]
      })
    }).catch(error => {
      //TODO: handle error
    }).finally(() => {
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if(auth.user) getStatistics();
  },[auth.user]);

  return (
    <div className='px-6 flex-1 flex flex-col pt-10 items-center sm:pb-20 border-t-[1px] border-gray-200'>
      { !isLoading &&
        <>
          <ConfirmationModal
            text={'¿Estás seguro? Se borrarán todas las estadísticas y empezarás de cero, sin ver la evolución.'} 
            onAccept={() => acceptReset()} 
            onClose={() => setModalReset(false)} 
            visible={modalReset}/>
          <div className="w-full flex flex-col items-center">
            <div className='flex-1 w-full sm:px-[15%] max-h-[80vh]'>
              { data && <Bar options={options} data={data} updateMode='resize'/> }
            </div>
            <div className="w-full flex flex-col sm:flex-row items-center justify-center gap-3 mt-10">
              <MainButton
              className="w-full sm:w-auto"
              type={ROUND_RED_BUTTON}
              onClick={() => navigate(`/tests/questions/failed/${true}`)}>
                Realizar test de preguntas falladas
              </MainButton>
              <MainButton
              type={ROUND_BUTTON}
              className="w-full sm:w-auto"
              onClick={() => navigate(`/tests/questions/notAnswered/${true}`)}>
                Realizar test de preguntas no contestadas
              </MainButton>
              <MainButton
              className="w-full sm:w-auto"
              type={ROUND_BLACK_BUTTON}
              onClick={() => setModalReset(true)}>
                Resetear estadisticas
              </MainButton>
            </div>
          </div>
        </>
      }
    </div>
  );
}