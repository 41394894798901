export interface AppConfig {
  views: AppViewConfig[];
}

export interface ISectionFormValue {
    standard: string;
    subject_block: string;
    title: string;
    article_ranges: string;
    level_range: string;
    number: number;
}

export interface IFormValues {
  name: string;
  lastname: string;
  email: string;
  password: string;
  lastPassword: string;
  currentPassword: string;
  confirmPassword: string;
  token: string;
  birthday: string;
  oposition: string [];
  newPassword: string;
  username: string;
  sections: any;
  numberOfSections: string;
  insertAt: any;
  statement: string;
  subject_block: string;
  standard: string;
  option_1: string;
  option_2: string;
  option_3: string;
  option_4: string;
  article: number;
  title: string;
  governmental: boolean;
  autonomic: boolean;
  local: boolean;
  preparer: string;
  level: number;
  article_url: string;
  answer: number;
  user_answer:string;
  checkLegal: boolean;
  checkEmail: string;
  last_name: string;
  role: string;
  stripe_subscription_status: string;
}

export interface IFormSectionSimulation {
  standard: string;
  subject_block: string;
  title: string;
  article_ranges: string;
  level_range: string;
  number: number;
}

export interface IFormValuesSimulation {
  name: string;

}


export interface AppDebugConfig {
  enabled: boolean;
}

export interface AppPlanConfig {
  isFreePlan: boolean;
  isCustomerPlan: boolean;
}

export interface AppViewConfig {
  id: string;
  layout: AppViewLayout;
  path: string;
  exact: boolean;
  auth?: boolean;
  hideSearch?: boolean;
}

export default class AppError extends Error {
  readonly status?: number;

  constructor(message: string, status?: number) {
    super(message);

    this.status = status;
  }
}

export enum DevicePlatform {
  Web = 'web',
}

export enum RoleType {
  IsAdminRole = 'admin',
  IsReportReviewer = 'reportReviewer',
  IsUserRole = 'user',
}

export enum DragAndDropType {
  AdminItem = 'admin-item',
}

export enum TimeInterval {
  Month = 'month',
  Year = 'year',
  day = 'day'
}

export enum AppViewLayout {
  Empty = 'empty',
  HeaderAndSidenav = 'header-and-sidenav',
}

export enum AppView {
  Login = 'login',
  Auth = 'auth',
  Recover = 'recover',
  Deactivation = 'deactivation',
  CheckoutSuccess = 'checkout-success',
  Checkout = 'checkout',
  Remove = 'remove',
  RemoveDialog = 'removedialog',
  NotFound = 'not-found',
  VerifyEmail = 'verify-email',
}

export enum SignupDeviceSource {
  Macintosh = 'MacOs',
  Android = 'Android',
  Iphone = 'iPhone',
  Windows = 'Windows',
  Linux = 'Linux',
  Ipad = 'iPad',
  Other = 'Other',
}

export enum LocalStorageItem {
  Role = 'role',
  User = 'userToken',
}

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export interface OrderSettings {
  by: string;
  direction: OrderDirection;
}