import { useEffect, useMemo, useState } from "react";
import { useCallback } from 'react';
import { FilterValue, IdType } from 'react-table';
import { ISimulation } from "../../../interfaces";
import { MenuItem, TextField } from '@mui/material';
import { DefaultTable } from "../../tables/table/table";
import UserService from "../../../services/httpServices/user.service";
import ConfirmationModal from "../../modals/ConfirmationModal";
import { GoChevronLeft } from "react-icons/go";
import MainButton from "../../buttons/MainButton";
import { FLOATING_BUTTON } from "../../../config/constants";
import { useNavigate } from "react-router-dom";
import EditUserForm from "../../forms/EditUserForm";


function SelectColumnFilter({
  column: { filterValue, render, setFilter, preFilteredRows, id },
}: any) {
  const options = useMemo(() => {
    const options = new Set<any>();
    preFilteredRows.forEach((row: any) => {
      options.add(row.values[id]);
    });
    return [...Array.from(options.values())];
  }, [id, preFilteredRows]);
  
  return (
    <TextField
      select
      label={render('Header')}
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      variant='standard'
    >
      <MenuItem value={''}>Todos</MenuItem>
      {options.map((option, i) => (
        <MenuItem key={i} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
}

function filterGreaterThan(rows: any, id: Array<IdType<any>>, filterValue: FilterValue) {
  return rows.filter((row: any) => {
    const rowValue = row.values[id[0]];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val: any) => typeof val !== 'number';


const columns = [
  {
    Header: 'Usuarios',
    columns: [
      {
        Header: 'Email del usuario',
        accessor: 'email',
        aggregate: 'count',
        filter: 'fuzzyText',
        Aggregated: ({ cell: { value } }: any) => `${value} Email`,
        disableGroupBy: true
      },
      {
        Header: 'Nombre',
        accessor: 'name',
        aggregate: 'count',
        filter: 'fuzzyText',
        Aggregated: ({ cell: { value } }: any) => `${value} Name`,
        disableGroupBy: true
      },
      {
        Header: 'Apellidos',
        accessor: 'last_name',
        aggregate: 'count',
        filter: 'fuzzyText',
        Aggregated: ({ cell: { value } }: any) => `${value} Lastname`,
        disableGroupBy: true
      },
      {
        Header: 'Check Email',
        accessor: 'checkEmail',
        aggregate: 'count',
        Filter: SelectColumnFilter,
        filter: 'includes',
        disableGroupBy: true
      },
      {
        Header: 'Estado usuario',
        accessor: 'role',
        aggregate: 'count',
        Filter: SelectColumnFilter,
        filter: 'includes',
        disableGroupBy: true,
      },
      {
        Header: 'Estado de la suscripción',
        accessor: 'stripe_subscription_status',
        aggregate: 'count',
        Filter: SelectColumnFilter,
        disableGroupBy: true
      },
      {
        Header: 'Stripe customer_id',
        accessor: 'customer_id',
        aggregate: 'count',
        filter: 'fuzzyText',
        Aggregated: ({ cell: { value } }: any) => `${value} CustomerId Stripe`,
        disableGroupBy: true,
      },
      {
        Header: 'Stripe subscription_id',
        accessor: 'subscription_id',
        aggregate: 'count',
        filter: 'fuzzyText',
        Aggregated: ({ cell: { value } }: any) => `${value} CustomerId Stripe`,
        disableGroupBy: true,
      },
      {
        Header: 'Stripe último pago',
        accessor: 'stripe_last_payment_date',
        aggregate: 'count',
        filter: 'fuzzyText',
        Aggregated: ({ cell: { value } }: any) => `${value} CustomerId Stripe`,
        disableGroupBy: true,
      },
      {
        Header: 'Stripe próximo pago',
        accessor: 'stripe_next_payment_date',
        aggregate: 'count',
        filter: 'fuzzyText',
        Aggregated: ({ cell: { value } }: any) => `${value} CustomerId Stripe`,
        disableGroupBy: true,
      },
    ],
  },

]; 

export default function ListUsers(): JSX.Element {

  const { getAllUsers, deleteAccountForAdmin } = UserService();

  const [data, setData] = useState<any[]>([]);
  const [userToEdit, setUserToEdit] = useState<ISimulation>();
  const [isViewEditUser, setIsViewUserEdit] = useState<boolean>(false);
  const [userToDelete, setUserToDelete] = useState<any>();
  const [viewModal, setViewModal] = useState(false);

  async function getUsers() {
    const fetchUser: any[] = await getAllUsers();
    setData(fetchUser);
  }

  useEffect(() => {
    getUsers();
  }, []);

  const editSimulation = useCallback(
    (instance: any) => () => {        
      instance.selectedFlatRows.map((v: any) => {
        setUserToEdit(v.original);
      });
      setIsViewUserEdit(true);
    },
    []
  );

  const onDeleteUser = useCallback(
    (instance: any) => () => {
      instance.selectedFlatRows.map( async (v: any) => {
        setUserToDelete(v.original);
      });     
    },
    []
  );

  const deleteUser = async () => {
    if (userToDelete)
      await deleteAccountForAdmin(userToDelete.subscription_id);
    window.location.reload();
  };

  return (
    <>
      <ConfirmationModal 
        text={`¿Quieres borrar al usuario ${userToDelete?.email}?`} 
        onAccept={() => deleteUser()} 
        onClose={() => setViewModal(false)} 
        visible={viewModal}/>
      { isViewEditUser && userToEdit ? 
        <div className="w-full flex-1 flex flex-col items-center relative pt-12">
            <MainButton 
            className="absolute top-0 left-0"
            type={FLOATING_BUTTON} 
            onClick={() => {
              setUserToEdit(undefined);
              setIsViewUserEdit(false);
            }}>
              <GoChevronLeft size={16}/>
              Atrás
            </MainButton>
            <EditUserForm 
            user={userToEdit} 
            onEditUser={() => {
              setUserToEdit(undefined);
              setIsViewUserEdit(false);
              getUsers();
            }}/>
        </div>
        :
        <div className="w-[70vw]">
          <DefaultTable<any>
            name={'usersTable'}
            columns={columns}
            data={data}
            onClick={(e: any) => {
              setUserToEdit(e.original);
              setIsViewUserEdit(true);
            }}                  
            onDeleteUser={onDeleteUser}
          />
        </div>
      }
    </>
  );
};
