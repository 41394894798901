import { useState } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import errorService from "../../services/error.service";
import { IFormValues } from "../../interfaces/types";
import PasswordInput from "../inputs/PasswordInput";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import UserService from "../../services/httpServices/user.service";
import ToastService from "../../services/toastService";


export default function UpdatePasswordComponent({ className = '' }: { className?: string }): JSX.Element {

  const { updatePassword } = UserService();
  const { showSuccessToast, showErrorToast } = ToastService();

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required('La contraseña es requerida').min(8, 'La longitud minima debe de ser 8'),
    newPassword: Yup.string()
      .required('La confirmación de contraseña es requerida').min(8, 'La longitud minima debe de ser 8')
  });

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    control,
  } = useForm<IFormValues>({ resolver: yupResolver(validationSchema), mode: 'onChange'});

  const currentPassword = useWatch({ control, name: 'currentPassword', defaultValue: '' });
  const newPassword = useWatch({ control, name: 'newPassword', defaultValue: '' });

  const [recoverPasswordError, setRecoverPasswordError] = useState<string[]>([]);
  const [showErrors, setShowErrors] = useState(false);

  const onSubmit: SubmitHandler<IFormValues> = async (formData: { currentPassword: string; newPassword: string; }) => {
    const { currentPassword, newPassword } = formData;
    try {
      await updatePassword(currentPassword, newPassword);
      showSuccessToast('La contraseña se ha actualizado con éxito');
      setShowErrors(false);
    } catch (error: unknown) {
      showErrorToast('La contraseña no se ha actualizado con éxito');
      const castedError = errorService.castError(error);
      setRecoverPasswordError([JSON.stringify(castedError.message)]);
      setShowErrors(true);
    }
  };

  return (
    <div className="flex flex-col items-center px-6">
      <h2 className="text-1xl font-semibold">Actualizar contraseña</h2>
      <form className="w-full sm:max-w-[500px] mt-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-2">
          <label
            htmlFor="currentPassword"
            className="block text-sm font-semibold text-gray-800"
          >
            Contraseña actual
          </label>
          <PasswordInput
            placeholder="Contraseña Actual"
            label={'currentPassword'}
            register={register}
            required={true}
            minLength={{ value: 8, message: 'El tamaño mínimo son 8 digitos' }}
            error={errors.currentPassword}
          />
          {errors.currentPassword?.message && (
            <span className="text-sm text-error-100">{errors.currentPassword.message}</span>
          )}
        </div>
        <div className="mb-2">
          <label
            htmlFor="newPassword"
            className="block text-sm font-semibold text-gray-800"
          >
            Nueva contraseña
          </label>
          <PasswordInput
            placeholder="Nueva Contraseña"
            label={'newPassword'}
            register={register}
            required={true}
            minLength={{ value: 8, message: 'El tamaño mínimo son 8 digito' }}
            error={errors.newPassword}
          />
          { errors.newPassword?.message && (
            <span className="text-sm text-error-100">{errors.newPassword.message}</span>
          )}
        </div>
        { recoverPasswordError && showErrors && (
          <div className="flex flex-row items-center pt-1 text-red-600	">
            <span className="font-base bg-yellow-50 text-sm text-red-600">{recoverPasswordError}</span>
          </div>
        )}
        <div className="mt-6 text-center">
          <button 
            type="submit"
            disabled={currentPassword.length >= 8 && newPassword.length >= 8 ? false : true} 
            name="submit" 
            className="px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-primary-100 rounded-md hover:bg-primary-100 focus:outline-none focus:bg-primary-100 disabled:opacity-50">
                  Actualizar contraseña
          </button>
        </div>  
      </form>       
    </div>
  );
};
        