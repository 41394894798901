import tabletLogo from '../../../../../assets/images/tabletlogo.png';


export default function ThirdSection() {
  return (
    <div className="flex flex-col sm:flex-row-reverse justify-evenly text-justify sm:text-left items-center px-8 xl:px-60 sm:gap-10">
      <div className='max-w-[500px]'>
        <h1 className="text-2xl text-text-black font-semibold" style={{fontFamily: 'serif'}}>Elige el test que se adapta a tu oposición</h1>
        <h1 className="mt-3 text-text-500">
          <b>
            En solo tres clicks podrás acceder a los simulacros de la Generalitat Valenciana y de Entidades Locales de la Comunidad Valenciana:
          </b> Ayuntamientos y Diputaciones, adaptados al nivel de tu oposición.
        </h1>
        <h3 className="mt-3 text-text-500">
          También tendras tests de cada norma, y además podrás
          <b> elegir la cantidad de preguntas que quieres responder en cada momento</b>
        </h3>
        <h3 className="mt-3 text-text-500"><b>¡La plataforma que se adapta a tí!</b></h3>
      </div>
      <img src={tabletLogo} alt={'App Auténtica Test'} className='mt-10 mx-auto sm:mx-0'/>
    </div>
  );
};
