import { useAxios } from "../../contexts/axiosContext";


export default function SimulationService() {

  const { publicAxios } = useAxios();

  function getAllSimulations() {
    return new Promise((resolve, reject) => {
      publicAxios.get(`/api/v1/simulation/`)
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function downloadSimulation(id) {
    return new Promise((resolve, reject) => {
      publicAxios.get(`/api/v1/simulation/download/${id}`, {
        responseType: 'blob'
      })
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function deleteSimulation(ids) {
    return new Promise((resolve, reject) => {
      publicAxios.post(`/api/v1/simulation/delete/admin`, { ids })
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function createSimulation(simulation) {
    return new Promise((resolve, reject) => {
      publicAxios.put(`/api/v1/simulation`, {
        name: simulation.name,
        sections: simulation.sections
      })
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function createCustomTest(customTest) {
    return new Promise((resolve, reject) => {
      publicAxios.post(`/api/v1/simulation/createCustomTest`, {
        customTest
      })
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function updateSimulation(simulation, id) {
    return new Promise((resolve, reject) => {
      publicAxios.post(`/api/v1/simulation/${id}`, {
        name: simulation.name,
        sections: simulation.sections
      })
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  return {
    createCustomTest,
    getAllSimulations,
    downloadSimulation,
    deleteSimulation,
    createSimulation,
    updateSimulation
  };
}