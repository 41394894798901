export default function FAQS(props: any) {
  return (
    <div className="flex flex-col items-center px-8">
      <h2 className="text-3xl font-bold text-text-100 text-center">
        Preguntas Frecuentes
      </h2>
      <p className="mt-4 mb-8 text-text-300 text-center">
        Preguntas frecuentes sobre la aplicación de Auténtica Test.
      </p>
      <div className="flex flex-col gap-5 w-full max-w-[800px]">
        <details className="transition rounded-lg ring-1 hover:ring-2 ring-gray-300">
          <summary className="cursor-pointer p-6 select-none">
            ¿Cuál es el método de pago?
          </summary>
          <p className="text-gray-600 px-6 pb-6">
          Se realiza un pago mensual, ya que se trata de un modelo de suscripción. 
          Cada mes se realizará un pago recurrente de forma automática de 9,90€, en tanto no te des de baja. 
          Si tienes alguna oferta de lanzamiento o descuento, sólo se aplicará en el primer pago.
          </p>
        </details>
        <details className="transition rounded-lg ring-1 hover:ring-2 ring-gray-300">
          <summary className="cursor-pointer p-6 select-none">
            ¿Tengo compromiso?
          </summary>
          <p className="text-gray-600 px-6 pb-6">
          Ninguno. En cualquier momento puedes cancelar la suscripción y ya no te cobraremos el pago del mes siguiente. 
          Para darte de baja, lo único que tendrás que hacer es acceder a tu perfil y seleccionar el botón de {'"Me voy a dar de baja"'}. 
          Además, ten en cuenta, que en el momento en que te des de baja, 
          ya no podrás hacer acceder a la plataforma desde ese momento, independientemente del resto de tiempo que te quede.
          </p>
        </details>
        <details className="transition rounded-lg ring-1 hover:ring-2 ring-gray-300">
          <summary className="cursor-pointer p-6 select-none">
          ¿Cómo sé si el temario de los test son de mi oposición?
          </summary>
          <p className="text-gray-600 px-6 pb-6">
          La plataforma de Auténtica Test está específicamente diseñada para Oposiciones de GVA {'(Generalitat Valenciana)'}, así como para Entidades Locales de la Comunidad Valenciana. Es decir, si te presentas a cualquier administración de la Comunidad Valenciana. ¡Esta plataforma es para tí! <br></br>
          Para los simulacros de la Generalitat, se siguen las convocatorias oficiales. Para los simulacros de Administración Local, se ha seguido una convocatoria tipo.
          </p>
        </details>
        <details className="transition rounded-lg ring-1 hover:ring-2 ring-gray-300">
          <summary className="cursor-pointer p-6 select-none">
          No sé cómo hacer un simulacro
          </summary>
          <p className="text-gray-600 px-6 pb-6">
          Entra con tu usuario y contraseña {'(tendrás que suscribirte antes para poder acceder)'}. Selecciona en la primera pantalla: Simulacro. A continuación, selecciona si es de GVA {'(Generalitat Valenciana)'} o Local {'(Entidades Locales)'}. 
          A continuación elige el subgrupo que quieras: A1, A2, C1, C2 y ¡listo! 
          La generación de los simulacros es aleatoria. Un simulacro distinto cada vez!
          </p>
        </details>
        <details className="transition rounded-lg ring-1 hover:ring-2 ring-gray-300">
          <summary className="cursor-pointer p-6 select-none">
          ¿Cómo hago un test de una normativa?
          </summary>
          <p className="text-gray-600 px-6 pb-6">
          Entra con tu usuario y contraseña {'(tendrás que suscribirte antes para poder acceder)'}. 
          Selecciona en la primera pantalla: Normativa. 
          A continuación, selecciona el bloque: Contratos, Derecho Administrativo, Derecho Constitucional, Función Pública, etc… 
          Elige el número de preguntas que quieres hacer: 10, 20 ó 40 y ¡listo!
          </p>
        </details>
        <details className="transition rounded-lg ring-1 hover:ring-2 ring-gray-300">
          <summary className="cursor-pointer p-6 select-none">
          ¿Cómo entro en mi perfil?
          </summary>
          <p className="text-gray-600 px-6 pb-6">
          Simplemente entra en la plataforma, desde el menú de arriba a la derecha. 
          Entra con tu usuario y contraseña {'(tendrás que suscribirte antes para poder acceder).'}  
          Una vez en la plataforma arriba a la derecha, tienes un icono con una forma de opositor/a. Accede y modifica tu perfil.
          </p>
        </details>
      </div>
    </div>
  );
}