import './App.scss';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { AuthProvider } from './app/contexts/authContext';
import { AxiosProvider } from './app/contexts/axiosContext';
import { SubscriptionProvider } from './app/contexts/subscriptionContext';
import CacheBuster from './app/services/CatcheBuster';
import Router from './app/router';
import FloatingButton from './app/components/buttons/FloatingButton';
import CookiesConsent from './app/components/cookies/CookiesConsent';
import cookiesService from './app/services/cookies.service';
import './assets/i18n/i18n';

function App() {
  const cookie = cookiesService.getCookie('consent');

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }: { loading: boolean, isLatestVersion: boolean, refreshCacheAndReload: () => void }) => {
        if (loading) return null;
        if (!isLatestVersion) {
          refreshCacheAndReload();
        }
        return (
          <AuthProvider>
            <AxiosProvider>
              <SubscriptionProvider>
                <CookiesProvider>
                  {cookie ? (
                    <>
                      <Router />
                      <FloatingButton />
                    </>
                  ) : (
                    <CookiesConsent />
                  )}
                  <ToastContainer
                    position={toast.POSITION.TOP_CENTER}
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    limit={1}
                    closeOnClick
                    rtl={false}
                    draggable={false}
                    pauseOnFocusLoss
                    pauseOnHover
                    theme='colored'
                  />
                </CookiesProvider>
              </SubscriptionProvider>
            </AxiosProvider>
          </AuthProvider>
        );
      }}
    </CacheBuster>
  );
}

export default App;
