import { Modal } from "flowbite-react"
import { BsClockFill } from "react-icons/bs"
import MainButton from "../../buttons/MainButton"

interface PauseModalProps  {
  onClick:()=>void,
  show: boolean
}
export default function PauseModal(props:PauseModalProps){
  return <Modal
    show={!props.show}
    size="md"
    popup={true}
  >
  <Modal.Body>
    <div className="text-center mt-8">
      <BsClockFill className="mx-auto mb-4 h-14 w-14 text-gray-400" />
      <h3 className="mb-5 text-lg font-normal text-gray-700">
        El test esta pausado
      </h3>
      <p>
        Reanuda el test para continuar. El tiempo empezará a correr inmediatamente
      </p>
      <div className="flex mt-5 justify-center gap-4">
        <MainButton
          onClick={() => props.onClick()}
        >
          {'Reanudar'}
        </MainButton>
      </div>
    </div>
  </Modal.Body>
</Modal>
}