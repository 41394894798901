import { Button } from "flowbite-react";
import { Dispatch, SetStateAction, useState } from "react";
import img1 from '../../../../assets/avatars/img1.jpg';
import img2 from '../../../../assets/avatars/img2.jpg';
import img3 from '../../../../assets/avatars/img3.jpg';
import img4 from '../../../../assets/avatars/img4.jpg';
import img5 from '../../../../assets/avatars/img5.jpg';
import img6 from '../../../../assets/avatars/img6.jpg';
import notavatar from '../../../../assets/avatars/notavatar.png';
import UserService from "../../../services/httpServices/user.service";
import ToastService from "../../../services/toastService";


export default function ModalSelectAvatar({
  diameter,
  setOpenSelectAvatarModal,
  setSrc
}: {
    diameter: number,
    setOpenSelectAvatarModal: Dispatch<SetStateAction<boolean>> 
    setSrc: Dispatch<SetStateAction<string>>;
  }): JSX.Element {

  const { updateAvatar } = UserService();
  const { showSuccessToast, showErrorToast } = ToastService();
  
  const [selected, setSelected] = useState('');

  const close = (e: any) => {
    e.preventDefault();
    setOpenSelectAvatarModal(false);
  };

  const putSrc = async (img: string) => {
    try {
      await updateAvatar(img === notavatar ? '' : img);
      setSrc(img);
      setOpenSelectAvatarModal(false);
      window.location.reload();
    } catch (error) {
      showErrorToast('El avatar no se ha podido actualizar con éxito');
    }
  };

  
  return (
    <div>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
      >
        <div className="relative w-full my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">
                    Selecciona un Avatar
              </h3>

            </div>
            {/*body*/}
            <div className="container m-auto grid grid-cols-3 md:grid-cols lg:grid-cols-3 gap-10">
              <div>
                <img className={`w-10/12 rounded-full cursor-pointer ${selected === img1 ? 'border-8 border-black': ''}`} src={img1} onClick={()=> setSelected(img1)}/>
                <span className="md:ml-10 text-gray-400">Imagen 1</span>
              </div>
              <div>
                <img  className={`w-10/12 rounded-full cursor-pointer ${selected === img2 ? 'border-8 border-black': ''}`} src={img2} onClick={()=> setSelected(img2)}/>
                <span className="md:ml-10 text-gray-400">Imagen 2</span>
              </div>
              <div>
                <img className={`w-10/12 rounded-full cursor-pointer ${selected === img3 ? 'border-8 border-black': ''}`} src={img3} onClick={()=> setSelected(img3)}/>
                <span className="md:ml-10 text-gray-400">Imagen 3</span>
              </div>
              <div>
                <img className={`w-10/12 rounded-full cursor-pointer ${selected === img4 ? 'border-8 border-black': ''}`} src={img4} onClick={()=> setSelected(img4)}/>
                <span className="md:ml-10 text-gray-400">Imagen 4</span>
              </div>
              <div>
                <img className={`w-10/12 rounded-full cursor-pointer ${selected === img5 ? 'border-8 border-black': ''}`} src={img5} onClick={()=> setSelected(img5)} />
                <span className="md:ml-10 text-gray-400">Imagen 5</span>
              </div>
              <div>
                <img  className={`w-10/12 rounded-full cursor-pointer ${selected === img6 ? 'border-8 border-black': ''}`} src={img6} onClick={()=> setSelected(img6)}/>
                <span className="md:ml-10 text-gray-400">Imagen 6</span>
              </div>
              <div>
                <img  className={`w-10/12 rounded-full border-4 cursor-pointer ${selected === notavatar ? 'border-8 border-black': ''}`} src={notavatar} onClick={()=> setSelected(notavatar)}/>
                <span className="md:ml-10 text-gray-400">Sin avatar</span>
              </div>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <Button
                color="gray"
                onClick={close}
                className='hover:bg-gray hover:text-black md:mr-2'
              >
            Cancelar
              </Button>
              <Button className="md:mr-4 bg-primary-100 font-medium text-white hover:border-[#696969] hover:bg-white hover:text-black disabled:opacity-50 hover:disabled:bg-[#FCE96A]"
                type="submit"
                color='primary-100'
                onClick={() => putSrc(selected)}
                disabled={selected ? false : true}
              >
                    Guardar
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  );
}