import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TabSelector from '../components/tabSelector/TabSelector';

import ListQuestionComponent from '../components/admin-panel/questions/listQuestions';
import UploadQuestionComponent from '../components/admin-panel/questions/uploadQuestions';
import ListReportQuestions from '../components/admin-panel/reports/listReportOption';
import TabsDisplay from '../components/admin-panel/TabsDisplay';


export default function ReportReviewerPanelView(): JSX.Element {

  const history = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const firstRun = useRef(true);
  const TABS = [
    {
      id: 'questions',
      label: 'Preguntas',
      tabs: [
        { id: 'list', label: 'Listar preguntas', component: <ListQuestionComponent/> },
        { id: 'upload', label: 'Subir CSV', component: <UploadQuestionComponent/> },
      ]
    },
    {
      id: 'reports',
      label: 'Reportes',
      tabs: [
        { id: 'list', label: 'Listar reportes', component: <ListReportQuestions/> },
      ]
    },
  ];

  const [activeTab, setActiveTab] = useState('test');

  function updateUrlWithState() {
    history({ search: new URLSearchParams({ tab: activeTab }).toString() });
  }

  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    updateUrlWithState();
  }, [activeTab]);

  useEffect(() => {
    const tab = 'reports';
    if(tab && TABS.find(t => t.id === tab)) setActiveTab(tab);
    else if (!tab) updateUrlWithState();
  }, []);

  return (
    <div className='flex-1 flex flex-col overflow-hidden'>
      <TabSelector tabs={TABS} activeTab={activeTab} onChange={setActiveTab}/>
      { TABS.map(tab => (
        <div className={`flex-1 overflow-hidden ${activeTab === tab.id ? 'flex' : 'hidden'}`} key={tab.id}>
          <TabsDisplay activeTab={activeTab}/>
        </div>
      ))}
    </div>
  );
}
