export const PRIMARY_COLOR = '#6BCAE2';
export const FREE_TEST_TRIAL_URL = 'https://www.autenticatest.com/prueba-gratis-simulacro-test/';

/* HTTP */

export const HTTP_CODES = {
  SUCCESS: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  CONFLICT: 409,
  BAD_GATEWAY: 502
}

/* PASSWORD VALIDATORS */

export const PASS_MUST_INCLUDE_NUMBERS = true;
export const PASS_MUST_INCLUDE_LOWER_AND_UPPER_CASE = true;
export const PASS_MUST_INCLUDE_SPECIAL_CHAR = false;
export const PASS_MIN_LENGTH = 8;

/* TABLES PAGINATION */

export const ITEMS_PER_PAGE = 20;

/* BUTTON TYPES */

export const PRIMARY_BUTTON = 'primary';
export const SECONDARY_BUTTON = 'secondary';
export const DESIST_BUTTON = 'desist';
export const SECONDARY_DESIST_BUTTON = 'secondary_desist';
export const SECONDARY_GRAY_BUTTON = 'secondary_gray';
export const TRANSPARENT_BUTTON = 'transparent';
export const ICON_BUTTON = 'icon';
export const ROUND_BUTTON = 'round';
export const ROUND_RED_BUTTON = 'round_red';
export const ROUND_BLACK_BUTTON = 'round_black';
export const ROUND_SECONDARY_BUTTON = 'round_secondary';
export const ROUND_WHITE_BUTTON = 'round_white';
export const ADMIN_BUTTON = 'admin';
export const FLOATING_BUTTON = 'floating';

/* DROPDOWN STYLES */

export const SECONDARY_DROPDOWN = 'secondary';

/* INPUT TYPES */

export const PASSWORD = 'password';
export const SEARCH = 'search';

/* INPUT STYLES */

export const SECONDARY_INPUT = 'secondary';


/* COMPONENTS TYPES */

export const COMPONENTS_TYPES = {
  SELECT_CUSTOM_OR_LAW: 'selectCustomOrLaw',
}