import { Fragment, useState } from "react";
import { Button, Modal} from 'flowbite-react'; 
import { HiOutlineExclamationCircle } from "react-icons/hi";
import ToastService from "../../services/toastService";
import MainButton from "../buttons/MainButton";
import { useNavigate } from "react-router-dom";


interface ModalProps {
  title: string;
  description: string;
  actionText: string;
  actionFunction: () => Promise<void>;
  onClose?: (()=>void) | null;
}

const SubscriptionFailureModal: React.FC<ModalProps> = ({
  description,
  actionText,
  title,
  actionFunction,
  onClose,
}) => {

  const { showErrorToast } = ToastService();

  const [openModal, setOpenModal] = useState(true);

  const close = () => {
    setOpenModal(false);
    onClose?.();
  };

  const accept = async () => {
    try {
      const url = await actionFunction();
    } catch (error) {
      showErrorToast("Error al acceder a la pantalla de pago, por favor ponganse en contacto con nosotros")
    }
  };

  return (
    <Fragment>
      <Modal show={openModal} size="md" popup={true} onClose={close}>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-red-400" />
            <h1 className="text-lg font-normal text-red-500">{title}</h1>
            <h3 className="mb-5 text-lg font-normal text-gray-500">{description}</h3>
            <div className="flex justify-center gap-4">
              <Button color="gray" onClick={close}>
                Cancelar
              </Button>
              <MainButton isSubmitButton={false} onClick={accept}>
                {actionText}
              </MainButton>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default SubscriptionFailureModal;
