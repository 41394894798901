import { useAxios } from "../../contexts/axiosContext";


export default function TestService() {

  const { publicAxios } = useAxios();

  function getSimulation(nameTest) {
    return new Promise((resolve, reject) => {
      publicAxios.get(`/api/v1/simulation/populate/${nameTest}`)
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function getLaw(size, standard, subject_block) {
    return new Promise((resolve, reject) => {
      publicAxios.post(`/api/v1/simulation/populate/law`, {
        size,
        standard,
        subject_block
      })
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  function getLawCustom(size, standard, subject_block, title, range_article, level_range) {
    return new Promise((resolve, reject) => {
      publicAxios.post(`/api/v1/simulation/populate/law`, {
        size,
        standard,
        subject_block,
        title: title == '' ? null : title,
        range_article: range_article == '' ? null : range_article,
        level_range: level_range == '' ? null : level_range,
      })
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function correctTest(questions, id) {
    return new Promise((resolve, reject) => {
      publicAxios.post(`/api/v1/test/correct/${id}`, {
        questions,
        isTest: false
      })
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function getTestQuestionsFailed() {
    return new Promise((resolve, reject) => {
      publicAxios.get(`/api/v1/test/failed`)
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function getTestQuestionsNotAnswered() {
    return new Promise((resolve, reject) => {
      publicAxios.get(`/api/v1/test/notAnswered`)
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function getTrialingTest(_id) {
    return new Promise((resolve, reject) => {
      publicAxios.get(`/api/v1/test/${_id}`, {
        params: {
          "populate": "sections.questions"
        }
      })
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function saveTestNoFinalized(test, id) {
    return new Promise((resolve, reject) => {
      publicAxios.post(`/api/v1/testSavedForLater/saveUnfinishedTest/`, {
        test
      })
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function getTestNoFinalized(page, pageSize) {
    return new Promise((resolve, reject) => {
      publicAxios.get(`/api/v1/testSavedForLater/getUnfinishedTest?page=${page}&pageSize=${pageSize}`)
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function recoverSpeciTestGVA(testName, testUnfinishedId) {
    return new Promise((resolve, reject) => {
      publicAxios.post(`/api/v1/simulation/getTestUnfinished/${testName}`, {
        testUnfinishedId
      })
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function recoverSpeciTestLaw(subjectBlock, testUnfinishedId, standard) {
    return new Promise((resolve, reject) => {
      publicAxios.post(`/api/v1/simulation/getTestUnfinishedLaw/`, {
        testUnfinishedId,
        subjectBlock,
        standard,
      })
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  function deleteTestRecover(idTesRecover) {
    return new Promise((resolve, reject) => {
      publicAxios.delete(`/api/v1/testSavedForLater/deleteUnfinishedTest?idTestRecovered=${idTesRecover}`)
        .then(async (res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function recoverCustomTestUnfinished(testUnfinishedId) {
    return new Promise((resolve, reject) => {
      publicAxios.post(`/api/v1/simulation/unfinishedCustomTestToTest`, {
        testUnfinishedId
      })
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function recoverTestWithDoubtful() {
    return new Promise((resolve, reject) => {
      publicAxios.get(`/api/v1/test/doubtful`)
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };


  return {
    deleteTestRecover,
    recoverSpeciTestLaw,
    recoverSpeciTestGVA,
    getTestNoFinalized,
    getSimulation,
    getLaw,
    correctTest,
    getTestQuestionsFailed,
    getTestQuestionsNotAnswered,
    getTrialingTest,
    saveTestNoFinalized,
    getLawCustom,
    recoverCustomTestUnfinished,
    recoverTestWithDoubtful

  };
}
