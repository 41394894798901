import { useEffect, useState } from 'react';
import SectionCard from './SectionCard';
import TextInput from '../../../inputs/TextInput';
import SimulationService from '../../../../services/httpServices/simulation.service';
import ToastService from '../../../../services/toastService';
import MainButton from '../../../buttons/MainButton';
import { PRIMARY_BUTTON, SECONDARY_BUTTON, SECONDARY_GRAY_BUTTON } from '../../../../config/constants';
import { BsPlus } from 'react-icons/bs';
import UtilsService from '../../../../services/utils.service';
import QuestionService from '../../../../services/httpServices/question.service';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { capitalizeFirstLetter } from '../../../../helpers/stringFormater';


export interface CreateTestProps {
  active?: boolean
  simulation?: any,
  customTest?: boolean
}

export default function CreateTest(props: CreateTestProps): JSX.Element {
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = ToastService();
  const { createSimulation, updateSimulation, downloadSimulation, createCustomTest } = SimulationService();
  const { downloadPDFblob } = UtilsService();
  const { getQuestionsForCreateSimulations } = QuestionService();
  const [simulation, setSimulation] = useState<any>(props.simulation);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>(props.simulation?.name ?? '');
  const [options, setOptions] = useState<any>([]);
  const BASE_SECTION = {
    article_ranges: '',
    level_range: '',
    number: '',
    standard: '',
    subject_block: '',
    title: ''
  }
  const [sections, setSections] = useState<any>(simulation?.sections ?? []);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  useEffect(() => {
    function getSimulations() {
      getQuestionsForCreateSimulations().then((simulations: any[]) => {
        setOptions(simulations);
      }).catch((err) => {
        // TODO: handle errors
      });
    }
    getSimulations();
  }, []);


  useEffect(() => {

    if (props.active) {
      setName('')
      setSections([])
      setSimulation(null)
    }
  }, [props.active]);

  const removeSection = (index: number) => {
    setSections([...sections.slice(0, index), ...sections.slice(index + 1)]);
  };

  const updateSection = (index: number, section: any) => {
    sections[index] = section;
    setSections([...sections]);
  };

  const appendItem = (i?: number) => {
    if (i) {
      sections.splice(i, 0, BASE_SECTION);
      setSections([...sections]);
    } else {
      sections.push(BASE_SECTION);
      setSections([...sections]);
    }
  };

  const downloadPDF = async () => {
    setIsDownloading(true);
    downloadSimulation(simulation._id).then((blobPDF: any) => {
      downloadPDFblob(blobPDF, `${simulation.name}.pdf`);
    }).catch((err: any) => {
      // TODO: handle errors
    }).finally(() => {
      setIsDownloading(false);
    })
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();
    const data = { name, sections };
    setIsLoading(true);
    if (simulation?._id) {
      updateSimulation(data, simulation._id).then(res => {
        showSuccessToast(`El test "${data.name}" se ha actualizado con éxito`);
      }).catch(err => {
        showErrorToast(`Se ha producido un error al actualizar el test "${data.name}", por favor, vuelve a intentarlo más tarde`);
      }).finally(() => {
        setIsLoading(false);
      })
    } else if (props.customTest) {
      createCustomTest(data).then(res => {
        navigate(`doCustomTest`, {
          state: {
            type: 'customTest',
            test: res,
            sectionsStructure: data
          }
        });

      }).catch(err => {
        if (err?.response?.data?.error?.codeName === 'DuplicateKey') {
          showErrorToast(`El test "${data.name}" ya existe, inténtalo con otro nombre`);
        } else {
          showErrorToast(`Se ha producido un error al crear el test "${data.name}", por favor, vuelve a intentarlo más tarde`);
        }
      }).finally(() => {
        setIsLoading(false);
      })
    } else {
      createSimulation(data).then(res => {
        showSuccessToast(`El test "${data.name}" se ha creado con éxito`);
        setSimulation(res)
      }).catch(err => {
        if (err?.response?.data?.error?.codeName === 'DuplicateKey') {
          showErrorToast(`El test "${data.name}" ya existe, inténtalo con otro nombre`);
        } else {
          showErrorToast(`Se ha producido un error al crear el test "${data.name}", por favor, vuelve a intentarlo más tarde`);
        }
      }).finally(() => {
        setIsLoading(false);
      })

    }
  };

  return (
    <form
      onSubmit={onSubmit}
      className={`flex flex-col gap-5 p-10 border border-gray-300 rounded-lg mt-5 w-full sm:min-w-[500px] sm:max-w-[800px] ${props.customTest ? ' bg-primary-400' : ''}`}>

      <div className='font-bold text-2xl text-text-200'>
        {props.customTest ? 'Crear test' : simulation?._id ? 'Editar simulacro' : 'Crear Simulacro'}
      </div>

      <div>
        <TextInput
          placeholder={props.customTest ? "Nombre de test" : "Nombre de simulacro"}
          type="text"
          value={name}
          minLength={{ value: 1, message: props.customTest ? "El nombre del  no puede estar vacío" : 'El nombre del simulacro no puede estar vacío' }}
          onChange={(e: any) => setName(props.customTest ? capitalizeFirstLetter(e.target.value) : e.target.value)}
        />
      </div>

      {sections.map((section: any, i: any) => (
        <div key={`section-${i}`}>
          <SectionCard
            customTest={props.customTest}
            id={i}
            options={options}
            section={section}
            onRemove={() => removeSection(i)}
            onUpdate={(sect: any) => updateSection(i, sect)} />
          {i < sections.length - 1 &&
            <MainButton
              className="mt-5"
              // disabled={options.length === 0 || (props.customTest && section.length === 3)}
              disabled={props.customTest ? sections.length < 3 ? false : true : options.length === 0}
              type={props.customTest ? PRIMARY_BUTTON : SECONDARY_GRAY_BUTTON}
              onClick={() => appendItem(i + 1)}>
              <BsPlus size={24} />
              Añadir nueva sección
            </MainButton>
          }
        </div>
      ))
      }

      <div className='flex flex-col sm:flex-row items-center gap-3 justify-between'>
        {simulation?._id ?
          <>
            <MainButton
              className="w-full sm:w-auto"
              type={SECONDARY_GRAY_BUTTON}
              onClick={() => downloadPDF()}>
              {isDownloading ? 'Descargando PDF....' : 'Descargar Simulacro PDF'}
            </MainButton>
            <MainButton
              className="w-full sm:w-auto"
              isSubmitButton={true}
              disabled={name.length === 0 || sections.length === 0 || options.length === 0}>
              Actualizar
            </MainButton>
          </> :
          <>
            {options.length === 0 ?
              <Skeleton width={150} height={45} /> :
              <MainButton
                className="w-full sm:w-auto"
                disabled={props.customTest ? sections.length < 3 ? false : true : options.length === 0}
                type={props.customTest ? PRIMARY_BUTTON : SECONDARY_GRAY_BUTTON}
                onClick={() => appendItem()}>
                <BsPlus size={24} />
                Añadir nueva sección
              </MainButton>
            }
            <MainButton
              className="w-full sm:w-auto"
              isSubmitButton={true}
              disabled={name.length === 0 || sections.length === 0 || options.length === 0 || isLoading}>
              {props.customTest ? 'Hacer Test' : 'Crear'}
            </MainButton>
          </>
        }
      </div>
    </form >
  );
};
