import { useEffect, useState } from "react";
import { RoleType } from "../../interfaces/types";
import { Navbar, Dropdown, Avatar } from 'flowbite-react';
import navbaricon3clicks from '../../../assets/images/navbaricon3clicks.png';
import student from '../../../assets/icons/student.svg';
import { useAuth } from "../../contexts/authContext";
import ReportService from "../../services/httpServices/report.service";
import UserService from "../../services/httpServices/user.service";
import { storageService } from "../../services/storageService/storageService";
import { TbDotsVertical } from "react-icons/tb";
import logoAutentica from "../../../assets/icons/logo.png";
import MainButton from "../buttons/MainButton";
import { ADMIN_BUTTON, FREE_TEST_TRIAL_URL, ICON_BUTTON, TRANSPARENT_BUTTON } from "../../config/constants";
import { useNavigate } from "react-router-dom";
import paymentServiceStripe from "../../services/paymentStripe.service";
import PaymentService from "../../services/httpServices/payment.service";
import { useSubscription } from "../../contexts/subscriptionContext";
import { NoSubscriptionModal } from "../../components/home/SubscriptionFailureModals";


export default function NavigationBar(props: any) {

  const { countReports } = ReportService();
  const auth = useAuth();
  const subscription = useSubscription();
  const navigate = useNavigate();
  const { getInfoUser } = UserService();
  const { createCheckoutSession } = PaymentService();

  const [reports, setReports] = useState(0);
  const [srcAvatar, setSrcAvatar] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const [subscriptionActive, setSubscriptionActive] = useState(false)
  const [loadingDataUser, setLoadingDataUser] = useState(true);
  const [animate, setAnimate] = useState(true);
  const [animationClass, setAnimationClass] = useState('');
  const [showNoSubscriptionModal, setShowNoSubscriptionModal] = useState(false);


  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        if (auth.hasUserSubscriptionAccess()) {
          setSubscriptionActive(true)
          setAnimationClass('fade-in');
        }
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          auth.logout();
        }
      } finally {
        setIsLoading(false);
      }
    })()
  }, [auth.user]);

  const getTotalReports = () => {
    countReports().then((response) => {
      setReports(response);
    }).catch((error) => {
      //TODO: handle error
    }).finally(() => {
      setLoadingDataUser(false);
    })
  }


  const getUserData = async () => {
    getInfoUser().then((user) => {
      auth.updateUser(user);
      if (user.avatar) setSrcAvatar(user.avatar);
    }).catch((error) => {
      //TODO: control error
    }).finally(() => {
      setIsLoading(false);
    })
  }

  useEffect(() => {
    if (auth.token) {
      getUserData();
      getTotalReports();
    }
  }, [auth.token]);

  const logout = () => {
    storageService.clearAllData();
    navigate(0)
  };

  useEffect(() => {
    if (!isLoading) {
      const timer = setTimeout(() => {
        setAnimate(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isLoading]);


  const subscribe = async () => {
    if (auth.user) {
      const createSesion = await createCheckoutSession(auth.user.email);
      await paymentServiceStripe.redirectToCheckout({ sessionId: createSesion.id });
    } else {
      navigate('/register')
    }
  }

  const handleStartButtonClick = () => {
    if (auth.hasUserSubscriptionAccess()) {
      navigate('/tests');
    } else {
      setShowNoSubscriptionModal(true);
    }
  };

  const handleGoToUnfinishedTests = () => {
    if (auth.hasUserSubscriptionAccess()) {
      navigate('/testUnfinished')
    } else {
      setShowNoSubscriptionModal(true);
    }
  };

  return (
    <div className={`relative sticky top-0 z-30 w-full bg-white ${props.shadow && 'shadow-lg'}`}>
      <Navbar
        fluid={true}
        rounded={true}
        color='white'
      >
        <Navbar.Brand className="flex-1" href="/">
          <img
            src={logoAutentica}
            className="max-w-[100px]"
            alt="Auténtica test logo"
          />
        </Navbar.Brand>
        {auth.token &&
          <Navbar.Brand className="hidden sm:block">
            <img
              src={navbaricon3clicks}
              className="h-[3rem]"
              alt="Auténtica test logo"
            />
          </Navbar.Brand>
        }

        {auth.token ?
          <div className="flex flex-1 justify-end gap-4">
            <MainButton className="hidden xs:block" onClick={handleStartButtonClick}>
              Empezar
            </MainButton>
            {
              subscriptionActive &&
              <div className={animationClass}>
                <MainButton
                  className={`
                ${auth.user?.privileges === RoleType.IsAdminRole ? 'hidden xs:block' : ''}
                `} onClick={() => navigate('/customTest')}>
                  ¡A la carta!
                </MainButton>
              </div>


            }
            {auth.user?.privileges === RoleType.IsAdminRole &&
              <MainButton type={ADMIN_BUTTON} onClick={() => navigate('/admin?tab=reports')}>
                Reportes
                <span className="ml-2 text-red-800">
                  {reports}
                </span>
              </MainButton>
            }
            {auth.user?.privileges === RoleType.IsReportReviewer &&
              <MainButton type={ADMIN_BUTTON} onClick={() => navigate('/reports')}>
                Reportes
                <span className="ml-2 text-red-800">
                  {reports}
                </span>
              </MainButton>
            }
            {!loadingDataUser &&
              <Dropdown
                arrowIcon={true}
                inline={true}
                label={<Avatar alt="User settings" img={srcAvatar ? srcAvatar : student} rounded={true} />}
              >
                <Dropdown.Header>
                  <span className="block text-sm">
                    {auth.user.name} {auth.user.lastname}
                  </span>
                  <span className="block truncate text-sm font-medium">
                    {auth.user.email}
                  </span>
                </Dropdown.Header>
                {auth.user?.privileges === RoleType.IsAdminRole &&
                  <Dropdown.Item className="option-menu-logged" onClick={() => navigate('/admin')}>
                    Panel de Administrador
                  </Dropdown.Item>
                }
                <Dropdown.Item className="option-menu-logged" onClick={() => navigate('/profile')}>
                  Mi cuenta
                </Dropdown.Item>
                <Dropdown.Item className="option-menu-logged" onClick={handleGoToUnfinishedTests}>
                  Mis test guardados
                </Dropdown.Item>
                <Dropdown.Item className="option-menu-logged" onClick={() => navigate('/statistics')}>
                  Mis estadisticas
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item className="option-menu-logged" onClick={() => navigate('/FAQS')}>
                  Preguntas frecuentes
                </Dropdown.Item>
                <a href="https://www.autenticatest.com/contacta/" target="_blank" rel="noreferrer">
                  <Dropdown.Item className="option-menu-logged">
                    Contacta
                  </Dropdown.Item>
                </a>
                <Dropdown.Item className="option-menu-logged" onClick={() => logout()}>
                  Cerrar sesión
                </Dropdown.Item>
              </Dropdown>
            }
          </div> :
          <div className="flex flex-1 justify-end items-center gap-5">
            <MainButton className="hidden xs:flex" onClick={() => navigate('/register')}>
              Suscribirse
            </MainButton>
            <MainButton type={TRANSPARENT_BUTTON} onClick={() => navigate('/login')}>
              Iniciar sesión
            </MainButton>
            <Dropdown
              arrowIcon={false}
              inline={true}
              label={
                <TbDotsVertical size={24} />
              }
            >
              <a href="https://www.autenticatest.com/tutorial" target="_blank" rel="noreferrer">
                <Dropdown.Item className="option-menu">
                  Tutorial
                </Dropdown.Item>
              </a>
              <a href="https://www.autenticatest.com/que-vas-a-encontrar/" target="_blank" rel="noreferrer">
                <Dropdown.Item className="option-menu">
                  Qué vas a encontrar
                </Dropdown.Item>
              </a>
              <Dropdown.Divider />
              <Dropdown.Item className="option-menu" onClick={() => window.open(FREE_TEST_TRIAL_URL, '_blank', 'noreferrer')}>
                Prueba gratis
              </Dropdown.Item>
              <a href="https://www.autenticatest.com/tarifa-plana/" target="_blank" rel="noreferrer">
                <Dropdown.Item className="option-menu">
                  Tarifa Plana
                </Dropdown.Item>
              </a>
              <a href="https://www.autenticatest.com/contacta/" target="_blank" rel="noreferrer">
                <Dropdown.Item className="option-menu">
                  Contacta
                </Dropdown.Item>
              </a>
            </Dropdown>
          </div>
        }
      </Navbar >
      {(!auth.user || !subscriptionActive) &&
        <MainButton className="flex xs:hidden w-[96%] mx-auto mb-2" onClick={subscribe}>
          Suscribirse
        </MainButton>}
      {showNoSubscriptionModal && (
        <NoSubscriptionModal onClose={() => setShowNoSubscriptionModal(false)} />
      )}
    </div >
  );
};
