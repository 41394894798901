import { Dispatch, SetStateAction } from "react";


export default function PictureAvatar({
  src,
  diameter,
  className = '',
  setOpenSelectAvatarModal
}: {
    src: string;
    diameter: number;
    className?: string;
    setOpenSelectAvatarModal: Dispatch<SetStateAction<boolean>>; 
  }): JSX.Element {
  const selectAvatar = (e: any) => {
    e.preventDefault();
    setOpenSelectAvatarModal(true);
  };

  return (
    <>
      <img style={{ width: diameter, height: diameter }} className={`${className} rounded-full`} src={src} onClick={selectAvatar}/> 
      <p className="text-sm text-center cursor-pointer text-decoration-line: underline mt-3" onClick={selectAvatar}>
        Seleccionar avatar
      </p>
    </>
  );
}