import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import Slide from "@mui/material/Slide";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { IFormValues } from "../../interfaces/types";
import { useForm, Controller, useWatch } from "react-hook-form";
import { Select } from "flowbite-react";
import { TransitionProps } from "@mui/material/transitions";
import { Dispatch, forwardRef, SetStateAction, useEffect, useState } from "react";
import { IQuestion, IReport } from "../../interfaces";
import dateService from "../../services/date.service";
import { useAuth } from "../../contexts/authContext";
import { RoleType } from "../../interfaces/types";
import TextInput from "../inputs/TextInput";
import QuestionService from "../../services/httpServices/question.service";
import { getLastBoeVersion } from "../../services/boe.service";
import MainButton from "../buttons/MainButton";
import ToastService from "../../services/toastService";
import errorService from "../../services/error.service";

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalInfoReport({
    diameter,
    setOpenModalReportInfo,
    openModalReportInfo,
    setIsViewEditQuestion,
    setQuestionToEdit,
    info,
    type
}: {
    diameter: number;
    info: any;
    setOpenModalReportInfo: Dispatch<SetStateAction<boolean>>;
    setIsViewEditQuestion: Dispatch<SetStateAction<boolean>>;
    setQuestionToEdit: Dispatch<SetStateAction<IQuestion | undefined>>;
    openModalReportInfo: boolean;
    type: 'report' | 'question'
}): JSX.Element {
    const validationSchema = Yup.object().shape({
        statement: Yup.string().required("El enunciado de la pregunta es requerido"),
        subject_block: Yup.string().required("El bloque de materia la pregunta es requerido"),
        standard: Yup.string().required("La normativa de la pregunta es requerida"),
        option_1: Yup.string().required("La opción 1 de la pregunta es requerida"),
        option_2: Yup.string().required("La opción 2 de la pregunta es requerida"),
        option_3: Yup.string().required("La opción 3 de la pregunta es requerida"),
        option_4: Yup.string().required("La opción 4 de la pregunta es requerida"),
        article: Yup.string().required("El articulo de la pregunta  es requerido"),
        title: Yup.string().optional(),
        governmental: Yup.boolean().required("El tipo estatal de la pregunta es requerido"),
        autonomic: Yup.boolean().required("El tipo autonomico de la pregunta es requerido"),
        local: Yup.boolean().required("El tipo local de la pregunta es requerido"),
        preparer: Yup.string().required("El preparador de la pregunta es requerido"),
        level: Yup.number().required("La dificultad de la pregunta es requerida"),
        article_url: Yup.string().required("La URL del articulo de la pregunta es requerida"),
        answer: Yup.number().required("La respuesta de la pregunta es requerida").min(1).max(4),
    });
    const { register, handleSubmit, formState, watch, control, getValues, setValue } = useForm<IFormValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            answer: info.question.answer,
            article: info.question.article,
            article_url: getLastBoeVersion(info.question.article_url),
            autonomic: info.question.autonomic,
            standard: info.question.standard,
            subject_block: info.question.subject_block,
            statement: info.question.statement,
            level: info.question.level,
            option_1: info.question.option_1,
            option_2: info.question.option_2,
            option_3: info.question.option_3,
            option_4: info.question.option_4,
            title: info.question.title,
            local: info.question.local,
            governmental: info.question.governmental,
            preparer: info.question.preparer,
        },
    });
    const { showErrorToast, showSuccessToast } = ToastService();


    const auth = useAuth();
    const [simulations, setSimulations] = useState<any[]>([]);
    const [editQuestionError, setEditQuestionError] = useState<string[]>([]);
    const [showErrors, setShowErrors] = useState<boolean>(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [disbaledButton, setDisabledButton] = useState(true)
    const { getQuestionsForCreateSimulations, updateQuestion } = QuestionService();
    const { errors } = formState;
    const [editForm, setEditForm] = useState({
        statement: true,
        option_1: true,
        option_2: true,
        option_3: true,
        option_4: true,
        answer: true,
        level: true,
        article: true,
        preparer: true,
        article_url: true,
    });

    useEffect(() => {
        async function getSimulations() {
            const simulations: any[] = await getQuestionsForCreateSimulations();
            setSimulations(simulations);
        }
        getSimulations();
    }, []);




    const close = (e: any) => {
        e.preventDefault();
        setOpenModalReportInfo(false);
    };

    const onEditQuestion = () => {
        setIsViewEditQuestion(true);
        setQuestionToEdit(info.question);
        setOpenModalReportInfo(false);
    };



    const onSubmit = async (data: any) => {
        try {
            if (info.question._id) {
                setIsUpdate(true);
                await updateQuestion(info.question._id, data);
                setIsUpdate(false);
                showSuccessToast(`La pregunta se ha modificado con éxito`);
                window.location.reload();
            }
        } catch (error) {
            setIsUpdate(false);
            showErrorToast(`La pregunta no se ha modificado con éxito, revisa los datos`);
            const castedError = errorService.castError(error);
            setEditQuestionError([JSON.stringify(castedError.message)]);
            setShowErrors(true);
        }
    };

    useWatch({ control, name: "subject_block", defaultValue: info.question.subject_block });
    useWatch({ control, name: "standard", defaultValue: info.question.standard });
    useWatch({ control, name: "title", defaultValue: info.question.title });
    useWatch({ control, name: "option_1", defaultValue: info.question.option_1 });
    useWatch({ control, name: "option_2", defaultValue: info.question.option_2 });
    useWatch({ control, name: "option_3", defaultValue: info.question.option_3 });
    useWatch({ control, name: "option_4", defaultValue: info.question.option_4 });
    useWatch({ control, name: "answer", defaultValue: info.question.answer });
    useWatch({ control, name: "article_url", defaultValue: getLastBoeVersion(info.question.article_url) });
    useWatch({ control, name: "autonomic", defaultValue: info.question.autonomic });
    useWatch({ control, name: "governmental", defaultValue: info.question.governmental });
    useWatch({ control, name: "local", defaultValue: info.question.local });
    useWatch({ control, name: "level", defaultValue: info.question.level });
    useWatch({ control, name: "article", defaultValue: info.question.article });
    useWatch({ control, name: "preparer", defaultValue: info.question.preparer });
    useWatch({ control, name: "statement", defaultValue: info.question.statement });

    const standard = getValues().standard;
    const title = getValues().title;
    const subject_block = getValues().subject_block;
    const statement = getValues().statement;
    const option_1 = getValues().option_1;
    const option_2 = getValues().option_2;
    const option_3 = getValues().option_3;
    const option_4 = getValues().option_4;
    const article_url = getValues().article_url;
    const governmental = getValues().governmental;
    const autonomic = getValues().autonomic;
    const local = getValues().local;
    const answer = getValues().answer;
    const level = getValues().level;
    const article = getValues().article;
    const preparer = getValues().preparer;





    useEffect(() => {
        const checkConditions = () => {
            const { question } = info;
            const conditionsMet =
                statement === question.statement &&
                option_1 === question.option_1 &&
                option_2 === question.option_2 &&
                option_3 === question.option_3 &&
                option_4 === question.option_4 &&
                answer === question.answer &&
                level === question.level &&
                article === question.article &&
                preparer === question.preparer &&
                autonomic === question.autonomic &&
                local === question.local &&
                article_url === question.article_url &&
                subject_block === question.subject_block &&
                governmental === question.governmental &&
                title === question.title;

            setDisabledButton(conditionsMet);
        };

        checkConditions();
    }, [formState, info]);






    return (
        <div>
            <Dialog
                className=" flex justify-center"
                fullScreen
                open={openModalReportInfo}
                onClose={close}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={close} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {type === "report" ? "Reporte" : "Editar pregunta"}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex items-center justify-center  gap-5 p-10 border border-gray-300 rounded-lg m-6 w-full  sm:min-w-[500px] sm:max-w-[800px]"
                >
                    <List>
                        {type === "report" &&
                            <>
                                <ListItem>
                                    <ListItemText primary="Email" secondary={`${info.user.email}`} />
                                </ListItem>
                                <Divider />
                                <ListItem>
                                    <ListItemText primary="Nombre del usuario" secondary={`${info.user.name}`} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Apellidos del usuario" secondary={`${info.user.last_name}`} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Descripción del reporte" secondary={`${info.description}`} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary="Fecha de creación"
                                        secondary={`${dateService.format(new Date(info.created_at), "DD-MM-YYYY")}`}
                                    />
                                </ListItem>
                            </>
                        }
                        <ListItem>
                            <ListItemText
                                primary={
                                    <div className=" flex justify-between items-center mb-2 ">
                                        <span> Enunciado de la pregunta </span>
                                        <div
                                            onClick={() => setEditForm({ ...editForm, statement: !editForm.statement })}
                                            className=" flex items-center justify-center gap-1 px-2 py-1 ring-1 ring-gray-300 text-text-300 rounded-md hover:enabled:bg-gray-100 hover:enabled:ring-gray-400  transition cursor-pointer"
                                        >
                                            {editForm.statement ? (
                                                <EditIcon style={{ fontSize: "1.2rem" }} />
                                            ) : (
                                                <>
                                                    <ClearIcon
                                                        onClick={() => setValue("statement", info.question.statement)}
                                                        style={{ fontSize: "1.2rem", color: "red", }}
                                                    />
                                                    |
                                                    <DoneIcon
                                                        style={{ fontSize: "1.2rem", color: "green ", }}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                }
                                secondary={
                                    <div>
                                        {editForm.statement ? (
                                            <span>{statement}</span>
                                        ) : (
                                            <Controller
                                                name={`statement`}
                                                control={control}
                                                render={({ field }) => (
                                                    <textarea
                                                        id="statement"
                                                        rows={4}
                                                        className="mt-2 block p-2.5 w-full text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 resize-none"
                                                        placeholder="Escribir el enunciado de la pregunta..."
                                                        onChange={(val) => {
                                                            field.onChange(val.target.value);
                                                        }}
                                                        defaultValue={statement}
                                                    />
                                                )}
                                            />
                                        )}
                                        {errors.statement?.message && (
                                            <span className="text-red-600">{errors.statement.message}</span>
                                        )}
                                    </div>
                                }
                            />
                        </ListItem>
                        <ListItem className={`${info.question.answer === 1 ? "bg-green-100" : ""}`}>
                            <ListItemText
                                primary={
                                    <div className=" flex justify-between items-center mb-2 ">
                                        <span>{"a)"} </span>
                                        <div
                                            onClick={() => setEditForm({ ...editForm, option_1: !editForm.option_1 })}
                                            className=" flex items-center justify-center gap-1 px-2 py-1 ring-1 ring-gray-300 text-text-300 rounded-md hover:enabled:bg-gray-100 hover:enabled:ring-gray-400  transitio cursor-pointer"
                                        >
                                            {editForm.option_1 ? (
                                                <EditIcon style={{ fontSize: "1.2rem" }} />
                                            ) : (
                                                <>
                                                    <ClearIcon
                                                        onClick={() => setValue("option_1", info.question.option_1)}
                                                        style={{ fontSize: "1.2rem", color: "red" }}
                                                    />
                                                    |
                                                    <DoneIcon style={{ fontSize: "1.2rem", color: "green" }} />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                }
                                secondary={
                                    <div>
                                        {editForm.option_1 ? (
                                            <span>{option_1}</span>
                                        ) : (
                                            <Controller
                                                name={`option_1`}
                                                control={control}
                                                render={({ field }) => (
                                                    <textarea
                                                        id="option_1"
                                                        rows={4}
                                                        className="mt-2 h-auto block p-2.5 w-full text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 resize-none"
                                                        placeholder="Opción 1 de la pregunta"
                                                        onChange={(val) => {
                                                            field.onChange(val.target.value);
                                                        }}
                                                        defaultValue={option_1}
                                                    />
                                                )}
                                            />
                                        )}
                                        {errors.option_1?.message && (
                                            <span className="text-red-600">{errors.option_1.message}</span>
                                        )}
                                    </div>
                                }
                            />
                        </ListItem>
                        <ListItem className={`${info.question.answer === 2 ? "bg-green-100" : ""}`}>
                            <ListItemText
                                primary={
                                    <div className=" flex justify-between items-center mb-2 ">
                                        <span>{"b)"} </span>
                                        <div
                                            onClick={() => setEditForm({ ...editForm, option_2: !editForm.option_2 })}
                                            className=" flex items-center justify-center gap-1 px-2 py-1 ring-1 ring-gray-300 text-text-300 rounded-md hover:enabled:bg-gray-100 hover:enabled:ring-gray-400  transition cursor-pointer"
                                        >
                                            {editForm.option_2 ? (
                                                <EditIcon style={{ fontSize: "1.2rem" }} />
                                            ) : (
                                                <>
                                                    <ClearIcon
                                                        onClick={() => setValue("option_2", info.question.option_2)}
                                                        style={{ fontSize: "1.2rem", color: "red" }}
                                                    />
                                                    |
                                                    <DoneIcon style={{ fontSize: "1.2rem", color: "green" }} />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                }
                                secondary={
                                    <div>
                                        {editForm.option_2 ? (
                                            <span>{option_2}</span>
                                        ) : (
                                            <Controller
                                                name={`option_2`}
                                                control={control}
                                                render={({ field }) => (
                                                    <textarea
                                                        id="option_2"
                                                        rows={4}
                                                        className="mt-2 block p-2.5 w-full text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 resize-none"
                                                        placeholder="Opción 2 de la pregunta"
                                                        onChange={(val) => {
                                                            field.onChange(val.target.value);
                                                        }}
                                                        defaultValue={option_2}
                                                    />
                                                )}
                                            />
                                        )}
                                        {errors.option_2?.message && (
                                            <span className="text-red-600">{errors.option_2.message}</span>
                                        )}
                                    </div>
                                }
                            />
                        </ListItem>
                        <ListItem className={`${info.question.answer === 3 ? "bg-green-100" : ""}`}>
                            <ListItemText
                                primary={
                                    <div className=" flex justify-between items-center mb-2 cursor-pointer">
                                        <span>{"c)"} </span>
                                        <div
                                            onClick={() => setEditForm({ ...editForm, option_3: !editForm.option_3 })}
                                            className=" flex items-center justify-center gap-1 px-2 py-1 ring-1 ring-gray-300 text-text-300 rounded-md hover:enabled:bg-gray-100 hover:enabled:ring-gray-400  transition cursor-pointer"
                                        >
                                            {editForm.option_3 ? (
                                                <EditIcon style={{ fontSize: "1.2rem" }} />
                                            ) : (
                                                <>
                                                    <ClearIcon
                                                        onClick={() => setValue("option_3", info.question.option_3)}
                                                        style={{ fontSize: "1.2rem", color: "red" }}
                                                    />
                                                    |
                                                    <DoneIcon style={{ fontSize: "1.2rem", color: "green" }} />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                }
                                secondary={
                                    <div>
                                        {editForm.option_3 ? (
                                            <span>{option_3}</span>
                                        ) : (
                                            <Controller
                                                name={`option_3`}
                                                control={control}
                                                render={({ field }) => (
                                                    <textarea
                                                        id="option_3"
                                                        rows={4}
                                                        className="mt-2 block p-2.5 w-full text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 resize-none"
                                                        placeholder="Opción 3 de la pregunta"
                                                        onChange={(val) => {
                                                            field.onChange(val.target.value);
                                                        }}
                                                        defaultValue={option_3}
                                                    />
                                                )}
                                            />
                                        )}
                                        {errors.option_3?.message && (
                                            <span className="text-red-600">{errors.option_3.message}</span>
                                        )}
                                    </div>
                                }
                            />
                        </ListItem>
                        <ListItem className={`${info.question.answer === 4 ? "bg-green-100" : ""}`}>
                            <ListItemText
                                primary={
                                    <div className=" flex justify-between items-center mb-2 ">
                                        <span>{"d)"} </span>
                                        <div
                                            onClick={() => setEditForm({ ...editForm, option_4: !editForm.option_4 })}
                                            className=" flex items-center justify-center gap-1 px-2 py-1 ring-1 ring-gray-300 text-text-300 rounded-md hover:enabled:bg-gray-100 hover:enabled:ring-gray-400  transition cursor-pointer"
                                        >
                                            {editForm.option_4 ? (
                                                <EditIcon style={{ fontSize: "1.2rem" }} />
                                            ) : (
                                                <>
                                                    <ClearIcon
                                                        onClick={() => setValue("option_4", info.question.option_4)}
                                                        style={{ fontSize: "1.2rem", color: "red" }}
                                                    />
                                                    |
                                                    <DoneIcon style={{ fontSize: "1.2rem", color: "green" }} />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                }
                                secondary={
                                    editForm.option_4 ? (
                                        `${option_4}`
                                    ) : (
                                        <Controller
                                            name={`option_4`}
                                            control={control}
                                            render={({ field }) => (
                                                <textarea
                                                    id="option_4"
                                                    rows={4}
                                                    className="mt-2 block p-2.5 w-full text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 resize-none"
                                                    placeholder="Opción 4 de la pregunta"
                                                    onChange={(val) => {
                                                        field.onChange(val.target.value);
                                                    }}
                                                    defaultValue={option_4}
                                                />
                                            )}
                                        />
                                    )
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={
                                    <div className=" flex justify-between items-center mb-2">
                                        <span>Respuesta</span>
                                        <div
                                            onClick={() => setEditForm({ ...editForm, answer: !editForm.answer })}
                                            className=" flex items-center justify-center gap-1 px-2 py-1 ring-1 ring-gray-300 text-text-300 rounded-md hover:enabled:bg-gray-100 hover:enabled:ring-gray-400  transition cursor-pointer "
                                        >
                                            {editForm.answer ? (
                                                <EditIcon style={{ fontSize: "1.2rem" }} />
                                            ) : (
                                                <>
                                                    <ClearIcon
                                                        onClick={() => setValue("answer", info.question.answer)}
                                                        style={{ fontSize: "1.2rem", color: "red" }}
                                                    />
                                                    |
                                                    <DoneIcon style={{ fontSize: "1.2rem", color: "green" }} />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                }
                                secondary={
                                    <div>
                                        {editForm.answer ? (
                                            <span>{answer}</span>
                                        ) : (
                                            <TextInput
                                                placeholder="Respuesta"
                                                label="answer"
                                                type="number"
                                                register={register}
                                                minLength={{
                                                    value: 1,
                                                    message:
                                                        "La respuesta de la pregunta de la pregunta no puede estar vacia",
                                                }}
                                                error={errors.answer}
                                                className=" w-1/5"
                                            />
                                        )}
                                        {errors.answer?.message && (
                                            <span className="text-red-600">{errors.answer.message}</span>
                                        )}
                                    </div>
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={
                                    <div className=" flex justify-between items-center mb-2 ">
                                        <span>Nivel</span>
                                        <div
                                            onClick={() => setEditForm({ ...editForm, level: !editForm.level })}
                                            className=" flex items-center justify-center gap-1 px-2 py-1 ring-1 ring-gray-300 text-text-300 rounded-md hover:enabled:bg-gray-100 hover:enabled:ring-gray-400  transition cursor-pointer "
                                        >
                                            {editForm.level ? (
                                                <EditIcon style={{ fontSize: "1.2rem" }} />
                                            ) : (
                                                <>
                                                    <ClearIcon
                                                        onClick={() => {
                                                            if (info.question.level) {
                                                                setValue("level", info.question.level);
                                                            }
                                                        }}
                                                        style={{ fontSize: "1.2rem", color: "red" }}
                                                    />
                                                    |
                                                    <DoneIcon style={{ fontSize: "1.2rem", color: "gren" }} />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                }
                                secondary={
                                    <div>
                                        {editForm.level ? (
                                            <span>{level}</span>
                                        ) : (
                                            <TextInput
                                                placeholder="Nivel"
                                                label="level"
                                                type="number"
                                                register={register}
                                                minLength={{
                                                    value: 1,
                                                    message:
                                                        "El nivel de la pregunta de la pregunta no puede estar vacio",
                                                }}
                                                error={errors.level}
                                                className=" w-1/5"
                                            />
                                        )}
                                        {errors.level?.message && (
                                            <span className="text-red-600">{errors.level.message}</span>
                                        )}
                                    </div>
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={
                                    <div className=" flex justify-between items-center mb-2 ">
                                        <span>Numero de articulo</span>
                                        <div
                                            onClick={() => setEditForm({ ...editForm, article: !editForm.article })}
                                            className=" flex items-center justify-center gap-1 px-2 py-1 ring-1 ring-gray-300 text-text-300 rounded-md hover:enabled:bg-gray-100 hover:enabled:ring-gray-400  transition cursor-pointer "
                                        >
                                            {editForm.article ? (
                                                <EditIcon style={{ fontSize: "1.2rem" }} />
                                            ) : (
                                                <>
                                                    <ClearIcon style={{ fontSize: "1.2rem", color: "red" }} />
                                                    |
                                                    <DoneIcon style={{ fontSize: "1.2rem", color: "green" }} />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                }
                                secondary={
                                    <div>
                                        {editForm.article ? (
                                            <span>{article}</span>
                                        ) : (
                                            <TextInput
                                                placeholder="Número de articulo"
                                                label="article"
                                                type="number"
                                                register={register}
                                                minLength={{
                                                    value: 1,
                                                    message: "El articulo de la pregunta no puede estar vacio",
                                                }}
                                                error={errors.article}
                                                className=" w-1/5"
                                            />
                                        )}
                                        {errors.article?.message && (
                                            <span className="text-red-600">{errors.article.message}</span>
                                        )}
                                    </div>
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={
                                    <div className=" flex justify-between items-center mb-2 ">
                                        <span>Preparador</span>
                                        <div
                                            onClick={() => setEditForm({ ...editForm, preparer: !editForm.preparer })}
                                            className=" flex items-center justify-center gap-1 px-2 py-1 ring-1 ring-gray-300 text-text-300 rounded-md hover:enabled:bg-gray-100 hover:enabled:ring-gray-400  transition cursor-pointer"
                                        >
                                            {editForm.preparer ? (
                                                <EditIcon style={{ fontSize: "1.2rem" }} />
                                            ) : (
                                                <>
                                                    <ClearIcon
                                                        onClick={() => {
                                                            if (info.question.preparer) {
                                                                setValue("preparer", info.question.preparer);
                                                            }
                                                        }}
                                                        style={{ fontSize: "1.2rem", color: "red" }}
                                                    />
                                                    |
                                                    <DoneIcon style={{ fontSize: "1.2rem", color: "green" }} />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                }
                                secondary={
                                    <div>
                                        {editForm.preparer ? (
                                            <span>{preparer}</span>
                                        ) : (
                                            <TextInput
                                                placeholder="Preparador"
                                                label="preparer"
                                                type="text"
                                                register={register}
                                                minLength={{
                                                    value: 1,
                                                    message:
                                                        "El preparador de la pregunta de la pregunta no puede estar vacio",
                                                }}
                                                error={errors.preparer}
                                                className="w-1/5"
                                            />
                                        )}

                                        {errors.preparer?.message && (
                                            <span className="text-red-600">{errors.preparer.message}</span>
                                        )}
                                    </div>
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                className=" flex gap-5 justify-between"
                                primary="Autonomica"
                                secondary={
                                    <Controller
                                        name={`autonomic`}
                                        control={control}
                                        render={({ field }) => (
                                            <div id="autonomic" className="text-center">
                                                <label className="flex items-center cursor-pointer relative">
                                                    <input
                                                        type="checkbox"
                                                        value=""
                                                        className="sr-only peer"
                                                        checked={autonomic}
                                                        onChange={(e: any) => {
                                                            field.onChange(e.target.checked);
                                                        }}
                                                    />
                                                    <div className="toggle-bg bg-gray-200 border-2 border-gray-200 h-6 w-11 rounded-full  peer-checked:bg-primary-200 peer-checked:border-0"></div>
                                                </label>
                                            </div>
                                        )}
                                    />
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                className=" flex gap-5 justify-between"
                                primary="Local"
                                secondary={
                                    <Controller
                                        name={`local`}
                                        control={control}
                                        render={({ field }) => (
                                            <div id="local" className="text-center">
                                                <label className="flex items-center cursor-pointer relative">
                                                    <input
                                                        type="checkbox"
                                                        value=""
                                                        className="sr-only peer"
                                                        checked={local}
                                                        onChange={(e: any) => {
                                                            field.onChange(e.target.checked);
                                                        }}
                                                    />
                                                    <div className="toggle-bg bg-gray-200 border-2 border-gray-200 h-6 w-11 rounded-full  peer-checked:bg-primary-200 peer-checked:border-0"></div>
                                                </label>
                                            </div>
                                        )}
                                    />
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                className=" flex gap-5 justify-between"
                                primary="Estatal"
                                secondary={
                                    <Controller
                                        name={`governmental`}
                                        control={control}
                                        render={({ field }) => (
                                            <div id="governmental" className="">
                                                <label className="flex items-center cursor-pointer relative">
                                                    <input
                                                        type="checkbox"
                                                        value=""
                                                        className="sr-only peer"
                                                        checked={governmental}
                                                        onChange={(e: any) => {
                                                            field.onChange(e.target.checked);
                                                        }}
                                                    />
                                                    <div className="toggle-bg bg-gray-200 border-2 border-gray-200 h-6 w-11 rounded-full  peer-checked:bg-primary-200 peer-checked:border-0"></div>
                                                </label>
                                            </div>
                                        )}
                                    />
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={
                                    <div className=" flex justify-between items-center mb-2 ">
                                        <span>URL articulo</span>
                                        <div
                                            onClick={() =>
                                                setEditForm({ ...editForm, article_url: !editForm.article_url })
                                            }
                                            className=" flex items-center justify-center gap-1 px-2 py-1 ring-1 ring-gray-300 text-text-300 rounded-md hover:enabled:bg-gray-100 hover:enabled:ring-gray-400  transition cursor-pointer"
                                        >
                                            {editForm.article_url ? (
                                                <EditIcon style={{ fontSize: "1.2rem" }} />
                                            ) : (
                                                <>
                                                    <ClearIcon
                                                        onClick={() => {
                                                            if (info.question.article_url) {
                                                                setValue("article_url", info.question.article_url);
                                                            }
                                                        }}
                                                        style={{ fontSize: "1.2rem", color: "red" }}
                                                    />
                                                    |
                                                    <DoneIcon style={{ fontSize: "1.2rem", color: "green" }} />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                }
                                secondary={
                                    editForm.article_url ? (
                                        <span>{article_url}l</span>
                                    ) : (
                                        <Controller
                                            name={`article_url`}
                                            control={control}
                                            render={({ field }) => (
                                                <textarea
                                                    id="article_url"
                                                    rows={4}
                                                    className="mt-2 block p-2.5 w-full text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 resize-none"
                                                    placeholder="La URL de la pregunta"
                                                    onChange={(val) => {
                                                        field.onChange(val.target.value);
                                                    }}
                                                    defaultValue={article_url}
                                                />
                                            )}
                                        />
                                    )
                                }
                            />
                        </ListItem>

                        <ListItem>
                            <ListItemText
                                primary="Bloque de materia"
                                secondary={
                                    <Controller
                                        name={`subject_block`}
                                        control={control}
                                        render={({ field }) => (
                                            <div id="selectsubjectblock" className="mt-2">
                                                <Select
                                                    className="mt-2 block p-2.5 w-[40rem] text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 resize-none"
                                                    style={{ backgroundColor: "white" }}
                                                    id="subject_block"
                                                    required={true}
                                                    value={subject_block}
                                                    onChange={(val: any) => {
                                                        field.onChange(val.target.value);
                                                    }}
                                                >
                                                    {simulations.map((res, index: number) => {
                                                        return <option key={res._id}> {res._id} </option>;
                                                    })}
                                                </Select>
                                            </div>
                                        )}
                                        defaultValue={info.question.subject_block}
                                    />
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Normativa"
                                secondary={
                                    <Controller
                                        name={`standard`}
                                        control={control}
                                        render={({ field }) => (
                                            <div id="selectstandard" className="mt-2">
                                                <Select
                                                    className="mt-2 block p-2.5 w-[40rem] text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 resize-none"
                                                    style={{ backgroundColor: "white" }}
                                                    id="standard"
                                                    required={true}
                                                    value={standard}
                                                    onChange={(val: any) => {
                                                        field.onChange(val.target.value);
                                                    }}
                                                >
                                                    {simulations
                                                        .filter((res) => res._id === subject_block)
                                                        .map((res) => {
                                                            return res.standards.map((res1: any) => {
                                                                return <option key={res1._id}> {res1._id} </option>;
                                                            });
                                                        })}
                                                </Select>
                                            </div>
                                        )}
                                        defaultValue={info.question.standard}
                                    />
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Titulo"
                                secondary={
                                    <Controller
                                        name={`title`}
                                        control={control}
                                        render={({ field }) => (
                                            <div id="selectitle" className="mt-2">
                                                <Select
                                                    className="mt-2 block p-2.5 w-[40rem] text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 resize-none"
                                                    style={{ backgroundColor: "white" }}
                                                    id="title"
                                                    required={true}
                                                    value={title}
                                                    onChange={(val: any) => {
                                                        field.onChange(val.target.value);
                                                    }}
                                                >
                                                    {simulations
                                                        .filter((res: any) => res._id === subject_block)
                                                        .map((res: any) => {
                                                            const standardItem = res.standards.filter(
                                                                (res: any) => res._id === standard
                                                            );
                                                            return standardItem.map((item: any) => {
                                                                return item.titles.map((title: any) => {
                                                                    return (
                                                                        <option key={title._id}> {title._id} </option>
                                                                    );
                                                                });
                                                            });
                                                        })}
                                                </Select>
                                            </div>
                                        )}
                                        defaultValue={info.question.title}
                                    />
                                }
                            />
                        </ListItem>

                        <div className="flex justify-center mt-5">
                            <MainButton isSubmitButton={true} disabled={disbaledButton}>
                                {isUpdate ? "Actualizando Pregunta..." : "Actualizar Pregunta"}
                            </MainButton>
                        </div>
                    </List>
                </form>
            </Dialog>
        </div>
    );
}
