import { ICON_BUTTON } from "../../../../config/constants";
import MainButton from "../../../buttons/MainButton";
import { VscClose } from "react-icons/vsc";
import { Select } from "flowbite-react";
import TextInput from "../../../inputs/TextInput";
import Skeleton from "react-loading-skeleton";
import { useState } from "react";


export interface SectionCardProps {
  onRemove: any,
  onUpdate: any,
  id: string,
  section: any,
  options: any
  customTest?: boolean
}

export default function SectionCard(props: SectionCardProps): JSX.Element {

  const { id, onRemove, section, onUpdate, options } = props;

  const [isGreaterThan40, setIsGreaterThan40] = useState<boolean>(false);

  const removeItem = () => {
    onRemove(props.id);
  };

  return (
    <>
      {options.length > 0 ?
        <div className='flex flex-col gap-5 border border-gray-300 p-8 rounded-lg'>

          <div className="flex justify-between gap-5 items-center text-text-200">
            <div className="font-medium text-xl">Sección {id + 1}</div>
            <MainButton
              type={ICON_BUTTON}
              onClick={removeItem}>
              <VscClose size={24} />
            </MainButton>
          </div>

          <div>
            <label>Bloque de materia</label>
            <Select
              className="mt-2"
              required={true}
              value={section.subject_block}
              onChange={(e: any) => {

                onUpdate({ ...section, subject_block: e.target.value })
              }}
            >
              <option key={'allsubjectblocks'} value={''} className='text-center'>  </option>
              {
                options.map((res: any, index: number) => {
                  return <option key={res._id} value={res._id} className='text-center'> {res._id} - {res.total} </option>;
                })
              }
            </Select>
          </div>

          <div>
            <label>Norma</label>
            <Select
              className="mt-2"
              required={!props.customTest && true}
              value={section.standard}
              onChange={(e: any) => {
                onUpdate({ ...section, standard: e.target.value })
              }}
              disabled={!section.subject_block}
            >
              <option key={'allregulationss'} value={''} className='text-center'> {props.customTest && 'Todas las normas'} </option>
              {
                options.filter((res: any) => res._id === section.subject_block).map((res: any) => {
                  return res.standards.map((res1: any) => {
                    return <option key={res1._id} value={res1._id} className='text-center'> {res1._id} - {res1.total} </option>;
                  });
                })
              }
            </Select>
          </div>

          <div>
            <label>Titulo</label>
            <Select
              className="mt-2"
              value={section.title}
              onChange={(e: any) => {
                onUpdate({ ...section, title: e.target.value })
              }}
              disabled={!section.subject_block || !section.standard}
            >
              <option key={'alltitles'} value={''} className='text-center'>{props.customTest && 'Todos los títulos'}</option>
              {
                options.filter((res: any) => res._id === section.subject_block).map((res: any) => {
                  const standardItem = res.standards.filter((res: any) => res._id === section.standard);
                  return standardItem.map((item: any) => {
                    return item.titles.map((title: any) => {
                      return <option key={title._id} value={title._id} className='text-center'> {title._id} - {title.total} </option>;
                    });
                  });

                })
              }
            </Select>
          </div>

          <div>
            <label>Número de preguntas</label>
            <TextInput
              value={section.number}
              placeholder={props.customTest ? "40 preguntas como maximo" : "Número de preguntas"}
              type="number"
              onChange={(e: any) => {
                const val = e.target.value;
                if (props.customTest) {
                  if (val > 40) {
                    setIsGreaterThan40(true);
                  } else {
                    setIsGreaterThan40(false);
                    onUpdate({ ...section, number: val })

                  }
                } else {
                  onUpdate({ ...section, number: val })
                }
              }}
            />
            {props.customTest && <div className='mt-2 text-sm text-text-400'>
              Puede que el número de preguntas seleccionadas no esté disponible.</div>}
            {isGreaterThan40 && <div className='mt-2 text-sm  text-red-500'>
              Solo puede seleccionar hasta 40 preguntas.</div>}
          </div>

          {!props.customTest && <div>
            <label>Artículos</label>
            <TextInput
              required={false}
              value={section.article_ranges}
              placeholder='Articulos como ... 1-6,8-12'
              type="text"
              onChange={(e: any) => {
                onUpdate({ ...section, article_ranges: e.target.value })
              }}
            />
            {<div className='mt-2 text-sm text-text-400'>
              Dejar vacío si se incluyen todos los artículos, en caso contrario introducir en el formato del siguiente ejemplo: 1-6,8,12-14
            </div>}
          </div>}

          {!props.customTest && <div>
            <label>Niveles</label>
            <TextInput
              required={false}
              value={section.level_range}
              placeholder='Niveles de ... 1-2,3-4'
              type="text"
              onChange={(e: any) => {
                onUpdate({ ...section, level_range: e.target.value })
              }}
            />
            <div className='mt-2 text-sm text-text-400'>
              Dejar vacío si se incluyen todos los niveles, en caso contrario introducir en el formato del siguiente ejemplo: 1-3
            </div>
            <div />
          </div>
          }
        </div>
        :
        <Skeleton height={'10vh'} />
      }
    </>
  );
}
