import computerHome from "../../../../../assets/images/principal.jpg";

export default function SecondSection() {
    return (
        <div className="flex flex-col sm:flex-row items-center justify-evenly">
            <div className="sm:px-6">
                <h1 className="text-4xl text-text-100 font-semibold" style={{ fontFamily: "serif" }}>
                    Test de Oposiciones
                </h1>
                <h1 className="text-4xl text-primary-200 font-semibold" style={{ fontFamily: "serif" }}>
                    Simulacros de examen
                </h1>
                <h3 className="text-2xl text-text-300 font-light">Generación Aleatoria</h3>
                <h3 className="mt-4 text-text-500 font-light">
                    Un simulacro distinto cada vez. Más de 16.000 preguntas.
                </h3>
                <div className="mt-2 text-text-500 font-semibold">
                    Adaptada a convocatorias de la Comunidad Valenciana:
                    <br></br>Generalitat y Entidades Locales.
                </div>
            </div>
            <img src={computerHome} alt={"App Auténtica Test"} className="max-w-full sm:max-w-1/2 mx-auto sm:mx-0" />
        </div>
    );
}
