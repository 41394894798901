import { IQuestion } from "../../interfaces";
import { useState } from "react";
import ModalQuestionReport from "./reportModal";
import { useAuth } from "../../contexts/authContext";
import { ROUND_BUTTON, ROUND_SECONDARY_BUTTON, TRANSPARENT_BUTTON } from "../../config/constants";
import MainButton from "../buttons/MainButton";
import { BiLinkExternal } from "react-icons/bi";
import { Button } from "flowbite-react";
import { BsQuestionCircleFill } from "react-icons/bs";
import DoubtfulButtonComponent from "./doubtfulButton";
import QuestionAnswer from "./QuestionAnswer";
import { getLastBoeVersion } from "../../services/boe.service";


export interface QuestionCardProps {
  question: IQuestion,
  isCorrected: boolean,
  index: number,
  onCorrectQuestion: any
  onAnswerQuestion: any
  onMarkedAsDoubtful: any
  type?: string
}

export default function QuestionCard(props: QuestionCardProps): JSX.Element {
  const {
    question,
    isCorrected,
    index,
    onCorrectQuestion,
    onMarkedAsDoubtful,
    onAnswerQuestion,
    type
  } = props;
  const auth = useAuth();

  const [openModalReportInfo, setOpenModalReportInfo] = useState<boolean>(false);
  const [markedAsDoubtful, setMarkedAsDoubtful] = useState<boolean>(false);

  const renderAnswer = (i: number) => {
    return (
      <QuestionAnswer question={question} isCorrected={isCorrected} index={i} onAnswerQuestion={onAnswerQuestion}></QuestionAnswer>
    )
  }


  const renderAnswers = () => {
    return (
      <>
        {question.option_1 && renderAnswer(1)}
        {question.option_2 && renderAnswer(2)}
        {question.option_3 && renderAnswer(3)}
        {question.option_4 && renderAnswer(4)}
      </>
    )
  }
  const renderDoubtfulButtonIfNeeded = () => {
    if (shouldRenderDoubtfulButton()) {
      return (
        <DoubtfulButtonComponent active={markedAsDoubtful || question.doubtful} onClick={toggleMarkedAsDoubtful}></DoubtfulButtonComponent>
      );
    }
  }

  const shouldRenderDoubtfulButton = () => {
    return !isCorrected || markedAsDoubtful
  }

  const toggleMarkedAsDoubtful = () => {
    onMarkedAsDoubtful(!markedAsDoubtful)
    setMarkedAsDoubtful(!markedAsDoubtful)
  }


  return (
    <div className={`p-8 rounded-xl shadow-lg ${(!isCorrected || !question.user_answer) ? 'bg-white' : (question.user_answer === question.answer) ? 'bg-green-100' : 'bg-red-50'}`}>
      {openModalReportInfo && question._id &&
        <ModalQuestionReport
          setOpenModalReportInfo={setOpenModalReportInfo}
          question={question._id}
          openModal={openModalReportInfo} />
      }

      <div className="flex gap-7">
        <div className="select-none hidden sm:block font-bold text-4xl text-gray-300 mt-[-7px]">
          {index + 1}
        </div>

        <div className="flex-1 sm:flex-init">
          <div className="flex flex-row gap-5 mb-5 text-text-200">
            <span className="flex-1 font-medium text-lg">
              <span className="select-none sm:hidden inline-flex w-[25px] h-[25px] bg-gray-300 text-sm font-bold text-white flex items-center justify-center rounded-full mr-2">
                {index + 1}
              </span>
              {question.statement}
            </span>
            {isCorrected && auth.token &&
              <MainButton
                className="hidden sm:flex text-xs max-h-[30px]"
                type={ROUND_SECONDARY_BUTTON}
                onClick={() => setOpenModalReportInfo(true)}
              >
                Reportar pregunta
              </MainButton>
            }
            {type !== "testWithDoubtful" && renderDoubtfulButtonIfNeeded()}
            {!isCorrected && auth?.user?.studyMode &&
              <MainButton
                className="hidden sm:flex text-xs"
                disabled={!question.user_answer}
                type={ROUND_BUTTON}
                onClick={() => onCorrectQuestion()}
              >
                Corregir pregunta
              </MainButton>
            }
          </div>

          <div className="flex flex-col gap-4">
            {renderAnswers()}
          </div>
        </div>
      </div>

      {isCorrected &&
        <MainButton
          className="w-full mt-5 whitespace-normal max-h-[500px]"
          type={TRANSPARENT_BUTTON}
          onClick={() => window.open(getLastBoeVersion(question.article_url), '_blank', 'noreferrer')}
        >
          <span className="whitespace-normal">Artículo {question.article} de {question.title} <br className="sm:hidden" />
            de {question.standard}</span>
          <BiLinkExternal />
        </MainButton>
      }

      {isCorrected && auth.token &&
        <MainButton
          className="flex sm:hidden text-xs max-h-[35px] w-full mt-6"
          type={ROUND_SECONDARY_BUTTON}
          onClick={() => setOpenModalReportInfo(true)}
        >
          Reportar pregunta
        </MainButton>
      }
      {!isCorrected && auth?.user?.studyMode &&
        <MainButton
          className="flex sm:hidden text-xs max-h-[35px] w-full mt-6"
          disabled={!question.user_answer}
          type={ROUND_BUTTON}
          onClick={() => onCorrectQuestion()}
        >
          Corregir pregunta
        </MainButton>
      }
    </div>
  );
}
