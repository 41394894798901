import { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/authContext";
import UserService from "../../../services/httpServices/user.service";
import { useNavigate } from "react-router-dom";
import MainButton from "../../../components/buttons/MainButton";
import { FLOATING_BUTTON, ROUND_BUTTON } from "../../../config/constants";
import { GoChevronLeft } from "react-icons/go";
import { useSubscription } from "../../../contexts/subscriptionContext";
import Skeleton from "react-loading-skeleton";


export default function SelectCustomGVAorLOCAL(): JSX.Element {

    const auth = useAuth();
    const subscription = useSubscription();
    const navigate = useNavigate();
    const { getInfoUser } = UserService();
    const [isLoading, setIsLoading] = useState(true);

    const getUserData = async () => {
        getInfoUser().then((user) => {
            auth.updateUser(user);
        }).catch((error) => {
            //TODO: control error
        }).finally(() => {
            setIsLoading(false);
        })
    }
    useEffect(() => {
        (async () => {
            try {
                if (auth.token && !auth.user.hasDetails) await getUserData();
            } catch (error: any) {
                if (error.response && error.response.status === 401) {
                    auth.logout();
                }
            } finally {
                setIsLoading(false);
            }
        })()
    }, []);

    const data = [
        {
            name: 'A1',
            simulationGVA: 'Simulacro A1 GVA',
            simulationLocal: 'Simulacro A1 LOCAL',

        },
        {
            name: 'A2',
            simulationGVA: 'Simulacro A2 GVA',
            simulationLocal: 'Simulacro A2 LOCAL',

        },
        {
            name: 'C1',
            simulationGVA: 'Simulacro C1 GVA',
            simulationLocal: 'Simulacro C1 LOCAL',

        },
        {
            name: 'C2',
            simulationGVA: 'Simulacro C2 GVA',
            simulationLocal: 'Simulacro C2 LOCAL',

        },
    ];

    return (
        <div className="relative flex-1 flex flex-col items-center justify-center bg-background-200">
            <MainButton
                className="absolute top-5 left-5"
                type={FLOATING_BUTTON}
                onClick={() => navigate(-1)}>
                <GoChevronLeft size={16} />
                Atrás
            </MainButton>
            <div className="absolute top-6 right-6">
                <label htmlFor="study-mode" className="flex items-center cursor-pointer relative">
                    <input
                        type="checkbox"
                        id="study-mode"
                        className="sr-only peer"
                        checked={auth.user?.studyMode}
                        onChange={(e) => {
                            auth.updateUser({ studyMode: e.target.checked });
                        }}
                    />
                    <div className="toggle-bg bg-gray-200 border-2 border-gray-200 h-6 w-11 rounded-full  peer-checked:bg-primary-200 peer-checked:border-0"></div>
                    <span className="text-text-300 text-sm font-medium text-center ml-2">
                        Modo estudio
                    </span>
                </label>
            </div>
            <h1 className="mt-28 mb-16 sm:mt-0 text-[25px] max-sm:text-[30px] text-center tracking-[10px] text-text-300 font-light max-sm:px-4">NORMATIVA POR NIVELES</h1>
            <div className="w-full flex items-center justify-center px-8">
                {isLoading ? (
                    <div className="w-full flex flex-wrap flex-col justify-center sm:flex-row gap-7" >
                        {[...Array(4)].map((_, index) =>
                            <Skeleton key={index} className="flex-1 w-full xs:w-auto xs:flex-initial xs:min-w-[280px] xs:w-[20vw] flex flex-col items-center justify-center transition bg-primary-200 text-center text-white px-10 pb-10 rounded-3xl shadow-xl hover:scale-11" height={260} borderRadius={12} />
                        )}
                    </div>
                ) :
                    <div className="flex w-full flex-wrap flex-col justify-center sm:flex-row gap-7">
                        {data?.map((item: any, index: number) => {
                            return (
                                <div
                                    className='flex-1 w-full xs:w-auto xs:flex-initial xs:min-w-[280px] xs:w-[20vw] flex flex-col items-center justify-center transition bg-primary-200 text-center text-white px-10 pb-10 rounded-3xl shadow-xl hover:scale-110'
                                    key={index}>
                                    <span className="select-none font-bold text-5xl my-12">{item.name}</span>
                                    <div className="w-full flex flex-col items-center gap-3">
                                        {
                                            <>
                                                <MainButton
                                                    className="w-full"
                                                    type={ROUND_BUTTON}
                                                    onClick={() => navigate('/tests/regulations/law', { state: { simulation: item.simulationGVA } })}
                                                >
                                                    GVA
                                                </MainButton>
                                                <MainButton
                                                    className="w-full"
                                                    type={ROUND_BUTTON}
                                                    onClick={() => navigate('/tests/regulations/law', { state: { simulation: item.simulationLocal } })}
                                                >
                                                    LOCAL
                                                </MainButton>
                                            </>
                                        }
                                    </div>
                                </div>
                            );
                        })
                        }
                    </div>
                }
            </div>
        </div>
    );
};
