import { useEffect, useState } from "react";
import { CssBaseline } from '@mui/material';
import { useCallback } from 'react';
import { FilterValue, IdType } from 'react-table';
import { IQuestion, IReport, ISimulation } from "../../../../interfaces";
import { Page } from "../../../tables/table/page";
import { DefaultTable } from "../../../tables/table/table";
import EditQuestionComponent from "../../questions/editQuestion";
import ModalInfoReport from "../ModalReportInfo";
import ReportService from "../../../../services/httpServices/report.service";


function filterGreaterThan(rows: any, id: Array<IdType<any>>, filterValue: FilterValue) {
  return rows.filter((row: any) => {
    const rowValue = row.values[id[0]];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val: any) => typeof val !== 'number';


const columns = [
  {
    Header: 'Reportes',
    columns: [
      {
        Header: 'Descripción',
        accessor: 'description',
        aggregate: 'count',
        filter: 'fuzzyText',
        Aggregated: ({ cell: { value } }: any) => `${value} Description`,
        disableGroupBy: true
      },
      {
        Header: 'Nombre de usuario',
        accessor: 'user.email',
        aggregate: 'uniqueCount',
        filter: 'fuzzyText',
        Aggregated: ({ cell: { value } }: any) => `${value} User`,
        disableGroupBy: true
      },
    ],
  },

]; 

export default function ListReportQuestions(): JSX.Element {

  const { getReports, deleteReport } = ReportService();
  const [data, setData] = useState<any[]>([]);
  const [openModalReportInfo, setOpenModalReportInfo] = useState(false);
  const [reportClicked, setReportCLicked] = useState<IReport>();
  const [isViewEditQuestion, setIsViewEditQuestion] = useState(false);
  const [questionToEdit, setQuestionToEdit] = useState<IQuestion>();


  useEffect(() => {
    async function getQuestions() {
      const fetchReports: any[] = await getReports();
      setData(fetchReports);
    }
    getQuestions();
  }, []);



  const onDeleteReport = useCallback(
    (instance: any) => async () => {
      await deleteReport(instance.selectedFlatRows.map(((res: any) => res.original._id)));
      window.location.reload();   
    },
    []
  );

  return (
    <>
      {
        isViewEditQuestion && questionToEdit ? 
          <EditQuestionComponent question={questionToEdit} setIsViewEditQuestion={setIsViewEditQuestion}/>
          :
          <div className="w-[70vw]">
            <DefaultTable<any>
              name={'reportsTable'}
              columns={columns}
              data={data}
              onDelete={onDeleteReport}
              onClick={(e: any) => {
                setReportCLicked(e.original);
                setOpenModalReportInfo(true);
              }}
            />
            <div>
              {
                openModalReportInfo && reportClicked ?
                  <ModalInfoReport 
                    setOpenModalReportInfo={setOpenModalReportInfo} 
                    openModalReportInfo={openModalReportInfo} 
                    diameter={100} 
                    info={reportClicked}
                    setIsViewEditQuestion={setIsViewEditQuestion}
                    setQuestionToEdit={setQuestionToEdit}
                  /> : <></>
              }
            </div>
          </div>
      }
    </>
  );
};
            