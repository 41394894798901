import dudasLogo from '../../../../../assets/images/dudas.png';
import { ROUND_WHITE_BUTTON } from '../../../../config/constants';
import MainButton from '../../../buttons/MainButton';


const ContactBanner = () => (
  <div className="flex flex-col pt-10 pb-16 sm:py-20 px-8 md:flex-row items-center justify-evenly bg-background-300 text-text-100">
    <img src={dudasLogo} alt={'App Auténtica Test'}/>
    <div className='flex flex-col items-center text-center'>
      <h3 className="text-3xl sm:text-5xl font-semibold">
        ¿Tienes alguna duda más?
      </h3>
      <h3 className="mt-1 font-light">
        Ponte en contacto con nosotros y estaremos encantados de atenderte.
      </h3>
      <MainButton
        className="mt-10 w-full sm:w-60"
        type={ROUND_WHITE_BUTTON}
        onClick={() => {window.location.href = 'https://www.autenticatest.com/contacta/'}}
      >
        Contacta
      </MainButton>
    </div>
  </div>
);

export { ContactBanner };