import tablet3clicks from '../../../../../assets/images/quecontiene.jpg';
import MainButton from '../../../buttons/MainButton';
import { ROUND_SECONDARY_BUTTON } from '../../../../config/constants';


export default function FourthSection() {
  return (
    <div className='flex flex-col sm:flex-row sm:justify-evenly items-center px-8 xl:px-60 text-text-500 sm:gap-5'>
      <div className='flex flex-col items-center sm:items-start'>
        <ul className='list-disc list-inside text-center xs:text-left'>
          <li>
            <b>Acceso inmediato</b> a todos los tests y simulacros.
          </li>
          <li>
            Las soluciones a las preguntas con el enlace a la <b>norma oficial.</b>
          </li>
          <li>
            Más de <b>16.000 preguntas de tests.</b>
          </li>
          <li>
            <b>Personalizada</b> a cada <b>oposición y nivel {'(A1-A2-C1-C2)'}</b>
          </li>
          <li>
            <b>Siempre actualizado.</b>
          </li>
        </ul>
        <h1 className="mt-8 text-center sm:text-left">
          <b>No te compliques,</b> suscripción mensual con <b className='text-orange-400'>Tarifa Plana: paga siempre lo mismo</b>
        </h1>
        <h1 className="mt-2 text-center sm:text-left">
          <b>Sin permananencia. Sin compromiso.</b>
        </h1>
        <MainButton
          className="mt-7 mx-auto mb-5 sm:mx-0 sm:mb-0"
          type={ROUND_SECONDARY_BUTTON}
          onClick={() => {window.location.href = 'https://www.autenticatest.com/tarifa-plana/'}}>
            Tarifa plana
        </MainButton>
      </div>

      <img src={tablet3clicks} alt={'App Auténtica Test'}/>
    </div>
  );
};
