import { Dispatch, Fragment, SetStateAction } from "react";
import { Button, Modal} from 'flowbite-react'; 
import { HiOutlineCheckCircle } from "react-icons/hi";


export default function SuccessModal({
  setOpenModal,
  info,
  openModal,
  isCancelSuscription,
  accept,

}: {
    info: string,
    setOpenModal: Dispatch<SetStateAction<boolean>>
    openModal: boolean
    isCancelSuscription?: boolean,
    accept: any
  }): JSX.Element {

  const close = () => {
    setOpenModal(false);
  };


  return (
    <Fragment>
      <Modal
        show={openModal}
        size="md"
        popup={true}
        onClose={close}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineCheckCircle className="mx-auto mb-4 h-14 w-14 text-green-400" />
            <h3 className="mb-5 text-lg font-normal text-gray-700">
              {info}
            </h3>
            <div className="flex justify-center gap-4">
              <Button
                color="gray"
                type="submit"
                onClick={accept}
              >
                Aceptar
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}