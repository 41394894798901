import { useState } from "react";
import errorService from "../../../../services/error.service";
import useDragAndDrop from "./dragAndDrop";
import './index.scss';
import QuestionService from "../../../../services/httpServices/question.service";
import ToastService from "../../../../services/toastService";
import ConfirmationModal from "../../../modals/ConfirmationModal";
import MainButton from "../../../buttons/MainButton";
import UploadQuestionsButton from "../../../buttons/UploadQuestionsButton";


export default function UploadQuestionComponent(): JSX.Element {

  const { showErrorToast, showSuccessToast } = ToastService();
  const {
    dragOver,
    setDragOver,
    onDragOver,
    onDragLeave,
    fileDropError,
    setFileDropError
  } = useDragAndDrop();
  const { uploadQuestions } = QuestionService();

  const [file, setFile] = useState<File>();
  const [repeteaded, setRepeteaded] = useState(0);
  const [viewRepeteadedModal, setRepeteadedModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [uploading, setUploading] = useState(false); 

  const onDrop = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();

    setDragOver(false);

    const selectedFile = e?.dataTransfer?.files[0];
    const type = selectedFile.name.split('.').pop();

    if (type !== "xlsx") {
      setShowError(true);
      return setFileDropError("Porfavor adjunte un archivo de formato xlsx");
    }
    setFile(selectedFile);
  };

  const fileSelect = (e: any) => {
    setShowError(false);
    e.preventDefault();
    const selectedFile = e.target.files[0];
    const type = selectedFile.name.split('.').pop();

    if (type !== "xlsx") {
      setShowError(true);
      return setFileDropError("Porfavor adjunte un archivo de formato xlsx");
    }
    setFile(selectedFile);
  };

  const uploadFile = async () => {
    try {
      setUploading(true); // Establece el estado en true antes de comenzar la carga
      if (file) {
        const uploadedQuestions = await uploadQuestions(file, false);
        if (uploadedQuestions[1].length > 0 ) {
          setRepeteadedModal(true);
          setRepeteaded(uploadedQuestions[1].length);
        }
        if(uploadedQuestions[0].length > 0) {
          showSuccessToast(`El archivo ${file?.name} ha insertado con exito ${uploadedQuestions[0].length}`);
        }
      }
    } catch (error) {
      setShowError(true);
      const castedError = errorService.castError(error);
      setFileDropError(JSON.stringify(castedError.message));
      showErrorToast(`Error en subir el archivo ${file?.name}`);
    }finally {
      setUploading(false); // Establece el estado en false cuando la carga se completa (o falla)
    }
  };

  const uploadRepeteadedFile = async () => {
    setRepeteadedModal(false);
    try {
      if (file) {
        await uploadQuestions(file, true);
        showSuccessToast(`El archivo ${file?.name} ha subido con exito ${repeteaded} preguntas repetidas`);
      }
    } catch (error) {
      setShowError(true);
      const castedError = errorService.castError(error);
      setFileDropError(JSON.stringify(castedError.message));
      showErrorToast(`Error en insertar preguntas duplicadas del archivo ${file?.name}`);
    }
  };

  return (
    <>
      <ConfirmationModal 
        text={`Hay ${repeteaded} preguntas repetidas, ¿quieres reemplazarlas?`} 
        onAccept={() => uploadRepeteadedFile()}
        onClose={() => setRepeteadedModal(false)}
        visible={viewRepeteadedModal}/>

      <form id="form-file-upload" className="mt-10 flex flex-col gap-5">
        {fileDropError && showError && (
          <span className="file-drop-error">{fileDropError}</span>
        )}
        <div className="transition border-[3px] py-[15vh] text-primary-100 cursor-pointer border-dashed border-primary-100 w-[50vw] text-lg text-center hover:border-primary-300">
          <label
            className="cursor-pointer"
            htmlFor="file"
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            style={{ border: `${dragOver ? "3px dashed yellowgreen" : ""}`, borderStyle: 'dashed' }}
          >
            {file && <h1 className="text-[100%] text-center">{file.name}</h1>}
            {!file && (
              <h1 style={{ color: `${dragOver ? " yellowgreen" : ""}` }} className="cursor-pointer pointer-events-none">
                {!dragOver ? "Selecciona o arrastra el archivo aquí..." : "Arrastra aquí..."}
              </h1>
            )}
          </label>
          <input type="file" name="file" id="file" onChange={fileSelect} />  
        </div>

      <UploadQuestionsButton
        onClick={async () => await uploadFile()}
        disabled={file?.name ? false : true}
      >
        {uploading ? 'Espere...' : 'Subir archivo'} 
      </UploadQuestionsButton>
      </form>
    </>
  );
}
             
