import { Dispatch, SetStateAction } from "react";
import DefaultAvatar from "./defaultAvatar";
import PictureAvatar from "./pictureAvatar";

export default function Avatar({
  src,
  diameter,
  className = '',
  setOpenSelectAvatarModal,
  setSrc
}: {
  src: string | null;
  diameter: number;
  className?: string;
  setOpenSelectAvatarModal: Dispatch<SetStateAction<boolean>>; 
  setSrc: Dispatch<SetStateAction<string>>;
}): JSX.Element {
  return src ? (
    <PictureAvatar src={src} diameter={diameter} className={className} setOpenSelectAvatarModal={setOpenSelectAvatarModal}/>
  ) : (
    <DefaultAvatar diameter={diameter} className={className} setOpenSelectAvatarModal={setOpenSelectAvatarModal} setSrc={setSrc}/>
  );
}