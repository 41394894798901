import { SubmitHandler, useForm } from 'react-hook-form';
import { IFormValues } from "../interfaces/types";
import UserService from '../services/httpServices/user.service';
import ToastService from '../services/toastService';
import TextInput from '../components/inputs/TextInput';
import MainButton from '../components/buttons/MainButton';


export default function RecoverPwd(): JSX.Element {

  const { sendEmailRecoverPassword } = UserService();
  const { showSuccessToast, showErrorToast } = ToastService();
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    control,
  } = useForm<IFormValues>({ mode: 'onChange' });

  const onSubmit: SubmitHandler<IFormValues> = async (formData) => {
    const { email } = formData;
    try {
      await sendEmailRecoverPassword(email);
      showSuccessToast(`El email a ${email} ha sido enviado con éxito, por favor revise su bandeja de entrada`);
    } catch (error: unknown) {
      showErrorToast(`El email a ${email} no se ha podido enviar, por favor inténtelo de nuevo`);
    }
  };

  return (
    <div className='pt-10 sm:pt-20 flex-1 flex flex-col items-center sm:pb-20 border-t-[1px] border-gray-200'>
      <div className='sm:max-w-[500px] px-6'>
        <h1 className="text-3xl font-semibold text-center text-primary-100 mb-5">
          Recuperar contraseña
        </h1>
        <span className="text-text-400">
          Introduce tu email y te enviaremos un correo electrónico para que puedas recuperar la contraseña.
        </span>
        <form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-2">
            <label
              htmlFor="email"
              className="block text-sm font-semibold text-gray-800"
            >
              Email
            </label>
            <TextInput
              placeholder="Email"
              label="email"
              type="email"
              register={register}
              minLength={{ value: 1, message: 'Email must not be empty' }}
              error={errors.email}
            />
          </div>
          <div className="mt-5">
            <MainButton className="w-full" isSubmitButton={true}>
              Enviar
            </MainButton>
          </div>
        </form>
        <p className="mt-7 text-xs font-light text-center text-gray-700">
          ¿No tienes cuenta?
          <a href="/register" className="ml-1 font-medium text-black-600 hover:underline">
            ¡Registrate aquí!
          </a>
        </p>
      </div>
    </div>
  );
}