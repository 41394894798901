import React, { useState } from "react";
import { BsQuestionCircleFill } from "react-icons/bs";

interface DoubtfulButtonProps {
    active: boolean | undefined;
    onClick: () => void
}

const DoubtfulButtonComponent: React.FC<DoubtfulButtonProps> = ({ onClick, active }) => {

    return (
        <button onClick={() => {
            onClick()
        }}>
            <BsQuestionCircleFill className={`transition-all text-3xl ${active && 'App-link'}`} >
            </BsQuestionCircleFill>
        </button>
    )

}

export default DoubtfulButtonComponent;
