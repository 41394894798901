import { useState } from "react";
import cookiesService from "../../services/cookies.service";
import { useCookies,  } from 'react-cookie';
import cookieIcon from '../../../assets/icons/cookieicon.png';
import MainButton from "../buttons/MainButton";


export default function CookiesConsent (): JSX.Element {

  const [visible, setVisble] = useState(true);
  const [cookies, setCookie] = useCookies(['consent']);

  const accept = () => {
    cookiesService.setCookie('consent_cookie', 'true');
    const date = new Date();
    date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
    setCookie('consent', 'true', {expires: date});
    setVisble(false);
    window.location.reload();
  };

  return (
    <div className="h-screen bg-background-200 flex flex-col items-center justify-center p-4">
      { visible && 
        <div className="flex flex-col items-center bg-white rounded-xl shadow-lg p-6 xs:py-8 xs:px-14 max-w-[550px] gap-5">
          <img src={cookieIcon} alt="Cookie Icon SVG" className="w-[50px]"/>
          <span className="text-center text-text-100">
            Auténtica Test usa cookies para ofrecer una mejor experiencia de usuario, acepta para acceder a la plataforma.
          </span>
          <MainButton 
          className="w-full"
          onClick={() => accept()}>
            Aceptar
          </MainButton>
          <a className="text-xs text-gray-400 hover:underline"
            href="https://www.autenticatest.com/cookies/ "
            target="_blank" 
            rel="noreferrer">
              Política de cookies
          </a>
        </div>
      }
    </div>
  );
};