import RegisterForm from "../components/forms/RegisterForm";


export default function RegisterView(): JSX.Element {

  return (
    <div className='flex-1 flex justify-center items-center sm:pb-20 border-t-[1px] border-gray-200'>
      <RegisterForm/>
    </div>
  );
}