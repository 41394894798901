import { loadStripe, RedirectToCheckoutServerOptions, Stripe, StripeError } from '@stripe/stripe-js';

let stripe: Stripe;

const paymentServiceStripe = {
  async getStripe(): Promise<Stripe> {
    const key = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_STRIPE_TEST_PK: process.env.REACT_APP_STRIPE_PK;
    if (!stripe) stripe = (await loadStripe(`${key}`)) as Stripe;
    return stripe;
  },

  async redirectToCheckout(options: RedirectToCheckoutServerOptions): Promise<{ error: StripeError }> {
    const stripe = await this.getStripe();
    return stripe.redirectToCheckout(options);
  },

};

export default paymentServiceStripe;