import React, { useState, useEffect } from 'react';
import packageJson from '../../../package.json';

global.appVersion = packageJson.version;

const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split('.').map(Number);
    const versionsB = versionB.split('.').map(Number);

    return versionsA.some((value, index) => {
        if (value === versionsB[index]) return false;
        return value > versionsB[index];
    });
};

const CacheBuster = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const [isLatestVersion, setIsLatestVersion] = useState(false);

    const refreshCacheAndReload = () => {
        console.log('Clearing cache and hard reloading...');
        if (caches) {
            caches.keys().then(function (names) {
                for (let name of names) caches.delete(name);
            });
        }
        window.location.reload(true);
    };

    useEffect(() => {
        fetch('/meta.json')
            .then(response => response.json())
            .then(meta => {
                const latestVersion = meta.version;
                const currentVersion = global.appVersion;

                const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
                if (shouldForceRefresh) {
                    console.log(`We have a new version - ${latestVersion}. Should force refresh`);
                    setIsLatestVersion(false);
                } else {
                    console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
                    setIsLatestVersion(true);
                }
                setLoading(false);
            });
    }, []);

    return children({ loading, isLatestVersion, refreshCacheAndReload });
};

export default CacheBuster;
