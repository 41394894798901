import { useAxios } from "../../contexts/axiosContext";


export default function PaymentService() {

  const { publicAxios } = useAxios();
  const planId = process.env.REACT_APP_STRIPE_PLAN_ID

  function createCheckoutSession(email) {
    return new Promise((resolve, reject) => {
      publicAxios.post(`/api/v1/payment/checkout/session/`, { email, priceId: planId })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  function billingDashboard() {
    return new Promise((resolve, reject) => {
      publicAxios.post(`/api/v1/payment/billing/dashboard/`, {})
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  return {
    createCheckoutSession,
    billingDashboard
  };
}
