import { useAuth } from "../../contexts/authContext";
import { useAxios } from "../../contexts/axiosContext";


export default function StatisticsService() {

  const { publicAxios } = useAxios();
  const auth = useAuth();

  function getStatisticUser() {
    return new Promise((resolve, reject) => {
      publicAxios.get(`/api/v1/answer`, {
        params: {
          "aggregate": `[{"$match": {"email": "${auth.user.email}"}},{"$project":{"subject_block":"$subject_block","corrects":{"$cond":[{"$eq":["$status","correct"]},1,0]},"failures":{"$cond":[{"$eq":["$status","failed"]},1,0]},"not_answered":{"$cond":[{"$eq":["$status","not_answered"]},1,0]}}},{"$group":{"_id":"$subject_block","corrects":{"$sum":"$corrects"},"failures":{"$sum":"$failures"},"not_answered":{"$sum":"$not_answered"}}}]`
        }
      })
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function resetStatistics() {
    return new Promise((resolve, reject) => {
      publicAxios.post(`/api/v1/answer/reset/answers`, {})
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  return {
    getStatisticUser,
    resetStatistics
  };
}
