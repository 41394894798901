export default function VerticalTabSelector({
  className,
  activeTab,
  onChange,
  tabs,
}: {
  className?: string,
  tabs: { id: string; label: string }[];
  activeTab: string;
  onChange: (newActiveTab: string) => void;
}): JSX.Element {
  return (
    <div className={`flex flex-col bg-white shadow-2xl md:min-w-[250px] ${className}`}>
      {tabs.map((tab) => (
        <button
          key={tab.id}
          className={
            `transition p-5 flex items-center justify-center font-medium
            ${tab.id === activeTab ? 'bg-primary-200 text-white' : 'text-text-300 hover:bg-primary-400'}
          `}
          onClick={() => onChange(tab.id)}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
}