import { useAuth } from "../../contexts/authContext";
import { useAxios } from "../../contexts/axiosContext";


export default function ReportService() {

  const { publicAxios } = useAxios();
  const auth = useAuth();

  function getReports() {
    return new Promise((resolve, reject) => {
      publicAxios.get(`/api/v1/report`, {
        params: {
          "resource": '{}',
          "populate": ['question', 'user'],
          'sort': 'statment'
        }
      })
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function countReports() {
    return new Promise((resolve, reject) => {
      publicAxios.get(`/api/v1/report/count`)
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function addReport(data) {
    return new Promise((resolve, reject) => {
      publicAxios.put(`/api/v1/report`, {
        description: data.description,
        question: data.question,
        user: auth.user._id
      })
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  function deleteReport(ids) {
    return new Promise((resolve, reject) => {
      publicAxios.post(`/api/v1/report/delete/admin`, {ids})
        .then(async (res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  return {
    getReports,
    countReports,
    addReport,
    deleteReport
  };
}