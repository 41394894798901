import { FieldError, Path, UseFormRegister, ValidationRule } from 'react-hook-form';
import { IFormValues } from '../../interfaces/types';

interface InputProps {
  label?: Path<IFormValues>;
  type: 'text' | 'email' | 'number' | 'date' | 'checkbox';
  disabled?: boolean;
  register?: UseFormRegister<IFormValues>;
  minLength?: ValidationRule<number> | undefined;
  maxLength?: ValidationRule<number> | undefined;
  placeholder: string;
  pattern?: ValidationRule<RegExp> | undefined;
  error?: FieldError | undefined;
  min?: ValidationRule<number | string> | undefined;
  required?: boolean;
  className?: string;
  autoFocus?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  value?: string | number; 
  name?: string;
  onChange?: any;
}

export default function TextInput({
  label,
  type,
  disabled,
  register,
  minLength,
  maxLength,
  placeholder,
  pattern,
  error,
  min,
  required,
  className,
  autoFocus,
  value,
  onChange
}: InputProps): JSX.Element {

  return (
    <div className={`${className}`}>
      { register && label ?
        <input
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          min={0}
          required={(required || required === false) ? required : true}
          autoFocus={autoFocus}
          {...register(label, {
            required,
            minLength,
            min,
            maxLength,
            pattern,
          })}
          className={
            `block rounded-md border-0 w-full px-4 py-2 mt-2 ring-1 ring-gray-300 focus:ring-black
            ${disabled ? 'bg-gray-100 text-gray-500' : 'text-black-100'}
            ${error && 'ring-error-100 bg-red-50'}
          `}
          value={value}
        /> :
        <input
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          min={0}
          required={(required || required === false) ? required : true}
          autoFocus={autoFocus}
          className={
            `block rounded-md border-0 w-full px-4 py-2 mt-2 ring-1 ring-gray-300 focus:ring-black
            ${disabled ? 'bg-gray-100 text-gray-500' : 'text-black-100'}
            ${error && 'ring-error-100 bg-red-50'}
          `}
          value={value}
          onChange={onChange}
        />
      }
    </div>
  );

}