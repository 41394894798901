import { useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { IFormValues } from '../../interfaces/types';
import errorService from '../../services/error.service';
import TextInput from '../inputs/TextInput';
import PasswordInput from '../inputs/PasswordInput';
import UserService from '../../services/httpServices/user.service';
import ToastService from '../../services/toastService';
import MainButton from '../buttons/MainButton';
import { SECONDARY_BUTTON } from '../../config/constants';


export default function AddUserForm(): JSX.Element {

  const validationSchema = Yup.object().shape({
    email: Yup.string().email()
      .required('El email del usuario es requerido'),
    name: Yup.string()
      .required('El nombre del usuario es requerido'),
    last_name: Yup.string()
      .optional(),
    password: Yup.string()
      .required('La contraseña del usuario es requerido'),
  });
  

  const { register, handleSubmit, formState, watch, control, getValues, setValue  } = useForm<IFormValues>({ 
    resolver: yupResolver(validationSchema)});

  const { errors } = formState;
  const { addUserForAdmin } = UserService();
  const { showSuccessToast, showErrorToast } = ToastService();
  
  const [editUserError, setEditUserError] = useState<string[]>([]);
  const [showErrors, setShowErrors] = useState(false);

  const onSubmit = async (data: any) => {
    try {
      await addUserForAdmin(data);
      showSuccessToast('El usuario ha sido creado con éxito');
    } catch (error) {
      showErrorToast(`El usuario no se ha modificado con éxito`);
      const castedError = errorService.castError(error);
      setEditUserError([JSON.stringify(castedError.message)]);
      setShowErrors(true);
    }
  };  

  const generatePassword = () => {
    const randomPassword = Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2);
    setValue('password', randomPassword);
  };

  return (
    <>
      <div className='font-medium text-text-200 text-2xl'>Crear usuario</div>
      <form onSubmit={handleSubmit(onSubmit)}
      className='flex flex-col gap-5 p-10 border border-gray-300 rounded-lg mt-5'>
          <div>
            <label>
              Email
            </label>
            <TextInput
              className='min-w-[300px]'
              placeholder="Nombre del usuario"
              label="email"
              type='text'
              register={register}
              error={errors.email}
              disabled={false}
            />
            {errors.email?.message && (
              <span className="text-red-600">{errors.email.message}</span>
            )}
          </div>

          <div>
            <label>
              Nombre
            </label>
            <TextInput
              className='min-w-[300px]'
              placeholder="Nombre del usuario"
              label="name"
              type='text'
              register={register}
              error={errors.name}
              disabled={false}
            />
            {errors.name?.message && (
              <span className="text-xs text-red-600">{errors.name.message}</span>
            )}
          </div>

          <div>
            <label>
              Apellidos
            </label>
            <TextInput
              className='min-w-[300px]'
              placeholder="Apellidos del usuario"
              label="last_name"
              type='text'
              register={register}
              error={errors.last_name}
            />
            {errors.last_name?.message && (
              <span className="text-red-600">{errors.last_name.message}</span>
            )}
          </div>

          <div>
            <label>
              Contraseña
            </label>
            <div className='flex flex-col sm:flex-row items-center gap-3'>
              <div>
                <PasswordInput
                  className='min-w-[300px]'
                  placeholder="Contraseña"
                  label={'password'}
                  register={register}
                  required={true}
                  minLength={{ value: 8, message: 'El tamaño mínimo son 8 digitos' }}
                  error={errors.password}
                />
              </div>
              <MainButton
              className="w-full mb-5 sm:mb-0 sm:w-auto sm:mt-2"
              type={SECONDARY_BUTTON}
              onClick={() => generatePassword()}>
                Generar contraseña segura
              </MainButton>
            </div>

            {errors.password?.message && (
              <span className="text-red-600">{errors.password.message}</span>
            )}
          </div>
          {editUserError && showErrors && (
            <span className="text-red-600">{editUserError}</span>
          )}

          <MainButton
          disabled={errors.email}
          isSubmitButton={true}>
            Crear
          </MainButton>
      </form>
    </>
  );
};