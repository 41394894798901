import Avatar from "../avatar";
import { useEffect, useState } from "react";
import ModalSelectAvatar from "../avatar/modalSelectAvatar";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { IFormValues } from "../../interfaces/types";
import TextInput from "../inputs/TextInput";
import errorService from "../../services/error.service";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import dateService from "../../services/date.service";
import UserService from "../../services/httpServices/user.service";
import ToastService from "../../services/toastService";
import { useAuth } from "../../contexts/authContext";
import MainButton from "../buttons/MainButton";
import { Button } from "flowbite-react";
import ConfirmationModal from "../modals/ConfirmationModal";
import { useSubscription } from "../../contexts/subscriptionContext";
import { useNavigate } from "react-router-dom";
import { DESIST_BUTTON } from "../../config/constants";


export default function EditProfileTab() {

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('El nombre del usuario es requerido'),
    last_name: Yup.string()
      .optional(),
    birthday: Yup.string()
      .optional(),
  });

  const auth = useAuth();
  const navigate = useNavigate();
  const { getInfoUser, updateUser, deleteUser } = UserService();
  const { showSuccessToast, showErrorToast } = ToastService();
  const { register, handleSubmit, formState, control, setValue } = useForm<IFormValues>({ resolver: yupResolver(validationSchema) });
  const { errors } = formState;

  const [openSelectAvatarModal, setOpenSelectAvatarModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [editPerfilError, setEditPerfilError] = useState<string[]>([]);
  const [showErrors, setShowErrors] = useState(false);
  const [src, setSrc] = useState('');
  const [showDeleteModal, setDeleteModal] = useState(false);

  const getUserData = async () => {
    getInfoUser().then((user) => {
      auth.updateUser(user);
    }).catch((error) => {
      //TODO: control error
    }).finally(() => {
      setIsLoading(false);
    })
  }

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (auth.user) {
      setValue('name', auth.user.name);
      setValue('last_name', auth.user.last_name);
      setValue('birthday', auth.user.birthday ? dateService.format(new Date(auth.user.birthday), 'YYYY-MM-DD') : '');
      if (auth.user.avatar) setSrc(auth.user.avatar);
    }
  }, [auth.user]);

  const onSubmit: SubmitHandler<IFormValues> = async (formData) => {
    const { name, birthday, last_name } = formData;
    try {
      await updateUser({ email: auth.user.email, name, birthday, last_name });
      showSuccessToast('El perfil ha sido actualizado con éxito');
    } catch (error: unknown) {
      displayError('El perfil no ha sido actualizado con éxito, inténtelo de nuevo', error);
    }
  };

  async function onDelete(): Promise<void> {
    try {
      await deleteUser();
      auth.logout()
      navigate("/");
    } catch (error: unknown) {
      displayError('No se ha podido eliminar el perfil, inténtelo de nuevo', error);
    }
  };

  const displayError = (text: string, error: unknown) => {
    showErrorToast(text);
    const castedError = errorService.castError(error);
    setEditPerfilError([JSON.stringify(castedError.message)]);
    setShowErrors(true);
  }

  return (
    <>
      {!isLoading &&
        <div className="flex flex-col items-center px-6">
          <ConfirmationModal
            text={'¿Seguro que deseas borrar tu cuenta? Todos tus datos serán eliminados de forma irrecuperable e inmediata.'}
            onClose={async () => await onDelete()}
            onAccept={() => setDeleteModal(false)}
            visible={showDeleteModal}
            cancelText='Eliminar'
            acceptText='Cancelar'
          />
          <Avatar diameter={100} src={src} setOpenSelectAvatarModal={setOpenSelectAvatarModal} setSrc={setSrc} />
          {openSelectAvatarModal &&
            <ModalSelectAvatar setOpenSelectAvatarModal={setOpenSelectAvatarModal} diameter={100} setSrc={setSrc} />
          }
          <form className="w-full sm:max-w-[500px] mt-6" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-2">
              <label
                htmlFor="email"
                className="block text-sm font-semibold text-gray-800"
              >
                Email
              </label>
              <TextInput
                placeholder={''}
                label="email"
                type="text"
                register={register}
                error={errors.email}
                className={'bg-gray'}
                disabled={true}
                value={auth.user.email}
              />
            </div>
            <div className="mb-2">
              <label
                htmlFor="name"
                className="block text-sm font-semibold text-gray-800"
              >
                Nombre
              </label>
              <Controller
                name={`name`}
                control={control}
                render={({ field }) => (
                  <input
                    type='text'
                    required={true}
                    className={`block w-full px-4 py-2 mt-2 text-black-100 border rounded-md focus:border-primary-100 focus:ring-primary-100 focus:outline-none focus:ring focus:ring-opacity-40
                  ${errors.name ? 'h-11 rounded-md border-error-100 bg-red-50 px-4 text-lg' : 'focus:outline-none h-11 rounded-md rounded-md focus:border-primary-100 focus:ring-primary-100 focus:outline-none focus:ring focus:ring-opacity-40 px-4 text-lg '}`}
                    {...register('name')}
                    onChange={(val: any) => {
                      field.onChange(val.target.value);
                    }}
                  />
                )}
              />
            </div>
            <div className="mb-2">
              <label
                htmlFor="last_name"
                className="block text-sm font-semibold text-gray-800"
              >
                Apellidos
              </label>
              <Controller
                name={`last_name`}
                control={control}
                render={({ field }) => (
                  <input
                    type='text'
                    required={false}
                    className={`block w-full px-4 py-2 mt-2 text-black-100 border rounded-md focus:border-primary-100 focus:ring-primary-100 focus:outline-none focus:ring focus:ring-opacity-40
                  ${errors.last_name ? 'h-11 rounded-md border-error-100 bg-red-50 px-4 text-lg' : 'focus:outline-none h-11 rounded-md rounded-md focus:border-primary-100 focus:ring-primary-100 focus:outline-none focus:ring focus:ring-opacity-40 px-4 text-lg '}`}
                    {...register('last_name')}
                    onChange={(val: any) => {
                      field.onChange(val.target.value);
                    }}
                  />
                )}
              />
            </div>
            <div className="mb-2">
              <label
                htmlFor="birthday"
                className="block text-sm font-semibold text-gray-800"
              >
                Fecha de nacimiento
              </label>
              <Controller
                name={`birthday`}
                control={control}
                render={({ field }) => (
                  <input
                    type='date'
                    required={false}
                    data-date-format="DD MMMM YYYY"
                    className={`block w-full px-4 py-2 mt-2 text-black-100 border rounded-md focus:border-primary-100 focus:ring-primary-100 focus:outline-none focus:ring focus:ring-opacity-40
                    ${errors.birthday ? 'h-11 rounded-md border-error-100 bg-red-50 px-4 text-lg' : 'focus:outline-none h-11 rounded-md rounded-md focus:border-primary-100 focus:ring-primary-100 focus:outline-none focus:ring focus:ring-opacity-40 px-4 text-lg '}`}
                    {...register('birthday')}
                    onChange={(val: any) => {
                      field.onChange(val.target.value);
                    }}
                  />
                )}
              />
            </div>
            {editPerfilError && showErrors && (
              <div className="flex flex-row items-center pt-1 text-red-600	">
                <span className="font-base bg-yellow-50 text-sm text-red-600">{editPerfilError}</span>
              </div>
            )}
            <div className="mt-6 text-center">
              <MainButton isSubmitButton={true} className="w-full">
                Guardar
              </MainButton>
            </div>
            <div className="mt-6 text-center">
              <MainButton
                isSubmitButton={false}
                name="desist"
                className="w-full"
                onClick={() => setDeleteModal(true)}
                type={DESIST_BUTTON}
              >
                Eliminar cuenta
              </MainButton>
            </div>
          </form>
        </div>
      }
    </>
  );
};
