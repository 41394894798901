export function getLastBoeVersion(url: string | undefined) {
    if(url) {
        const urlParts = url.split('?');
        const queryParams = new URLSearchParams(urlParts[1]);
        if(queryParams.has('p')) {
            const now = new Date();
            queryParams.set("p", now.toISOString().substring(0,10).split('-').join(""));
            return `${urlParts[0]}?${decodeURIComponent(queryParams.toString())}`;
        }
    }
    return url;
  }
