import ToastService from "./toastService";


export default function UtilsService() {

  const { showSuccesToast } = ToastService();

  function downloadZip(blob, name) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = name + '.zip';
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
    showSuccesToast(`El archivo ZIP ${name}.zip ha sido descargado con éxito!`);
  }

  function downloadPDFblob(blob, name) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = name + '.pdf';
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
    showSuccesToast(`El pdf ${name} ha sido descargado con exito!`);
  }

  function downloadExcelblob(blob, name) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = name + '.xlsx';
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
    showSuccesToast(`El excel ${name} ha sido descargado con exito!`);
  }

  return {
    downloadZip,
    downloadPDFblob,
    downloadExcelblob
  };
}