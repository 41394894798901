import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { IFormValues } from '../../interfaces/types';
import PasswordInput from '../inputs/PasswordInput';
import TextInput from '../inputs/TextInput';
import AuthService from '../../services/authService';
import MainButton from '../buttons/MainButton';
import { Spinner } from 'flowbite-react';
import { PRIMARY_COLOR } from '../../config/constants';
import { useNavigate } from 'react-router-dom';


export default function LoginForm(props: any) {

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormValues>({ mode: 'onChange' });
  const { login } = AuthService();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [authError, setAuthError] = useState(null);

  const onSubmit: SubmitHandler<IFormValues> = async (formData) => {
    const { email, password } = formData;
    setIsLoading(true);
    login(email, password).then(()=>{
      navigate("/");
    }).catch((err: any) => {
      setAuthError(err?.response?.data?.error?.message)
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const shadowStyle = "sm:shadow-xl";

  return (
    <div className={`w-full p-10 bg-white rounded-lg sm:max-w-xl ${props.shadow && shadowStyle}`}>
      <h1 className="text-3xl font-semibold text-center text-primary-100">
        Iniciar sesión
      </h1>
      { isLoading ?
        <div className='text-center my-6'>
          <Spinner className="fill-primary-100"></Spinner>
        </div> :
        <div>
          <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="text-sm font-semibold text-gray-700"
              >
                Email
              </label>
              <TextInput
                placeholder="Email"
                label="email"
                type="email"
                register={register}
                minLength={{ value: 1, message: 'Email must not be empty' }}
                error={errors.email}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-sm font-semibold text-gray-700"
              >
                Contraseña
              </label>
              <PasswordInput
                placeholder="Contraseña"
                label={'password'}
                className='mt-2'
                register={register}
                required={true}
                minLength={{ value: 1, message: 'Password must not be empty' }}
                error={errors.password}
              />
            </div>
            <a
              href="/send/recover/password"
              className="text-xs text-gray-800 hover:underline"
            >
              He olvidado mi contraseña
            </a>
            { authError &&
              <div className="text-sm text-red-600 mt-5">{authError}</div>
            }
            <div className="mt-6">
              <MainButton
              className="w-full"
              isSubmitButton={true}>
                Iniciar sesión
              </MainButton>
            </div>
          </form>

          <p className="mt-8 text-xs font-light text-center text-gray-700">
            <span>¿No tienes cuenta? </span>
            <a
              href="/register"
              className="font-medium text-black-600 hover:underline"
            >
              <span>¡Regístrate pulsando aquí!</span>
            </a>
          </p>
        </div>
      }
    </div>
  );
};
