import MainButton from "../../../components/buttons/MainButton"
import { FLOATING_BUTTON } from "../../../config/constants"
import { GoChevronLeft } from "react-icons/go"
import { useAuth } from "../../../contexts/authContext";
import { useNavigate } from "react-router-dom";
import CreateTest from "./../../../components/admin-panel/tests/createTest/CreateTest";
import { useEffect, useState } from "react";

const CustomTest = (): JSX.Element => {

    const auth = useAuth();
    const navigate = useNavigate();
    const [subscriptionActive, setSubscriptionActive] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                setSubscriptionActive(auth.hasUserSubscriptionAccess())
            } catch (error: any) {
                if (error.response && error.response.status === 401) {
                    auth.logout();
                }
            } finally {
                setIsLoading(false);
            }
        })()
    }, []);

    return (
        <div className="relative flex flex-col flex-1 bg-background-200 ">
            <div className="flex flex-col items-center px-8 w-full mt-20">
                <h2 className="text-3xl font-bold text-text-100 text-center">
                    Crear test personalizado
                </h2>
                <p className="mt-4 mb-8 text-text-300 text-center">
                    Selecciona Bloque, Normativa, Título y Número de Preguntas
                </p>
                <MainButton
                    className="absolute top-5 left-5"
                    type={FLOATING_BUTTON}
                    onClick={() => {
                        if (subscriptionActive) navigate(-1)
                        else navigate('/')
                    }}>
                    <GoChevronLeft size={16} />
                    Atrás
                </MainButton>
                <div className=" flex items-center justify-center w-full">
                    <CreateTest customTest={true} active={true} />
                </div>
            </div>
        </div>
    )
}

export default CustomTest