import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { IFormValues } from '../../../../interfaces/types';
import errorService from '../../../../services/error.service';
import TextInput from '../../../inputs/TextInput';
import { ArrowLeft } from 'phosphor-react';
import { IQuestion } from '../../../../interfaces';
import { Select } from 'flowbite-react';
import QuestionService from '../../../../services/httpServices/question.service';
import ToastService from '../../../../services/toastService';
import MainButton from '../../../buttons/MainButton';
import { FLOATING_BUTTON } from '../../../../config/constants';
import { GoChevronLeft } from 'react-icons/go';
import { getLastBoeVersion } from '../../../../services/boe.service';


export interface IEditQuestionProp {
  question: IQuestion
  setIsViewEditQuestion: Dispatch<SetStateAction<boolean>>
}

export default function EditQuestionComponent(props: IEditQuestionProp): JSX.Element {

  const { question, setIsViewEditQuestion } = props;
  const { getQuestionsForCreateSimulations, updateQuestion } = QuestionService();
  const { showErrorToast, showSuccessToast } = ToastService();
  const validationSchema = Yup.object().shape({
    statement: Yup.string()
      .required('El enunciado de la pregunta es requerido'),
    subject_block: Yup.string()
      .required('El bloque de materia la pregunta es requerido'),
    standard: Yup.string()
      .required('La normativa de la pregunta es requerida'),
    option_1: Yup.string()
      .required('La opción 1 de la pregunta es requerida'),
    option_2: Yup.string()
      .required('La opción 2 de la pregunta es requerida'),
    option_3: Yup.string()
      .required('La opción 3 de la pregunta es requerida'),
    option_4: Yup.string()
      .required('La opción 4 de la pregunta es requerida'),
    article: Yup.string()
      .required('El articulo de la pregunta  es requerido'),
    title: Yup.string()
      .optional(),
    governmental: Yup.boolean()
      .required('El tipo estatal de la pregunta es requerido'),
    autonomic: Yup.boolean()
      .required('El tipo autonomico de la pregunta es requerido'),
    local: Yup.boolean()
      .required('El tipo local de la pregunta es requerido'),
    preparer: Yup.string()
      .required('El preparador de la pregunta es requerido'),
    level: Yup.number()
      .required('La dificultad de la pregunta es requerida'),
    article_url: Yup.string()
      .required('La URL del articulo de la pregunta es requerida'),
    answer: Yup.number()
      .required('La respuesta de la pregunta es requerida').min(1).max(4),
  });

  const { register, handleSubmit, formState, watch, control, getValues } = useForm<IFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues:
    {
      answer: question.answer,
      article: question.article,
      article_url: getLastBoeVersion(question.article_url),
      autonomic: question.autonomic,
      standard: question.standard,
      subject_block: question.subject_block,
      statement: question.statement,
      level: question.level,
      option_1: question.option_1,
      option_2: question.option_2,
      option_3: question.option_3,
      option_4: question.option_4,
      title: question.title,
      local: question.local,
      governmental: question.governmental,
      preparer: question.preparer
    }
  });
  const { errors } = formState;
  const [simulations, setSimulations] = useState<any[]>([]);
  const [editQuestionError, setEditQuestionError] = useState<string[]>([]);
  const [showErrors, setShowErrors] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);


  useEffect(() => {
    async function getSimulations() {
      const simulations: any[] = await getQuestionsForCreateSimulations();
      setSimulations(simulations);
    }
    getSimulations();
  }, []);

  const onSubmit = async (data: any) => {
    try {
      if (question._id) {
        setIsUpdate(true);
        await updateQuestion(question._id, data);
        setIsUpdate(false);
        showSuccessToast(`La pregunta se ha modificado con éxito`);
        window.location.reload();
      }
    } catch (error) {
      setIsUpdate(false);
      showErrorToast(`La pregunta no se ha modificado con éxito, revisa los datos`);
      const castedError = errorService.castError(error);
      setEditQuestionError([JSON.stringify(castedError.message)]);
      setShowErrors(true);
    }
  };
  const subject_block = getValues().subject_block;
  useWatch({ control, name: 'subject_block', defaultValue: question.subject_block });
  useWatch({ control, name: 'standard', defaultValue: question.standard });
  useWatch({ control, name: 'title', defaultValue: question.title });
  useWatch({ control, name: 'option_1', defaultValue: question.option_1 });
  useWatch({ control, name: 'option_2', defaultValue: question.option_2 });
  useWatch({ control, name: 'option_3', defaultValue: question.option_3 });
  useWatch({ control, name: 'option_4', defaultValue: question.option_4 });
  useWatch({ control, name: 'answer', defaultValue: question.answer });
  useWatch({ control, name: 'article', defaultValue: question.article });
  useWatch({ control, name: 'preparer', defaultValue: question.preparer });
  useWatch({ control, name: 'article_url', defaultValue: getLastBoeVersion(question.article_url) });
  useWatch({ control, name: 'autonomic', defaultValue: question.autonomic });
  useWatch({ control, name: 'governmental', defaultValue: question.governmental });
  useWatch({ control, name: 'local', defaultValue: question.local });

  const standard = getValues().standard;
  const title = getValues().title;

  const statement = getValues().statement;
  const option_1 = getValues().option_1;
  const option_2 = getValues().option_2;
  const option_3 = getValues().option_3;
  const option_4 = getValues().option_4;
  const article_url = getValues().article_url;
  const governmental = getValues().governmental;
  const autonomic = getValues().autonomic;
  const local = getValues().local;

  return (
    <div className="w-full flex-1 flex flex-col items-center relative pt-12">
      <MainButton
        className="absolute top-0 left-0"
        type={FLOATING_BUTTON}
        onClick={() => {
          setIsViewEditQuestion(false);
        }}>
        <GoChevronLeft size={16} />
        Atrás
      </MainButton>

      <div>
        <div className='font-bold text-2xl text-text-200'>
          Editar pregunta
        </div>

        <form onSubmit={handleSubmit(onSubmit)}
          className='flex flex-col gap-5 p-10 border border-gray-300 rounded-lg mt-5 w-full sm:min-w-[500px] sm:max-w-[800px]'>
          <div>
            <label>
              Enunciado de la pregunta*
            </label>
            <Controller
              name={`statement`}
              control={control}
              render={({ field }) => (
                <textarea
                  id="statement"
                  rows={4}
                  className="mt-2 block p-2.5 w-[40rem] text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 resize-none"
                  placeholder="Escribir el enunciado de la pregunta..."
                  onChange={(val) => {
                    field.onChange(val.target.value);
                  }}
                  defaultValue={statement}
                />
              )}
            />
            {errors.statement?.message && (
              <span className="text-red-600">{errors.statement.message}</span>
            )}
          </div>

          <div>
            <label>
              Opción 1 de la pregunta*
            </label>
            <Controller
              name={`option_1`}
              control={control}
              render={({ field }) => (
                <textarea
                  id="option_1"
                  rows={4}
                  className="mt-2 block p-2.5 w-[40rem] text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 resize-none"
                  placeholder="Opción 1 de la pregunta"
                  onChange={(val) => {
                    field.onChange(val.target.value);
                  }}
                  defaultValue={option_1}

                />
              )}
            />
            {errors.option_1?.message && (
              <span className="text-red-600">{errors.option_1.message}</span>
            )}
          </div>

          <div>
            <label>
              Opción 2 de la pregunta*
            </label>
            <Controller
              name={`option_2`}
              control={control}
              render={({ field }) => (
                <textarea
                  id="option_2"
                  rows={4}
                  className="mt-2 block p-2.5 w-[40rem] text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 resize-none"
                  placeholder="Opción 2 de la pregunta"
                  onChange={(val) => {
                    field.onChange(val.target.value);
                  }}
                  defaultValue={option_2}
                />
              )}
            />
            {errors.option_2?.message && (
              <span className="text-red-600">{errors.option_2.message}</span>
            )}
          </div>

          <div>
            <label>
              Opción 3 de la pregunta*
            </label>
            <Controller
              name={`option_3`}
              control={control}
              render={({ field }) => (
                <textarea
                  id="option_3"
                  rows={4}
                  className="mt-2 block p-2.5 w-[40rem] text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 resize-none"
                  placeholder="Opción 3 de la pregunta"
                  onChange={(val) => {
                    field.onChange(val.target.value);
                  }}
                  defaultValue={option_3}
                />
              )}
            />
            {errors.option_3?.message && (
              <span className="text-red-600">{errors.option_3.message}</span>
            )}
          </div>

          <div>
            <label>
              Opción 4 de la pregunta*
            </label>
            <Controller
              name={`option_4`}
              control={control}
              render={({ field }) => (
                <textarea
                  id="option_4"
                  rows={4}
                  className="mt-2 block p-2.5 w-[40rem] text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 resize-none"
                  placeholder="Opción 4 de la pregunta"
                  onChange={(val) => {
                    field.onChange(val.target.value);
                  }}
                  defaultValue={option_4}
                />
              )}
            />
            {errors.option_4?.message && (
              <span className="text-red-600">{errors.option_4.message}</span>
            )}
          </div>

          <div>
            <label>
              Respuesta*
            </label>
            <TextInput
              placeholder="Respuesta"
              label="answer"
              type='number'
              register={register}
              minLength={{ value: 1, message: 'La respuesta de la pregunta de la pregunta no puede estar vacia' }}
              error={errors.answer}
              className='w-1/5'
            />
            {errors.answer?.message && (
              <span className="text-red-600">{errors.answer.message}</span>
            )}
          </div>

          <div>
            <label>
              Nivel*
            </label>
            <TextInput
              placeholder="Nivel"
              label="level"
              type='number'
              register={register}
              minLength={{ value: 1, message: 'El nivel de la pregunta de la pregunta no puede estar vacio' }}
              error={errors.level}
              className='w-1/5'
            />
            {errors.level?.message && (
              <span className="text-red-600">{errors.level.message}</span>
            )}
          </div>

          <div>
            <label>
              Número del articulo*
            </label>
            <TextInput
              placeholder="Número de articulo"
              label="article"
              type='number'
              register={register}
              minLength={{ value: 1, message: 'El articulo de la pregunta de la pregunta no puede estar vacio' }}
              error={errors.article}
              className='w-1/5'
            />
            {errors.article?.message && (
              <span className="text-red-600">{errors.article.message}</span>
            )}
          </div>

          <div>
            <label>
              Preparador
            </label>
            <TextInput
              placeholder="Preparador"
              label="preparer"
              type='text'
              register={register}
              minLength={{ value: 1, message: 'El preparador de la pregunta de la pregunta no puede estar vacio' }}
              error={errors.preparer}
              className='w-1/5'
            />
            {errors.preparer?.message && (
              <span className="text-red-600">{errors.preparer.message}</span>
            )}
          </div>

          <div className='flex items-center gap-5'>
            <label>
              Autonomica
            </label>
            <Controller
              name={`autonomic`}
              control={control}
              render={({ field }) => (
                <div id="autonomic" className='text-center'>
                  <label className="flex items-center cursor-pointer relative">
                    <input type="checkbox" value="" className="sr-only peer" checked={autonomic}
                      onChange={(e: any) => {
                        field.onChange(e.target.checked);
                      }}
                    />
                    <div className="toggle-bg bg-gray-200 border-2 border-gray-200 h-6 w-11 rounded-full  peer-checked:bg-primary-200 peer-checked:border-0"></div>
                  </label>
                </div>
              )}
            />
            {errors.autonomic?.message && (
              <span className="text-red-600">{errors.autonomic.message}</span>
            )}
          </div>

          <div className='flex items-center gap-5'>
            <label>
              Local
            </label>
            <Controller
              name={`local`}
              control={control}
              render={({ field }) => (
                <div id="local" className='text-center'>
                  <label className="flex items-center cursor-pointer relative">
                    <input type="checkbox" value="" className="sr-only peer" checked={local}
                      onChange={(e: any) => {
                        field.onChange(e.target.checked);
                      }}
                    />
                    <div className="toggle-bg bg-gray-200 border-2 border-gray-200 h-6 w-11 rounded-full  peer-checked:bg-primary-200 peer-checked:border-0"></div>
                  </label>
                </div>
              )}
            />
            {errors.local?.message && (
              <span className="text-red-600">{errors.local.message}</span>
            )}
          </div>

          <div className='flex items-center gap-5'>
            <label>
              Estatal
            </label>
            <Controller
              name={`governmental`}
              control={control}
              render={({ field }) => (
                <div id="governmental" className=''>
                  <label className="flex items-center cursor-pointer relative">
                    <input type="checkbox" value="" className="sr-only peer" checked={governmental}
                      onChange={(e: any) => {
                        field.onChange(e.target.checked);
                      }}
                    />
                    <div className="toggle-bg bg-gray-200 border-2 border-gray-200 h-6 w-11 rounded-full  peer-checked:bg-primary-200 peer-checked:border-0"></div>
                  </label>
                </div>
              )}
            />
            {errors.governmental?.message && (
              <span className="text-red-600">{errors.governmental.message}</span>
            )}
          </div>

          <div>
            <label>
              URL del artículo
            </label>
            <Controller
              name={`article_url`}
              control={control}
              render={({ field }) => (
                <textarea
                  id="article_url"
                  rows={4}
                  className="mt-2 block p-2.5 w-[40rem] text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 resize-none"
                  placeholder="La URL de la pregunta"
                  onChange={(val) => {
                    field.onChange(val.target.value);
                  }}
                  defaultValue={article_url}
                />
              )}
            />
            {errors.article_url?.message && (
              <span className="text-red-600">{errors.article_url.message}</span>
            )}
          </div>

          <div>
            <label>
              Bloque de materia*
            </label>
            <Controller
              name={`subject_block`}
              control={control}
              render={({ field }) => (
                <div id="selectsubjectblock" className='mt-2'>
                  <Select
                    id="subject_block"
                    required={true}
                    value={subject_block}
                    onChange={(val: any) => {
                      field.onChange(val.target.value);

                    }}
                  >
                    {
                      simulations.map((res, index: number) => {
                        return <option key={res._id}> {res._id} </option>;
                      })
                    }

                  </Select>
                </div>
              )}
              defaultValue={question.subject_block}
            />
          </div>

          <div>
            <label>
              Normativa*
            </label>
            <Controller
              name={`standard`}
              control={control}
              render={({ field }) => (
                <div id="selectstandard" className='mt-2'>
                  <Select
                    id="standard"
                    required={true}
                    value={standard}
                    onChange={(val: any) => {
                      field.onChange(val.target.value);
                    }}
                  >
                    {
                      simulations.filter((res) => res._id === subject_block).map((res) => {
                        return res.standards.map((res1: any) => {
                          return <option key={res1._id} > {res1._id} </option>;
                        });
                      })
                    }

                  </Select>
                </div>
              )}
              defaultValue={question.standard}
            />
          </div>

          <div>
            <label>
              Titulo*
            </label>
            <Controller
              name={`title`}
              control={control}
              render={({ field }) => (
                <div id="selectitle" className='mt-2'>
                  <Select
                    id="title"
                    required={true}
                    value={title}
                    onChange={(val: any) => {
                      field.onChange(val.target.value);
                    }}
                  >
                    {
                      simulations.filter((res: any) => res._id === subject_block).map((res: any) => {
                        const standardItem = res.standards.filter((res: any) => res._id === standard);
                        return standardItem.map((item: any) => {
                          return item.titles.map((title: any) => {
                            return <option key={title._id}> {title._id} </option>;
                          });
                        });

                      })
                    }


                  </Select>
                </div>
              )}
              defaultValue={question.title}
            />
          </div>

          {editQuestionError && showErrors && (
            <span className="text-red-600">{editQuestionError}</span>
          )}

          <div className="flex justify-end mt-5">
            <MainButton isSubmitButton={true} disabled={isUpdate}>
              {isUpdate ? 'Actualizando Pregunta...' : 'Actualizar Pregunta'}
            </MainButton>
          </div>
        </form>
      </div>
    </div>
  );
};
