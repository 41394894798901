import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MainButton from "../components/buttons/MainButton";
import { PRIMARY_BUTTON, SECONDARY_GRAY_BUTTON } from "../config/constants";


export default function PaymentStatusView(): JSX.Element {

  const navigate = useNavigate();
  const search = useLocation().search;
  const status = new URLSearchParams(search).get('payment_status');

  const success = status === 'success';

  const buttonPressedHandler = () => {
    if(success) navigate('login');
    else navigate('register');
  }
    
  return (
    <div className='flex-1 flex flex-col sm:flex-row justify-center sm:justify-evenly gap-16 border-t-[1px] border-gray-200 items-center p-6 sm:p-12'>
      <div className="w-[50%] sm:w-[30%] max-w-[250px]">
        { success ?
          <img src={require('../../assets/icons/thanks.png')}></img> :
          <img src={require('../../assets/icons/payment_error.png')}></img>
        }
      </div>
      <div>
        <h1 className={`${success ? 'text-primary-100' : 'text-error-100'} text-center sm:text-left font-bold text-xl sm:text-4xl mb-2`}>
          { success ? '¡Gracias por suscribirte a Auténtica Test!' : 'Pago no completado'}
        </h1>
        <h2 className="text-text-300 text-center sm:text-left">
          { success ? 'Ya puedes iniciar sesión con tus credenciales y empezar a realizar tests' : 'Se ha producido un error en el proceso de pago. Por favor, vuelve a intentarlo o ponte en contacto con nosotros'}
        </h2>
        <MainButton 
        type={success ? PRIMARY_BUTTON : SECONDARY_GRAY_BUTTON} 
        className="mt-8 w-full sm:w-auto min-w-[150px]"
        onClick={() => buttonPressedHandler()}>
          { success ? 'Empezar' : 'Volver'}
        </MainButton>
      </div>
    </div>
  );
}