import FAQS from "../components/FAQS/FAQS";


export default function FAQSView(): JSX.Element {

  return (
    <div className='flex-1 pt-10 sm:pb-20 border-t-[1px] border-gray-200'>
      <FAQS/>
    </div>
  );
}