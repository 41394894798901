import { useLocation, useNavigate } from "react-router-dom";
import UserService from "../services/httpServices/user.service";
import * as Yup from 'yup';
import { IFormValues } from "../interfaces/types";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import errorService from "../services/error.service";
import PasswordInput from "../components/inputs/PasswordInput";
import MainButton from "../components/buttons/MainButton";
import jwt_decode from "jwt-decode";
import ToastService from "../services/toastService";


export default function ResetPwd(): JSX.Element {

  const { showSuccessToast } = ToastService();
  const navigate = useNavigate();
  const { recoverPassword } = UserService();
  const search = useLocation().search;

  const token = new URLSearchParams(search).get('recover_token');
  
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('La contraseña es requerida').min(8, 'La longitud minima debe de ser 8'),
    confirmPassword: Yup.string()
      .required('La confirmación de contraseña es requerida').min(8, 'La longitud minima debe de ser 8')
      .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<IFormValues>({ resolver: yupResolver(validationSchema), mode: 'onChange'});

  const password = useWatch({ control, name: 'password', defaultValue: '' });
  const confirmPassword = useWatch({ control, name: 'confirmPassword', defaultValue: '' });

  const [recoverPasswordError, setRecoverPasswordError] = useState<string[]>([]);
  const [showErrors, setShowErrors] = useState(false);

  const onSubmit: SubmitHandler<IFormValues> = async (formData: { password: string; confirmPassword: string; }) => {
    const { password } = formData;
    try {
      const decodedToken : any = jwt_decode(token!);
      await recoverPassword(decodedToken?.email, password);
      showSuccessToast(`La contraseña se ha modificado correctamente`);
      navigate('/login');
    } catch (error: unknown) {
      const castedError = errorService.castError(error);
      setRecoverPasswordError([JSON.stringify(castedError.message)]);
      setShowErrors(true);
    }
  };

  return (
    <div className='pt-10 sm:pt-20 flex-1 flex flex-col items-center sm:pb-20 border-t-[1px] border-gray-200'>
      <h1 className="text-3xl font-semibold text-center text-primary-100 mb-5">
        Restablecer contraseña
      </h1>
      <form className='w-full sm:max-w-[500px] px-6' onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-2">
          <label
            htmlFor="password"
            className="text-sm font-semibold text-gray-800"
          >
            Nueva contraseña
          </label>
          <PasswordInput
            placeholder="Contraseña nueva"
            label={'password'}
            register={register}
            required={true}
            minLength={{ value: 8, message: 'El tamaño mínimo son 8 digitos' }}
            error={errors.currentPassword}
          />
          {errors.password?.message && (
            <div className="flex flex-row items-center pt-1 text-red-600	">
              <span className="font-base bg-yellow-100 text-sm text-red-600">{errors.password.message}</span>
            </div>
          )}
        </div>
        <div className="mb-7">
          <label
            htmlFor="confirmPassword"
            className="text-sm font-semibold text-gray-800"
          >
            Confirmar contraseña
          </label>
          <PasswordInput
            placeholder="Confirmar contraseña nueva"
            label={'confirmPassword'}
            register={register}
            required={true}
            minLength={{ value: 8, message: 'El tamaño mínimo son 8 digito' }}
            error={errors.confirmPassword}
          />
          { errors.confirmPassword?.message && (
            <span className="text-sm text-error-100">{errors.confirmPassword.message}</span>
          )}
        </div>
        { recoverPasswordError && showErrors && (
          <span className="text-sm text-error-100">{recoverPasswordError}</span>
        )}
        <MainButton 
          isSubmitButton={true} 
          className="w-full"
          disabled={password.length < 8 || confirmPassword.length < 8 || password!==confirmPassword} 
          >
            Restablecer
        </MainButton>
      </form>
    </div>
  );
}